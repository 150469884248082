import React from 'react';
import { Link } from 'react-router-dom';

const AllOlimpPosts = (props) => {
  const { posts } = props;
  if (!posts) {
    return null;
  }

  return (
    <div>
      <div class='flex flex-col'>
        <div class='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div class='py-2 align-middle inline-block min-w-full sm:px-3 lg:px-8'>
            <div class='shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table class='min-w-full divide-y divide-gray-200 '>
                <thead class='bg-blue-50'>
                  <tr>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      #
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Naslov
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Podnaslov
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Autor_id
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Rubrika
                    </th>
                    <th scope='col' class='relative px-3 py-3'>
                      Uredi
                    </th>
                  </tr>
                </thead>
                <tbody class='bg-white divide-y divide-gray-200 '>
                  {posts.map((item, i) => (
                    <tr key={item.id} className={i % 2 ? 'bg-gray-100' : ''}>
                      <td class='px-3 py-2 wrap ' style={{ maxWidth: '30px' }}>
                        #{item.id}
                      </td>
                      <td class='px-3 py-2 wrap ' style={{ maxWidth: '200px' }}>
                        <small className='text-2xl'>{item.naslov}</small>
                      </td>
                      <td class='px-3 py-2 wrap' style={{ maxWidth: '100px' }}>
                        <small className='text-2xl'>
                          {item.podnaslov
                            ? `${item.podnaslov.slice(0, 80)}[...]`
                            : '/'}
                        </small>
                      </td>
                      <td
                        className='px-3 py-2 whitespace-nowrap text-sm text-gray-500'
                        style={{ maxWidth: '50px' }}>
                        {item.autor_id}
                      </td>
                      <td
                        style={{ maxWidth: '50px' }}
                        class='px-3 py-2  text-sm text-gray-500'>
                        {item.rubrika_id}
                      </td>
                      <td class='px-3 py-2 whitespace-nowrap text-right text-sm font-medium'>
                        <div className='d-flex'>
                          <Link to={'/uredi-olimp-clanak/' + item.id}>
                            <button
                              type='submit'
                              className='bg-green-600 p-1 text-white px-3 rounded-md my-2'>
                              Uredi
                            </button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllOlimpPosts;
