import React, { Component } from "react";
import jwt from 'jwt-decode'
import { Redirect } from "react-router-dom";
import Login from "./Auth/Login";
class Not_found extends Component {

    render() {
        const token = localStorage.getItem('token');
        const userToken = token ? jwt(token) : '';
        return (
            <div>
                { !userToken ?
                    <Login />
                    : <div className="d-flex " style={ { height: '60vh', maxWidth: '800px', margin: '0 auto' } }>

                        <div className="m-auto">
                            <h3 className="text-black">Žao nam je, ali nismo mogli pronaći tu stranicu :(</h3><br />
                            <h5 className="">Premjestili smo neke stvari i tvoja se stranica vjerojatno pritom izgubila. Pokušaj ponovno unijeti adresu ili se vrati na početnu stranicu.</h5>
                            <h6><a className="text-black" href="/">Go to Dashboard </a></h6>
                        </div>
                    </div> }


            </div>
        );
    }
}

export default Not_found;