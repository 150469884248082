import Axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import api from '../Api/api';
import './select-search.css';

const SportoviSelect = ({ setParams, placeholder, value }) => {
  const searchInput = useRef();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selec, setSelected] = useState();
  useEffect(() => {
    getCategories();
  }, []);
  const select = (value) => {
    setParams(value);
    setSelected(value);
  };
  const getCategories = () => {
    Axios.get(api + '/api/sportasi/sportovi')
      .then((res) => {
        setLoading(false);

        setCategories(res.data);
      })
      .catch((e) => console.log(e));
  };
  if (loading || categories.length < 1) {
    return <div></div>;
  }

  const options = Object.values(categories).map((item) => ({
    name: item.naziv + ' - ' + item.id,
    value: item.id,
  }));

  return (
    <div>
      <SelectSearch
        filterOptions={fuzzySearch}
        ref={searchInput}
        options={options}
        value={value}
        search
        name='Sportovi'
        onChange={select}
        placeholder={placeholder}
      />
    </div>
  );
};
SportoviSelect.propTypes = {
  setParams: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default SportoviSelect;
