import './App.css';
import React, { Component } from 'react';
import axios from 'axios';
import Rutes from './routes';
import Rutes2 from './routes2';
import Login from './PAGES/Auth/Login';
import Register from './PAGES/Auth/Register';
import { connect } from 'react-redux';

const api = process.env.REACT_APP_API;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      loading: true,
      haveAccount: true,
      message: '',
    };
  }
  componentDidMount() {
    this.chechAuth();
  }
  Register() {
    this.setState((prevState) => ({
      haveAccount: !prevState.haveAccount,
    }));
  }
  async chechAuth() {
    axios.defaults.withCredentials = true;
    const get = await fetch(`${api}/api/auth/token`, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());
    this.setState({ loading: false });
    if (get === 'AUTH') {
      this.setState({
        auth: true,
      });
    } else {
      localStorage.removeItem('token');
      this.setState({
        auth: false,
        message: 'Your token expired',
      });
      setTimeout(() => {
        this.setState({
          message: '',
        });
      }, 2000);
    }
  }
  authContainer() {
    if (this.state.haveAccount) {
      return (
        <div>
          <Login />
          <p id='login-register' onClick={this.Register.bind(this)}>
            Dont have account? register here
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <Register />
          <p id='login-register' onClick={this.Register.bind(this)}>
            Login here
          </p>
        </div>
      );
    }
  }

  render() {
    axios.defaults.withCredentials = true;
    return (
      <div className='bg-[#fcfdfd]'>
        {this.state.loading ? (
          <div className='loader-div'>
            <div className='m-auto items-center justify-center '>
              <div
                style={{ borderTopColor: 'transparent' }}
                className='w-16 h-16 border-4 border-gray-500 border-solid rounded-full animate-spin'></div>
            </div>
          </div>
        ) : (
          <div>{this.state.auth ? <Rutes /> : <Rutes2 />}</div>
        )}
      </div>
    );
  }
}

export default connect()(App);
