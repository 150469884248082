import Axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link, useParams } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import { godina, months } from '../../utils/constants';
import { getAutor } from '../Profile/GetComments';
import FolderListSingle from '../NovaMedia/MediaModalSingle';

const UrediOlimp = () => {
  const { id } = useParams();
  //values
  const [modal, showModal] = useState(false);
  const [modalPdf, showModalPdf] = useState(false);
  const [broj, setBroj] = useState();
  const [godina_, setGodina] = useState();
  const [mjesec, setMjesec] = useState();
  const [graficka, setGraficka] = useState();
  const [tisak, setTisak] = useState();
  const [issue, setIssue] = useState();
  const [urednik, setUrednik] = useState();
  const [prijevod, setPrijevod] = useState();
  const [produkcija, setProdukcija] = useState();
  const [nakladnik, setNakladnik] = useState();
  const [naklada, setNaklada] = useState();
  const [dodatno, setDodatno] = useState();
  const [dodatnoEng, setDodatnoEng] = useState('');
  const [opis, setOpis] = useState();
  //get
  const [autori, setAutori] = useState([]);
  //upload
  const [pdfLoading, setLoadingPdf] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [imgLoading, setLoadingImage] = useState(false);
  const [img, setImg] = useState(null);
  //alert
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  //doc upload #########################
  const onChangePdf = (event) => {
    if (event.target.files[0] != null) {
      console.log(event.target.files[0]);
      uploadPdf(event.target.files[0]);
    } else {
    }
  };

  const uploadPdf = (img) => {
    setLoadingPdf(true);
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/upload-olimp-pdf.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);

          setPdf(data.image);
          setLoadingPdf(false);
          showAlert('success', data.message);
        });
      })
      .catch((e) => {
        showAlert('success', e.message);
      });
  };
  //image upload
  const onChangeImage = (event) => {
    if (event.target.files[0] != null) {
      console.log(event.target.files[0]);
      uploadImage(event.target.files[0]);
    } else {
    }
  };

  const uploadImage = (img) => {
    setLoadingImage(true);
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/olimp-image-upload.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);

          setImg(data.image);
          setLoadingImage(false);
          showAlert('success', data.message);
        });
      })
      .catch((e) => {
        showAlert('success', e.message);
      });
  };
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const send = async (e) => {
    e.preventDefault();
    const broj_olimpa = 0 + '' + broj * 1;

    let val = {
      broj_olimpa,
      godina_,
      mjesec,
      graficka,
      tisak,
      issue,
      pdf,
      img,
      urednik,
      prijevod,
      produkcija,
      nakladnik,
      naklada,
      dodatno,
      dodatnoEng,
      opis,
      id,
    };
    try {
      if (img === null) {
        showAlert('danger', 'Slika nije dodana');
      } else if (pdf === null) {
        showAlert('danger', 'Dokument nije dodan!');
      } else if (pdf && img) {
        const res = await Axios.put(
          api + '/api/content/update-olimp-number',
          val,
          {
            headers: {
              'x-access-token': localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          }
        );
        showAlert('success', res.data.message);
        window.location.reload();
        return res;
      }
    } catch (e) {
      console.log(e.message);
      showAlert('danger', e.message);
    }
  };
  const get_SingleOlimp = useCallback(async () => {
    const get = await Axios.get(api + '/api/content/olimp-brojevi/' + id, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    });

    setBroj(get.data.broj);
    setGodina(get.data.godina);
    setMjesec(get.data.mjesec);
    setGraficka(get.data.graficka_priprema);
    setTisak(get.data.tisak);
    setIssue(get.data.issuu);
    setPdf(get.data.link);
    setImg(get.data.slika);
    setUrednik(get.data.urednik_id);
    setPrijevod(get.data.prijevod_id);
    setProdukcija(get.data.produkcija_id);
    setNakladnik(get.data.nakladnik);
    setNaklada(get.data.naklada);
    setDodatno(get.data.dodatno);
    setDodatnoEng(get.data.dodatno_eng);
    setOpis(get.data.opis);
  }, [id]);

  const get_Autor = useCallback(async () => {
    const get = await getAutor();
    setAutori(get);
  }, []);
  const handleEditor = (value) => {
    setOpis(value);
  };

  useEffect(() => {
    get_Autor();
    get_SingleOlimp();
  }, [getAutor, get_SingleOlimp, id]);
  return (
    <section className='posts-list px-2'>
      <h1>Dodaj novi broj olimpa</h1>

      <form className='pb-5 mb-2' onSubmit={send} id='olimp'>
        <Alert show={AlertShow} type={alertType} message={alertMessage} />
        <div className='d-flex justify-content-between '>
          <button className='bg-blue-800 p-1 text-white px-3 rounded-md my-2'>
            <Link to='/dodaj-olimp-clanak'>Novi članak</Link>
          </button>
          <button
            type='submit'
            className='bg-green-600 p-1 text-white px-3 rounded-md my-2'>
            Ažuriraj
          </button>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Broj Olimpa</span>
            <input
              defaultValue={broj}
              required
              onChange={(e) => setBroj(e.target.value)}
              type={'number'}
              placeholder='Unesi broj'
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Godina</span>
            <select onChange={(e) => setGodina(e.target.value)}>
              {godina.reverse().map((item, index) => (
                <option
                  key={index}
                  selected={godina_ == item.value}
                  value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>{' '}
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Mjesec</span>
            <select
              defaultValue={mjesec}
              onChange={(e) => setMjesec(e.target.value)}>
              {months.map((item, index) => (
                <option
                  key={index}
                  selected={mjesec == item.value}
                  value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Grafička priprema</span>
            <input
              defaultValue={graficka}
              onChange={(e) => setGraficka(e.target.value)}
              type={'text'}
              placeholder='Grafička'
            />
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Tisak</span>
            <input
              defaultValue={tisak}
              onChange={(e) => setTisak(e.target.value)}
              type={'text'}
              placeholder='Tisak'
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Issue link</span>
            <input
              defaultValue={issue}
              required
              onChange={(e) => setIssue(e.target.value)}
              type={'text'}
              placeholder='Issue'
            />
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Slika</span>
            <button
              className='btn bg-indigo-300 mb-1'
              type='button'
              onClick={() => showModal(true)}>
              Odaberi sliku (olimp-slike)
            </button>
            {modal ? (
              <FolderListSingle
                onClose={() => showModal(false)}
                folder={'uploads'}
                selected={img}
                setImage={setImg}
              />
            ) : null}
            {img ? (
              <img
                style={{ objectFit: 'contain', height: '200px' }}
                src={'https://dev.hoo.hr/' + img}
              />
            ) : null}
          </div>
          <div style={{ height: '49%' }} className='m-1'>
            <span>Pdf</span>
            <button
              className='btn bg-indigo-300 mb-1'
              type='button'
              onClick={() => showModalPdf(true)}>
              Odaberi pdf (olimp-pdf)
            </button>
            {modalPdf ? (
              <FolderListSingle
                allowSelect={true}
                onClose={() => showModalPdf(false)}
                folder={'uploads'}
                selected={pdf}
                setImage={setPdf}
              />
            ) : null}
            {pdf ? (
              <div>
                <i className='bi bi-file-earmark-arrow-down text-black text-4xl'></i>
                <br />
                <span className='text-green-600 font-light'>Link: {pdf}</span>
                <br />
                <span className='text-green-600 font-light'>
                  Puni link: https://dev.hoo.hr/{pdf}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Urednik</span>
            <select
              defaultValue={urednik}
              onChange={(e) => setUrednik(e.target.value)}>
              {autori.map((item, index) => (
                <option
                  key={index}
                  selected={urednik == item.id}
                  value={item.id}>
                  {item.ime} {item.prezime}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Prijevod</span>
            <select
              defaultValue={prijevod}
              onChange={(e) => setPrijevod(e.target.value)}>
              {autori.map((item, index) => (
                <option
                  key={index}
                  selected={prijevod == item.id}
                  value={item.id}>
                  {item.ime} {item.prezime}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Produkcija</span>
            <select
              defaultValue={produkcija}
              onChange={(e) => setProdukcija(e.target.value)}>
              {autori.map((item, index) => (
                <option
                  key={index}
                  selected={produkcija == item.id}
                  value={item.id}>
                  {item.ime} {item.prezime}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Nakladnik</span>
            <input
              defaultValue={nakladnik}
              onChange={(e) => setNakladnik(e.target.value)}
              type={'text'}
              placeholder='Nakladnik'
            />
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Naklada</span>
            <input
              defaultValue={naklada}
              onChange={(e) => setNaklada(e.target.value)}
              type={'number'}
              placeholder='Naklada'
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Dodatno</span>
            <textarea
              defaultValue={dodatno}
              onChange={(e) => setDodatno(e.target.value)}
              rows={2}
              type={'text'}
              placeholder='Issue'
            />
          </div>
        </div>
        <div className='m-1'>
          <span>Dodatno eng.</span>
          <textarea
            defaultValue={dodatnoEng}
            onChange={(e) => setDodatnoEng(e.target.value)}
            rows={2}
            type={'text'}
            placeholder='Dodatno eng'
          />
        </div>
        <div className='m-1'>
          <span>Opis</span>
          <div className='shadow-sm p-2 py-4 bg-light rounded-md'>
            <ReactQuill
              theme={'snow'}
              onChange={handleEditor}
              value={opis}
              modules={UrediOlimp.modules}
              formats={UrediOlimp.formats}
              bounds={'.app'}
              placeholder={'Opis hr'}
            />
          </div>
        </div>
      </form>
    </section>
  );
};
UrediOlimp.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
UrediOlimp.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'document',
];
export default UrediOlimp;
