import { useEffect, useState } from 'react';

import Sportasi_select from '../../components/Select/Sportasi_select';
import { getOlimpijciSingle } from '../Profile/GetComments';

const Sportasi = () => {
  const [data, setData] = useState([]);
  const [param, setParams] = useState();

  const getSingle = async () => {
    const get = await getOlimpijciSingle(param);
    setData(get);
  };

  useEffect(() => {
    getSingle();
  }, [param]);
  return (
    <section className='posts-list '>
      <h1>Olimpijci</h1>
      <div className='d-flex my-2'>
        <a href='/dodaj-sportasa' className='ml-auto'>
          <button className='bg-green-600 p-1 text-white px-3 rounded-md my-2'>
            Dodaj sportaša
          </button>
        </a>
      </div>
      <Sportasi_select
        value={param}
        setParams={setParams}
        placeholder='Odaberi sportaša'
      />
      {data.length < 1 ? null : (
        <div>
          {data.map((item, index) => (
            <div key={index} className=''>
              <div key={index} className='d-flex mb-3 pb-2 '>
                <img
                  style={{
                    height: '200px',
                    width: '200px',
                    objectFit: 'cover',
                  }}
                  className='mt-3  rounded-full'
                  src={'https://dev.hoo.hr/' + item.slika}
                />
                <h2 className='mt-auto mb-auto ml-3'>
                  {item.Ime + ' ' + item.Prezime}
                </h2>
              </div>

              <div>
                <a className='my-5' href={`/olimpijci/detalji/${item.id}`}>
                  <button className='bg-blue-800 p-1 text-white px-3 rounded-md my-2'>
                    Uredi
                  </button>
                </a>

                <div
                  className='bg-light px-3 py-2 rounded-md'
                  dangerouslySetInnerHTML={{
                    __html: item.tekst,
                  }}
                />
                <div
                  className='bg-light px-3 py-2 rounded-md'
                  dangerouslySetInnerHTML={{
                    __html: item.tekst_eng,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default Sportasi;
