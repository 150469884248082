import Axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link, useParams } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import { getAutor } from '../Profile/GetComments';
import SelectSearch, { fuzzySearch } from 'react-select-search';

const UrediClanakOlimpa = () => {
  const { id } = useParams();
  const [class_select, setClassSelect] = useState('d-none');
  const searchInput = useRef();
  const [_autori, setAutori] = useState([]);
  const [olimp, setOlimp] = useState([]);
  const [rubrike, setRubrike] = useState([]);
  //values
  const [naslov, setNaslov] = useState();
  const [podnaslov, setPodnaslov] = useState();
  const [broj_id, setBroj] = useState();
  const [rubrika_id, setRubrika] = useState();
  const [autor_id, setAutor] = useState([]);
  const [photo_autor_id, setAutorPhoto] = useState();
  const [stranica_start, setStranicaStart] = useState();
  const [stranica_kraj, setStranicaKraj] = useState();
  const [tagovi, setTagovi] = useState();
  const [puni_sardrzaj, setSadrzaj] = useState();
  //alert
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const selectAutor = (value) => {
    setAutor(value);
    setClassSelect('d-none');
  };
  const openSelectClass = () => {
    if (class_select == 'd-none') {
      setClassSelect('');
    } else {
      setClassSelect('d-none');
    }
  };
  const get_Olimp = useCallback(async () => {
    try {
      const get = await Axios.get(
        api + '/api/content/get-olimp-article-single/' + id
      );

      setNaslov(get.data[0].naslov);
      setPodnaslov(get.data[0].podnaslov);
      setBroj(get.data[0].broj_id);
      setRubrika(get.data[0].rubrika_id);
      setAutor(get.data[0].autor_id);
      setAutorPhoto(get.data[0].foto_autor_id);
      setStranicaStart(get.data[0].stranica_start);
      setStranicaKraj(get.data[0].stranica_kraj);
      setTagovi(get.data[0].tagovi);
      setSadrzaj(get.data[0].puni_sadrzaj);
      return get;
    } catch (e) {
      console.log(e);
    }
  }, [id]);
  const get_Olimps = useCallback(async () => {
    try {
      const get = await Axios.get(api + '/api/content/olimp-brojevi', {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      setOlimp(get.data);
      return get;
    } catch (e) {
      console.log(e);
    }
  }, []);
  const get_Rubrike = useCallback(async () => {
    try {
      const get = await Axios.get(api + '/api/content/get/olimp-rubrike', {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      setRubrike(get.data);

      return get;
    } catch (e) {
      console.log(e);
    }
  }, []);
  const get_Autor = useCallback(async () => {
    const get = await getAutor();
    setAutori(get);
  }, []);

  useEffect(() => {
    get_Autor();
    get_Rubrike();
    get_Olimp();
    get_Olimps();
  }, []);

  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const send = async (e) => {
    e.preventDefault();
    const autor = autor_id.join('|');
    console.log(autor);
    let val = {
      naslov,
      podnaslov,
      broj_id,
      rubrika_id,
      autor,
      photo_autor_id,
      stranica_start,
      stranica_kraj,
      tagovi,
      puni_sardrzaj,
      id,
    };
    console.log(val);
    try {
      const res = await Axios.put(
        api + '/api/content/update-olimp-clanak',
        val,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      showAlert('success', res.data.message);
      get_Olimp();
      return res;
    } catch (e) {
      console.log(e.message);
      showAlert('danger', e.message);
    }
  };
  const handleEditor = (value) => {
    setSadrzaj(value);
  };
  const options = Object.values(_autori).map((item) => ({
    name: item.ime + ' ' + item.prezime,
    value: item.id,
  }));
  return (
    <section className='posts-list '>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <button className='bg-blue-800 p-1 text-white px-3 rounded-md my-2'>
        <Link to='/dodaj-olimp'>Nazad</Link>
      </button>

      <h1>Novi Olimp članak</h1>
      <form onSubmit={send} id='clanak-olimp'>
        <button
          type='submit'
          className='bg-green-600  p-1 text-white px-3 rounded-md my-2'>
          Ažuriraj
        </button>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Naslov članka</span>
            <input
              defaultValue={naslov}
              required
              onChange={(e) => setNaslov(e.target.value)}
              placeholder='Naslov'
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Podnaslov</span>
            <textarea
              defaultValue={podnaslov}
              rows={3}
              onChange={(e) => setPodnaslov(e.target.value)}
            />
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Broj</span>
            <select onChange={(e) => setBroj(e.target.value)}>
              {olimp.map((item, index) => (
                <option
                  key={index}
                  selected={broj_id == item.id}
                  value={item.id}>
                  {item.broj}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Rubrika</span>
            <select onChange={(e) => setRubrika(e.target.value)}>
              {rubrike.map((item, index) => (
                <option
                  key={index}
                  selected={rubrika_id == item.id}
                  value={item.id}>
                  {item.naziv}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <small>Foto autor</small>
            <select onChange={(e) => setAutorPhoto(e.target.value)}>
              {_autori.map((item, index) => (
                <option
                  key={index}
                  selected={photo_autor_id == item.id}
                  value={item.id}>
                  {item.ime} {item.prezime}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Stranica start</span>
            <input
              defaultValue={stranica_start}
              type={'number'}
              onChange={(e) => setStranicaStart(e.target.value)}
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Stranica kraj</span>
            <input
              defaultValue={stranica_kraj}
              onChange={(e) => setStranicaKraj(e.target.value)}
              type={'number'}
            />
          </div>
        </div>
        <div>
          <span>Tagovi</span>
          <input
            defaultValue={tagovi}
            placeholder='tagovi'
            onChange={(e) => setTagovi(e.target.value)}
          />
        </div>
        <div>
          <span>Puni sadržaj</span>

          <div className='shadow-sm p-2 py-4 bg-light rounded-md'>
            <ReactQuill
              theme={'snow'}
              onChange={handleEditor}
              value={puni_sardrzaj}
              modules={UrediClanakOlimpa.modules}
              formats={UrediClanakOlimpa.formats}
              bounds={'.app'}
              placeholder={'Opis hr'}
            />
          </div>
          <p className='py-4'>Odaberi autore</p>
          <div style={{ width: '60%' }} className='m-1'>
            <p
              className='bg-blue-700  p-1 text-white px-3 rounded-md my-2'
              onClick={openSelectClass}>
              Odaberi autora
            </p>
            <SelectSearch
              filterOptions={fuzzySearch}
              ref={searchInput}
              search
              options={options}
              id={class_select}
              name='Autor'
              onChange={selectAutor}
              multiple
              value={autor_id}
              placeholder={'Odaberi autora'}
            />
            <br />
          </div>
        </div>
      </form>
    </section>
  );
};
UrediClanakOlimpa.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
UrediClanakOlimpa.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'document',
];
export default UrediClanakOlimpa;
