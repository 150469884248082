import PropTypes from 'prop-types';

const Modal = ({ onPress, title, value, content }) => {
  return (
    <div style={{ zIndex: 202020 }}>
      <div class='modal-dialog' role='document'>
        <div class='modal-content'>
          <div class='modal-header'>
            <h5 class='modal-title' id='exampleModalLabel'>
              {value}
            </h5>
            <button
              type='button'
              class='close'
              data-dismiss='modal'
              aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div class='modal-body'>{content}</div>
          <div class='modal-footer'>
            <button type='button' class='btn btn-success' data-dismiss='modal'>
              Odustani
            </button>
            <button
              onClick={onPress}
              type='button'
              class='btn btn-danger'
              data-dismiss='modal'>
              {title}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
Modal.propTypes = {
  onPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  content: PropTypes.string,
};

export default Modal;
