import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import jwt from 'jwt-decode';
import moment from 'moment';

import Home from './PAGES/Home';
import Not_found from './PAGES/Not_found';
import Sidebar from './PAGES/Sidebar';
import {
  _get_unreaded_messages,
  _NotificationsAsReaded,
  _user_token,
} from './PAGES/Profile/GetComments';
import PostDetails from './PAGES/Posts/PostDetails';
import Pages from './PAGES/Pages/pages';
import AddPost from './PAGES/Posts/AddPost';
import Medalje from './PAGES/Medalje/medalje';
import DodajMedalju from './PAGES/Medalje/DodajMedalju';
import PageDetails from './PAGES/Pages/PageDetails';
import Sportasi from './PAGES/Sportasi/Sportasi';
import Settings from './PAGES/Settings/Settings';
import Sportasi_detalji from './PAGES/Sportasi/Sportasi_detalji';
import Kategorizacije from './PAGES/Kategorizacije/Kategorizacije';
import Dashboard from './PAGES/Dashboard';
import DocUpload from './PAGES/DocumentsUpload/DocUpload';
import ImageUpload from './PAGES/DocumentsUpload/ImageUpload';
import Media from './PAGES/Medij/Media';
import UploadKategorizacije from './PAGES/Kategorizacije/UploadKategorizacije';
import AddPage from './PAGES/Pages/DodajStranicu';
import Dokumenti from './PAGES/DocumentsUpload/Dokumenti';
import DokumentiSingle from './PAGES/DocumentsUpload/DokumentiSingle';
import Navigation from './PAGES/Navigation/Navigation';
import ImageUploadOld from './PAGES/DocumentsUpload/OldImages';
import ListaDokumenata from './PAGES/DocumentsUpload/ListaDokumenata';
import WebImages from './PAGES/DocumentsUpload/WebImages';
import DodajSportasa from './PAGES/Sportasi/DodajSportasa';
import ClanciSlike from './PAGES/DocumentsUpload/Clanci-slike';
import DocUploadClanci from './PAGES/DocumentsUpload/Clanci-Dokumenti';
import DodajOlimp from './PAGES/Olimp/DodajOlimp';
import DodajClanakOlimpa from './PAGES/Olimp/DodajClanakOlimpa';
import BrojeviOlimp from './PAGES/Olimp/BrojeviOlimp';
import UrediOlimp from './PAGES/Olimp/UrediOlimp';
import OlimpClanci from './PAGES/Olimp/OlimpClanci';
import UrediClanakOlimpa from './PAGES/Olimp/UrediClanak';
import AutoriOlimp from './PAGES/Olimp/AutoriOlimp';
import DodajAutora from './PAGES/Olimp/DodajAutora';
import DodajRubriku from './PAGES/Olimp/DodajRubriku';
import FolderList from './PAGES/NovaMedia/Media';
import FolderListPage from './PAGES/NovaMedia/MediaPage';
import MediaManager from './PAGES/NovaMedia/MediaFolderSelect';
import { Editor } from './PAGES/Test';
import UrediMedalju from './PAGES/Medalje/Uredi';

function Rutes() {
  const [message, setMessage] = useState('');

  const token = localStorage.getItem('token');
  const userToken = token ? jwt(token) : '';
  const user = userToken.userId;
  const role = userToken.role;
  const tokenTime = userToken.exp * 1000;
  const time = moment(tokenTime).format('LLLL');

  const retrieveData = async () => {
    let repeat = false;
    const storedToken = await localStorage.getItem('token');
    if (storedToken !== null) {
      const decoded = jwt(storedToken);
      const tokenTime = decoded.exp * 1000;
      const time = moment(tokenTime).format('LLLL');
      const current = moment().format('LLLL');
      var isafter = moment(time).isAfter(current);
      if (!isafter) {
        logout();
        console.log(isafter);
        console.log('Your session expired');
      } else {
        repeat = setTimeout(retrieveData, 300000);
      }
    }
    return () => {
      repeat = true;
      if (repeat) {
        clearTimeout(repeat);
      }
    };
  };

  useEffect(() => {
    retrieveData();
  }, []);

  const logout = () => {
    setMessage('Your session expired!');

    localStorage.removeItem('token');
    window.location.reload();
  };

  const AccountType = () => {
    switch (role) {
      case 'superadmin':
        return (
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/postovi' component={Home} />
            <Route exact path='/editor' component={Editor} />
            <Route
              exact
              path='/media-manager/:folder'
              component={FolderListPage}
            />
            <Route exact path='/autori-olimp' component={AutoriOlimp} />
            <Route exact path='/dodaj-autora-olimp' component={DodajAutora} />
            <Route exact path='/dodaj-rubriku-olimp' component={DodajRubriku} />
            <Route exact path='/olimp-clanci' component={OlimpClanci} />
            <Route
              exact
              path='/uredi-olimp-clanak/:id'
              component={UrediClanakOlimpa}
            />
            <Route exact path='/post/detalji/:alias' component={PostDetails} />
            <Route exact path='/stranice' component={Pages} />{' '}
            <Route exact path='/stranice/:id' component={PageDetails} />
            <Route exact path='/dodaj-post' component={AddPost} />
            <Route exact path='/medalje' component={Medalje} />
            <Route exact path='/dodaj/medalje' component={DodajMedalju} />
            <Route exact path='/uredi-medalju/:id' component={UrediMedalju} />
            <Route exact path='/sportasi' component={Sportasi} />
            <Route exact path='/clanci-slike' component={ClanciSlike} />
            <Route exact path='/dodaj-sportasa' component={DodajSportasa} />
            <Route exact path='/kategorizacije' component={Kategorizacije} />
            <Route exact path='/image-upload' component={ImageUpload} />
            <Route exact path='/image-old' component={ImageUploadOld} />
            <Route exact path='/stari-dokumenti' component={ListaDokumenata} />
            <Route exact path='/media' component={Media} />
            <Route exact path='/dokumenti-folderi' component={Dokumenti} />
            <Route
              exact
              path='/dokumenti/folder/:id'
              component={DokumentiSingle}
            />
            <Route exact path='/new-page' component={AddPage} />
            <Route exact path='/navigacija' component={Navigation} />
            <Route
              exact
              path='/kategorizacije-upload'
              component={UploadKategorizacije}
            />
            <Route exact path='/web-slike' component={WebImages} />
            <Route
              exact
              path='/olimpijci/detalji/:id'
              component={Sportasi_detalji}
            />
            <Route exact path='/dodaj-olimp' component={DodajOlimp} />
            <Route
              exact
              path='/dodaj-olimp-clanak'
              component={DodajClanakOlimpa}
            />
            <Route exact path='/olimp-brojevi' component={BrojeviOlimp} />
            <Route exact path='/uredi-olimp/:id' component={UrediOlimp} />
            <Route exact path='/postavke' component={Settings} />
            <Route component={Not_found} />
          </Switch>
        );
      case 'olimp':
        return (
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/olimp-clanci' component={OlimpClanci} />
            <Route exact path='/autori-olimp' component={AutoriOlimp} />
            <Route exact path='/dodaj-autora-olimp' component={DodajAutora} />
            <Route exact path='/dodaj-rubriku-olimp' component={DodajRubriku} />
            <Route
              exact
              path='/uredi-olimp-clanak/:id'
              component={UrediClanakOlimpa}
            />

            <Route
              exact
              path='/media-manager/:folder'
              component={FolderListPage}
            />

            <Route exact path='/medalje' component={Medalje} />
            <Route exact path='/dodaj/medalje' component={DodajMedalju} />
            <Route exact path='/sportasi' component={Sportasi} />

            <Route exact path='/dodaj-sportasa' component={DodajSportasa} />

            <Route
              exact
              path='/olimpijci/detalji/:id'
              component={Sportasi_detalji}
            />
            <Route exact path='/dodaj-olimp' component={DodajOlimp} />
            <Route
              exact
              path='/dodaj-olimp-clanak'
              component={DodajClanakOlimpa}
            />
            <Route exact path='/olimp-brojevi' component={BrojeviOlimp} />
            <Route exact path='/uredi-olimp/:id' component={UrediOlimp} />

            <Route component={Not_found} />
          </Switch>
        );
      case 'tipfeler':
        return (
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/postovi' component={Home} />
            <Route
              exact
              path='/media-manager/:folder'
              component={FolderListPage}
            />
            <Route exact path='/post/detalji/:alias' component={PostDetails} />
            <Route exact path='/dodaj-sportasa' component={DodajSportasa} />
            <Route exact path='/dodaj-post' component={AddPost} />
            <Route exact path='/image-upload' component={ImageUpload} />
            <Route exact path='/media' component={Media} />
            <Route exact path='/stranice/:id' component={PageDetails} />
            <Route exact path='/stranice' component={Pages} />{' '}
            <Route exact path='/sportasi' component={Sportasi} />
            <Route exact path='/dokumenti-folderi' component={Dokumenti} />
            <Route exact path='/web-slike' component={WebImages} />
            <Route exact path='/image-old' component={ImageUploadOld} />
            <Route exact path='/stari-dokumenti' component={ListaDokumenata} />
            <Route
              exact
              path='/dokumenti/folder/:id'
              component={DokumentiSingle}
            />
            <Route exact path='/new-page' component={AddPage} />
            <Route
              exact
              path='/olimpijci/detalji/:id'
              component={Sportasi_detalji}
            />
            <Route component={Not_found} />
          </Switch>
        );
      case 'dokumenti':
        return (
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route
              exact
              path='/media-manager/:folder'
              component={FolderListPage}
            />

            <Route exact path='/dodaj-sportasa' component={DodajSportasa} />

            <Route exact path='/media' component={Media} />

            <Route exact path='/sportasi' component={Sportasi} />
            <Route exact path='/dokumenti-folderi' component={Dokumenti} />

            <Route
              exact
              path='/dokumenti/folder/:id'
              component={DokumentiSingle}
            />
            <Route
              exact
              path='/olimpijci/detalji/:id'
              component={Sportasi_detalji}
            />
            <Route exact path='/medalje' component={Medalje} />
            <Route exact path='/dodaj/medalje' component={DodajMedalju} />
            <Route component={Not_found} />
          </Switch>
        );
      case 'sportasi':
        return (
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/sportasi' component={Sportasi} />
            <Route
              exact
              path='/olimpijci/detalji/:id'
              component={Sportasi_detalji}
            />
            <Route exact path='/dodaj-sportasa' component={DodajSportasa} />
            <Route component={Not_found} />
          </Switch>
        );
      case 'kategorizacije':
        return (
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route
              exact
              path='/kategorizacije-upload'
              component={UploadKategorizacije}
            />
            <Route exact path='/kategorizacije' component={Kategorizacije} />
            <Route component={Not_found} />
          </Switch>
        );
    }
  };

  return (
    <div>
      <Router>
        <div className='wrapper'>
          <Sidebar />
          <div id='content' className='bg-gray'>
            <nav
              className='navbar mt-0 pt-0 navbar-expand-lg navbar-light '
              style={{ background: 'transparent' }}>
              <div className='container-fluid'>
                <span
                  className='btn'
                  style={{
                    color: 'black',
                    borderRadius: '3px',
                    fontSize: '30px',
                    paddingTop: '0px',
                    marginTop: '0px',
                  }}
                  id='sidebarCollapse'>
                  {' '}
                  <i className='bi bi-filter-left'></i>
                </span>
                <span
                  style={{ fontSize: '28px' }}
                  className='  d-inline-block d-lg-none ml-auto'
                  type='text'
                  data-toggle='collapse'
                  data-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'>
                  <i className='bi bi-justify-right'></i>
                </span>
                <div
                  className='collapse navbar-collapse'
                  id='navbarSupportedContent'>
                  <ul className='nav navbar-nav ml-auto'>
                    <li className='nav-item'>
                      <a className='nav-link text-black'>ID: {userToken.id}</a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link text-black'>
                        Admin: {userToken.name}
                      </a>
                    </li>
                    <li>
                      <button
                        onClick={logout}
                        className='py-1 px-3 bg-red-500 text-light'>
                        Odjavi se
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <div className='App'>{AccountType()}</div>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default Rutes;
