import Axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import api from '../Api/api';
import './select-search.css';

const CategoriesSelect = ({ setParams, placeholder, value }) => {
  const searchInput = useRef();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selec, setSelected] = useState();
  useEffect(() => {
    getCategories();
  }, []);
  const select = (value) => {
    setParams(value);
    setSelected(value);
  };
  const getCategories = async () => {
    try {
      const response = await Axios.get(
        'https://hoo-server.vercel.app/api/content/get/cats'
      );
      setLoading(false);
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  if (loading || categories.length < 1) {
    return <div></div>;
  }

  const options = Object.values(categories).map((item) => ({
    name: item.title + ' - ' + item.id,
    value: item.id,
  }));

  return (
    <div>
      <SelectSearch
        filterOptions={fuzzySearch}
        ref={searchInput}
        options={options}
        value={value}
        search
        name='language'
        onChange={select}
        placeholder={placeholder}
      />
    </div>
  );
};
CategoriesSelect.propTypes = {
  setParams: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default CategoriesSelect;
