import Axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import { getAutor } from '../Profile/GetComments';

const DodajRubriku = () => {
  //values
  const [naziv, setNaziv] = useState();

  //alert
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const send = async (e) => {
    e.preventDefault();
    let val = [[naziv]];
    try {
      const res = await Axios.post(
        api + '/api/content/add-rubrika-olimp',
        val,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      showAlert('success', res.data.message);
      document.getElementById('clanak-olimp').reset();
      return res;
    } catch (e) {
      console.log(e.message);
      showAlert('danger', e.message);
    }
  };

  return (
    <section className='posts-list '>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />

      <h1>Dodaj rubriku Olimp</h1>
      <form onSubmit={send} id='clanak-olimp'>
        <button
          type='submit'
          className='bg-green-600  p-1 text-white px-3 rounded-md my-2'>
          Objavi
        </button>

        <div style={{ width: '49%' }} className='m-1'>
          <span>Naziv rubrike</span>
          <input
            required
            onChange={(e) => setNaziv(e.target.value)}
            placeholder='Ime rubrike'
          />
        </div>
      </form>
    </section>
  );
};

export default DodajRubriku;
