import moment from 'moment';
import { useEffect, useState } from 'react';
import SelectSearch from 'react-select-search';
import axios from 'axios';
import jwt from 'jwt-decode';

import '../../components/Select/select-search.css';
import ReactQuill from 'react-quill';
import Switch from 'react-switch';
import ModalImage from '../../components/Modal/ModalImage';
import api from '../../components/Api/api';
import Alert from '../../components/Alert/Alert';
import { Link } from 'react-router-dom';
import CategoriesSelect from '../../components/Select/CategoriesSelect';
import MultipleCaption from '../../components/Modal/MultupleCaption';
import FolderListSingle from '../NovaMedia/MediaModalSingle';
import FolderList from '../NovaMedia/Media';

const AddPage = (props) => {
  //gallery
  const [modalGallery, showModalGallery] = useState(false);
  const [param, setParams] = useState('');
  const [modal, showModal] = useState(false);
  const [page_images, setPageImages] = useState([]);
  const [page_image, setPageImage] = useState();
  const [page_image_caption, setPageImageCaption] = useState();
  const [post_image, setImage] = useState([]);
  const [listaSlika, setSlike] = useState([]);
  const [captions, setCaptions] = useState([]);
  const [caption_input, setCaption_Input] = useState();

  const [title, setTitle] = useState('');
  const [alias, setAlias] = useState('');
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [description, setDescription] = useState('');
  const [lng, setLng] = useState('hr-HR');
  const [show_gallery, setShowGallery] = useState(0);
  const [show_buttons, setShowButtons] = useState(0);
  const [show_accordion, setShowAccordion] = useState(0);
  const [buttons, setButtons] = useState([]);
  const [accordions, setAccoridons] = useState([]);
  // buttons
  const [buttonTitle, setButtonTitle] = useState('');
  const [buttonLink, setButtonLink] = useState('');
  // accordion
  const [accordionTitle, setAccordionTitle] = useState('');
  const [accordioData, setAccordionData] = useState('');

  //lng
  const optionsLangugage = [
    {
      name: 'Engleski',
      value: 'en-GB',
    },
    {
      name: 'Hrvatski',
      value: 'hr-HR',
    },
  ];
  const unselect = (item, id) => {
    const isImageSelected = page_images.some((image) => image.image === id);

    if (isImageSelected) {
      const updatedImages = page_images.filter((image) => image.image !== id);
      setPageImages(updatedImages);
      // Reset the `ima` value when the image is unselected
    } else {
    }
  };
  // user id
  const token = localStorage.getItem('token');
  const userToken = token ? jwt(token) : '';
  const userID = userToken.id;
  useEffect(() => {
    setImage(post_image);
  }, [setImage]);
  //alias
  function convertToSlug(Text) {
    if (Text) {
      return Text.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
    } else {
      return '';
    }
  }
  //upload image
  const onChangeImg = (event) => {
    if (event.target.files[0] != null) {
      uploadImage(event.target.files[0]);
    } else {
    }
  };
  const uploadImage = (img) => {
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/upload.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);

          setPageImage(data.image);
          showAlert('success', data.message);
        });
      })
      .catch((e) => {
        showAlert('success', 'Greška');
        console.log(e);
      });
  };
  const setVal = (val, index) => {
    setCaptions((value) => [...value, { opis: val, index: index }]);
  };
  const removeCaption = (value) => {
    const array = captions;
    const index = array.indexOf(value);
    console.log(index);
    array.splice(index, 1);
    setCaptions([...array]);
  };
  //slike
  const dohvatiSlike = async (type) => {
    showModal(true);
  };
  const dohvatiSlikeGalerije = async (type) => {
    showModalGallery(true);
  };
  const folderi = (type) => {
    dohvatiSlike(type);
  };
  // html description
  const handleChange = (value) => {
    setDescription(value);
  };
  const clearButtonNames = () => {
    setButtonTitle('');
    setButtonLink('');
  };
  const selectLangugage = (value) => {
    setLng(value);
  };
  const clearButton = (value) => {
    const array = buttons;
    const index = array.indexOf(value);
    array.splice(index, 1);
    setButtons([...array]);
  };
  const clearAccordionButton = (value) => {
    const array = accordions;
    const index = array.indexOf(value);
    array.splice(index, 1);
    setButtons([...array]);
  };
  const clearAccordionButtonNames = () => {
    setAccordionData('');
    setAccordionTitle('');
  };
  const handleChangeAccordion = (value) => {
    setAccordionData(value);
  };
  //alert
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };
  // send data to backend
  const addPage = () => {
    if (title == '') {
      showAlert('danger', 'Unesite naslov!');
    } else {
      const image_data = {
        url: page_image,
        caption: page_image_caption,
      };
      const buttonValue = JSON.stringify(buttons);
      const accordionValue = JSON.stringify(accordions);
      const galleryValue = JSON.stringify(page_images);
      const gallery_captions = JSON.stringify(captions);
      const page_images_ = JSON.stringify(image_data);
      const extentit = 'com_content';
      const alias = convertToSlug(title);
      const data = [
        title,
        alias,
        description,
        1,
        1,
        userID,
        moment().format(),
        userID,
        moment().format(),
        lng,
        show_gallery,
        show_buttons,
        show_accordion,
        buttonValue,
        accordionValue,
        galleryValue,
        gallery_captions,
        page_images_,
      ];
      axios
        .post(api + '/api/content/add/add-new-page', [data], {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          showAlert('success', res.data.message);
          setTimeout(() => {
            props.history.push('/stranice');
          }, 3000);
        })
        .catch((err) => {
          console.log(err, 'errr');
          showAlert('danger', err.data.message);
        });
    }
  };
  const handleCaptionChange = (event, index) => {
    const updatedImages = [...page_images];
    updatedImages[index].caption = event.target.value;

    setPageImage(updatedImages);
  };
  return (
    <div className=''>
      <section className='posts-list container px-1'>
        <Alert show={AlertShow} type={alertType} message={alertMessage} />

        <div className='flex items-center space-x-6'>
          <h1>Dodaj stranicu</h1>
          <Link to='/stranice'>
            <i class='bi bi-arrow-left'></i>Nazad
          </Link>
        </div>
        <ul className='mt-4'>{title}</ul>

        <div className='d-flex'>
          <button
            onClick={() => addPage()}
            className='bg-green-100 text-green-500 p-1 ml-auto  px-3 rounded-md my-2'>
            Objavi stranicu
          </button>
        </div>

        <p className='pt-3 font-bold text-black'>Unesi naziv</p>
        <input
          value={title}
          className='px-4 box-shadow bg-white py-3 bg-white border border-gray-100'
          placeholder={'Naziv stranice'}
          onChange={(e) => setTitle(e.target.value)}
        />
        <p className='pt-3 font-bold text-black'>Dodatno</p>
        <div className='flex justify-between items-center border border-gray-100 py-3 rounded-md box-shadow bg-white'>
          <div className='  p-3 w-1/2 rounded-md '>
            <p className='pt-3 font-bold text-black'>Dodaj sliku</p>
            <button
              className='btn bg-blue-900 text-white'
              onClick={() => dohvatiSlike()}>
              Arhiva
            </button>
            <br />
            {!page_image ? (
              <small className='text-warning text-xs text-lighter'>
                Slika nije odabrana
              </small>
            ) : (
              <div>
                <img
                  style={{ height: '200px', objectFit: 'contain' }}
                  src={'https://dev.hoo.hr/' + page_image}
                />
                <input
                  className='px-4 box-shadow bg-white py-1 bg-white border border-gray-100'
                  placeholder='Opis slike...'
                  value={page_image_caption}
                  onChange={(e) => setPageImageCaption(e.target.value)}
                />
              </div>
            )}
            {page_image}

            {modal ? (
              <FolderListSingle
                onClose={() => showModal(false)}
                listaSlika={listaSlika}
                folder={'clanci-slike'}
                selected={page_image}
                setImage={setPageImage}
              />
            ) : null}
          </div>
          <div className='w-1/2 border-l  p-3 '>
            <p className='pt-3 font-bold text-black'>Jezik</p>
            <SelectSearch
              options={optionsLangugage}
              value={lng}
              name='language'
              onChange={selectLangugage}
              placeholder='Jezik'
            />
          </div>
        </div>
        <p className='pt-3 pb-2 font-bold text-black'>Unesi sadržaj</p>
        <div className=' rounded-lg box-shadow bg-white py-6 border border-gray-100'>
          <ReactQuill
            theme={'snow'}
            onChange={handleChange}
            value={description}
            modules={AddPage.modules}
            formats={AddPage.formats}
            bounds={'.app'}
            placeholder={'Sadržaj stranice'}
          />
        </div>

        <div className='mt-4 box-shadow bg-white p-3 rounded-md border border-gray-100'>
          <h5 className='text-sm font-bold'>Dodaj linkove</h5>

          <Switch
            onChange={() =>
              setShowButtons((show_buttons) => (show_buttons === 0 ? 1 : 0))
            }
            checked={show_buttons === 1}
          />
          {show_buttons === 1 ? (
            <div className='py-2 my-3'>
              <hr />
              {buttons.map((val, index) => (
                <div className='mx-2'>
                  <div
                    className='row p-2 my-1 bg-gray-300 text-dark rounded-md'
                    style={{ listStyleType: 'none', maxWidth: '50%' }}
                    key={index}>
                    <span className='mt-auto mb-auto'>
                      {val.title} | {val.link}
                    </span>
                    <span
                      onClick={() => clearButton(val)}
                      className='mt-auto mb-auto ml-auto text-danger p-1 rounded-lg bg-white'>
                      <i class='bi bi-trash'></i>
                    </span>
                  </div>
                </div>
              ))}
              <div className='d-flex'>
                <input
                  className='m-1 w-50 bg-gray-200'
                  placeholder='Ime linka'
                  value={buttonTitle}
                  onChange={(e) => setButtonTitle(e.target.value)}
                />
                <CategoriesSelect
                  value={param}
                  setParams={setParams}
                  placeholder='Odaberi stranicu'
                />
              </div>
              <div className='text-right'>
                <button
                  onClick={() => {
                    {
                      setButtons((data) => {
                        return [
                          ...data,
                          {
                            title: buttonTitle,
                            link: '/page/' + '' + param + '/' + '1',
                          },
                        ];
                      });
                      clearButtonNames();
                    }
                  }}
                  className='btn btn-success my-2 '>
                  Add
                </button>
              </div>
            </div>
          ) : null}
        </div>

        <div className='mt-4 box-shadow bg-white p-3 rounded-md border border-gray-100'>
          <h5 className='text-sm font-bold'>Dodaj padajuće</h5>
          <Switch
            onChange={() =>
              setShowAccordion((show_accordion) =>
                show_accordion === 0 ? 1 : 0
              )
            }
            checked={show_accordion === 1}
          />
          {show_accordion === 1 ? (
            <div className='py-2 my-3'>
              {accordions.map((val, index) => (
                <div className='mx-2'>
                  <div
                    className='row  p-2 my-1 bg-gray-200  text-white rounded-md'
                    style={{ listStyleType: 'none' }}
                    key={index}>
                    <div>
                      <span className='mt-auto mb-auto text-black'>
                        {val.title}
                      </span>
                      <div
                        className='accordion-p'
                        dangerouslySetInnerHTML={{ __html: val.data }}
                      />
                    </div>
                    <span
                      onClick={() => clearAccordionButton(val)}
                      className='mt-auto mb-auto ml-auto text-danger p-1 rounded-lg bg-white'>
                      <i class='bi bi-trash'></i>
                    </span>
                  </div>
                </div>
              ))}
              <div>
                <div className='my-2 rounded-md  p-2'>
                  <input
                    className=' p-2 py-4 bg-gray-200 '
                    placeholder='Naslov'
                    value={accordionTitle}
                    onChange={(e) => setAccordionTitle(e.target.value)}
                  />
                </div>
                <div className=' p-2 py-4 bg-gray-200 '>
                  <ReactQuill
                    theme={'snow'}
                    onChange={handleChangeAccordion}
                    value={accordioData}
                    modules={AddPage.modules}
                    formats={AddPage.formats}
                    bounds={'.app'}
                    placeholder={'Opis'}
                  />
                </div>
              </div>
              <div className='text-right'>
                <button
                  onClick={() => {
                    {
                      setAccoridons((data) => {
                        return [
                          ...data,
                          { title: accordionTitle, data: accordioData },
                        ];
                      });
                      clearAccordionButtonNames();
                    }
                  }}
                  className='btn btn-success my-2 '>
                  Add
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className='mt-4 box-shadow bg-white p-3 rounded-md border border-gray-100'>
          <h5 className='text-sm font-bold'>Dodaj galeriju</h5>

          <Switch
            onChange={() =>
              setShowGallery((show_gallery) => (show_gallery === 0 ? 1 : 0))
            }
            checked={show_gallery === 1}
          />
          {show_gallery === 1 ? (
            <div className='my-2'>
              <hr />
              {page_images ? (
                <span className='d-flex'>
                  <button
                    onClick={() => setPageImage([])}
                    className='btn btn-danger ml-auto mb-2'>
                    <i class='bi bi-trash'></i>
                  </button>
                </span>
              ) : null}

              <hr />
              <h5 className='my-2'>Dodaj galeriju</h5>
              <button
                onClick={() => dohvatiSlikeGalerije('test.php')}
                className='btn bg-blue-700 rounded-sm text-white mb-2'
                data-toggle='modal'
                data-target='#exampleModal'>
                Odaberi u arhivi
              </button>

              {modalGallery ? (
                <FolderList
                  folder={'galerije'}
                  multiple={true}
                  withCaption={true}
                  onClose={() => showModalGallery(false)}
                  setImage={setPageImages}
                  selected={page_images}
                />
              ) : null}
              <div className='row'>
                {page_images.map((i, index) => (
                  <div className='col-sm-4'>
                    <small onClick={() => unselect(i, i.image)}>
                      <i class='bi bi-trash text-danger text-xl'></i>
                    </small>
                    <img src={'https://dev.hoo.hr/' + i.image} />
                    <input
                      type='text'
                      value={i.caption || ''}
                      placeholder='Caption'
                      onChange={(event) => handleCaptionChange(event, index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
};

// quill modules
AddPage.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
AddPage.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'document',
];

export default AddPage;
