import React, { Component } from "react";
import axios from 'axios';
import jwt from 'jwt-decode'
import { Link } from "react-router-dom";
class ResetUserPassword extends Component {
    constructor (props) {
        super(props);
        this.state = {
            lists: [],
            loading: true, fetch_user_id: null
        };
    }
    componentDidMount() {
        // get listing



    }
    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message },
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 10000);
    }
    send(e) {
        e.preventDefault();
        axios.defaults.withCredentials = true;
        if (this.inputPassword.value === this.inputPassword2.value) {


            const data = {
                passwordToken: this.props.match.params.id,
                password: this.inputPassword.value
            };
            axios.post(`https://meet-cro-test.herokuapp.com/update-password`, data, { credentials: 'include', headers: { 'Content-Type': 'application/json' } }).then((res) => {
                console.log(res)
                this.showAlert('success', `${res.data.message} .Plesae login`)
                this.resetForm()

            }).catch((err) => {
                this.showAlert('danger', `${err.message}`)

            })
        } else {
            this.showAlert('danger', "Password doesnt match!")
        }





    }
    resetForm() {
        this.refs.CheckoutFormForm.reset();

    }
    isVerifyed() {
        const { accountVerify } = this.state
        if (accountVerify) {
            window.location.href = "/"
        }
        else {

        }

    }
    render() {

        return (
            <div style={ { height: '96vh', width: '100%' } } className="w-100 p-0 m-0 d-flex  bg-grey-500">
                { this.state.alert && <div className={ `alert alert-${this.state.alertData.type} my-alert ` } role='alert'>
                    <div className='container'>
                        { this.state.alertData.message }
                    </div>
                </div> }

                <div className="m-auto">
                    { this.props.match.params.id }
                    <form
                        onSubmit={ this.send.bind(this) }
                        ref='CheckoutFormForm'
                    >
                        <input

                            name="password"

                            type="password"
                            placeholder='Enter new password'
                            ref={ (password) => (this.inputPassword = password) }
                        />
                        <input

                            name="password"

                            type="password"
                            placeholder='Confirm password'
                            ref={ (password2) => (this.inputPassword2 = password2) }
                        />
                        <button className="my-button-sucess" type="submit">Reset</button>
                    </form>
                    <small ><Link to="/">Login here</Link></small>


                </div>

            </div>
        );
    }
}

export default ResetUserPassword;