import Axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { getKategorizacije } from '../../PAGES/Profile/GetComments';
import './select-search.css';

const KategorizacijaSelect = ({ setParams, placeholder, value }) => {
  const searchInput = useRef();
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selec, setSelected] = useState();
  useEffect(() => {
    getSportasi();
  }, []);
  const select = (value) => {
    setParams(value);
    setSelected(value);
  };

  const getSportasi = async () => {
    const get = await getKategorizacije();
    setData(get);
    setLoading(false);
  };
  useEffect(() => {
    getSportasi();
  }, []);
  if (loading || data.length < 1) {
    return <div>Loading</div>;
  }

  const options = Object.values(data).map((item) => ({
    name:
      item.Ime + ' ' + item.PreZme + ' | ' + item.SptNazOK + '| ' + item.EVSKat,
    value: item.AUTOID,
  }));

  return (
    <div>
      <SelectSearch
        filterOptions={fuzzySearch}
        ref={searchInput}
        options={options}
        value={value}
        search
        name='language'
        onChange={select}
        placeholder={placeholder}
      />
    </div>
  );
};
KategorizacijaSelect.propTypes = {
  setParams: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default KategorizacijaSelect;
