import Axios from 'axios';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useParams } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import EditorToolbar, {
  modules,
  formats,
} from '../../components/Quill/QuillModules';
import jwt from 'jwt-decode';
import Switch from 'react-switch';
import {
  getAccordions,
  getPageDetails,
  getPageGallery,
} from '../Profile/GetComments';
import moment from 'moment';
import ModalImage from '../../components/Modal/ModalImage';
import CategoriesSelect from '../../components/Select/CategoriesSelect';
import MultipleCaption from '../../components/Modal/MultupleCaption';
import FolderList from '../NovaMedia/Media';
import FolderListSingle from '../NovaMedia/MediaModalSingle';

const PageDetails = (props) => {
  const { id } = useParams();
  const [modalGallery, showModalGallery] = useState(false);
  const [page_images, setPageImages] = useState([]);
  //post gallery
  const [modal, showModal] = useState(false);
  const [post_image, setImage] = useState([]);
  const [listaSlika, setSlike] = useState([]);
  const [captions, setCaptions] = useState([]);
  const [caption_input, setCaption_Input] = useState();

  //post data
  const [title, setTitle] = useState();
  const [desc, setDescription] = useState();
  const [page_image, setPageImage] = useState();
  const [page_image_caption, setPageImageCaption] = useState();
  const [loading, setLoading] = useState(true);
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [show_gallery, setShowGallery] = useState(0);
  const [show_buttons, setShowButtons] = useState(0);
  const [show_accordion, setShowAccordion] = useState(0);

  // buttons
  const [param, setParams] = useState('');
  const [buttonTitle, setButtonTitle] = useState('');
  const [buttonLink, setButtonLink] = useState('');
  const [buttons, setButtons] = useState([]);
  // accordion
  const [accordionTitle, setAccordionTitle] = useState('');
  const [accordioData, setAccordionData] = useState('');
  const [accordions, setAccoridons] = useState([]);
  const [editAcc, setEditAcc] = useState(false);
  const [editAccValues, setEditAccValues] = useState([]);
  const [accTitleEdit, setAccordionTitleEdit] = useState('');
  const [accDesEdit, setAccordionDescEdit] = useState('');
  // user id
  const token = localStorage.getItem('token');
  const userToken = token ? jwt(token) : '';
  const userID = userToken.id;
  const unselect = (item, id) => {
    const isImageSelected = page_images.some((image) => image.image === id);

    if (isImageSelected) {
      const updatedImages = page_images.filter((image) => image.image !== id);
      setPageImages(updatedImages);
      // Reset the `ima` value when the image is unselected
    } else {
    }
  };
  //upload image
  const onChangeImg = (event) => {
    if (event.target.files[0] != null) {
      uploadImage(event.target.files[0]);
    } else {
    }
  };
  const uploadImage = (img) => {
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/upload.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);

          setPageImage(data.image);
          showAlert('success', data.message);
        });
      })
      .catch((e) => {
        showAlert('success', 'Greška');
        console.log(e);
      });
  };
  //buttons func
  const clearButtonNames = () => {
    setButtonTitle('');
    setButtonLink('');
  };
  const httpHtml = (content) => {
    var myStr = content;
    return myStr.replace(/[*+,?^%/_${}()|[\]\\]/g, ' ');
  };
  const defaultVal = (val) => {
    const i = captions.map((item, index) => (val === index ? item.opis : null));

    return i;
  };
  const removeCaption = (value) => {
    const array = captions;
    const index = array.indexOf(value);
    console.log(index);
    array.splice(index, 1);
    setCaptions([...array]);
  };
  const updateAccordion = (val) => {
    // loop over the todos list and find the provided id.
    let updatedList = accordions.map((item) => {
      if (item.title === val) {
        return { title: accTitleEdit, data: accDesEdit }; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item
    });

    setAccoridons(updatedList);
  };

  const clearButton = (value) => {
    const array = buttons;
    const index = array.indexOf(value);
    array.splice(index, 1);
    setButtons([...array]);
  };
  //accordion func
  const clearAccordionButton = (value) => {
    const array = accordions;
    const index = array.indexOf(value);
    array.splice(index, 1);
    setAccoridons([...array]);
  };
  const clearAccordionButtonNames = () => {
    setAccordionData('');
    setAccordionTitle('');
  };

  //alert
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };
  //slike
  const dohvatiSlike = async (type) => {
    showModal(true);
  };
  const dohvatiSlikeGalerije = async (type) => {
    showModalGallery(true);
  };
  const folderi = (type) => {
    dohvatiSlike(type);
  };
  const dohvati = async (id) => {
    const get = await getPageDetails(id);

    setTitle(get[0].title);
    setDescription(get[0].description);
    setButtons(get[0].buttons ? JSON.parse(get[0].buttons) : []);
    setShowAccordion(get[0].show_accordion);
    setShowButtons(get[0].show_buttons);
    setShowGallery(get[0].show_gallery);
    setPageImage(get[0].image ? JSON.parse(get[0].image).url : '');
    setPageImageCaption(get[0].image ? JSON.parse(get[0].image).caption : []);
    //other
    setPageImages(get[0].gallery ? JSON.parse(get[0].gallery) : []);
    setAccoridons(get[0].accordion ? JSON.parse(get[0].accordion) : []);
    setCaptions(
      get[0].gallery_captions ? JSON.parse(get[0].gallery_captions) : []
    );
    setLoading(false);
  };
  useEffect(() => {
    dohvati(id);
  }, [id]);

  const handleChange = (value) => {
    setDescription(value);
  };
  const handleChangeAccordion = (value) => {
    setAccordionData(value);
  };
  const handleCaptionChange = (event, index) => {
    const updatedImages = [...page_images];
    updatedImages[index].caption = event.target.value;

    setPageImage(updatedImages);
  };
  const editAccordion = (val) => {
    setEditAcc((e) => !e);
    setEditAccValues(val);
    setAccordionDescEdit(val.data);
    setAccordionTitleEdit(val.title);
  };

  // opis slika
  const setVal = (val, index) => {
    setCaptions((value) => [...value, { opis: val, index: index }]);
  };

  const onSubmit = () => {
    const button_data = JSON.stringify(buttons);
    const image_data = {
      url: page_image,
      caption: page_image_caption,
    };
    const params = {
      id: id,
      title: title,
      description: desc,
      modified_user_id: userID,
      modified_time: moment().format(),
      show_gallery: show_gallery,
      show_buttons: show_buttons,
      show_accordion: show_accordion,
      buttons: button_data,
      accordion: JSON.stringify(accordions),
      gallery: JSON.stringify(page_images),
      gallery_captions: JSON.stringify(captions),
      image: JSON.stringify(image_data),
    };
    Axios.put(api + '/api/content/get/page/update', params, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
      })
      .catch((e) => {
        if (e.message === 'Request failed with status code 400') {
          showAlert('danger', 'Pripazite da je naslov unesen!');
        } else {
          showAlert('danger', e.message);
        }

        console.log(e);
      });
  };
  const deletePage = (id) => {
    Axios.delete(api + '/api/content/get/pages/remove/' + id, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
        setTimeout(() => {
          props.history.push('/stranice');
        }, 3000);
      })
      .catch((e) => {
        showAlert('danger', e.message);

        console.log(e);
      });
  };

  if (loading) {
    return (
      <div className='d-flex ' style={{ height: '90vh' }}>
        <p className='m-auto'>Loading posts...</p>
      </div>
    );
  }

  return (
    <section className='posts-list container-lg px-1'>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <p>Page id: {id}</p>
      <div className='flex items-center justify-between'>
        <h4>Uredi stranicu</h4>
        <h4 className='font-bold'>
          {' '}
          <span className='text-black'>{title}</span>
        </h4>
      </div>

      <div className='d-flex'>
        <button
          onClick={() => onSubmit()}
          className='bg-green-600 p-1 text-white px-3 rounded-md my-2'>
          Ažuriraj stranicu
        </button>
      </div>

      <h6 className='py-4'>Naziv stranice</h6>
      <input
        value={title}
        className='px-4 box-shadow bg-white py-3 bg-white border border-gray-100'
        onChange={(e) => setTitle(e.target.value)}
      />

      <h6 className='py-4'>Sadržaj</h6>
      <div className=' rounded-lg box-shadow bg-white py-6 border border-gray-100'>
        <EditorToolbar />
        <ReactQuill
          theme={'snow'}
          onChange={handleChange}
          value={desc}
          modules={modules}
          formats={formats}
          bounds={'.app'}
          placeholder={'Novi post'}
        />
      </div>
      <div className='px-4 box-shadow mt-4 bg-white rounded-md  py-3  border border-gray-100'>
        <h6 className='py-4'>Dodaj sliku</h6>
        <button
          className='btn bg-blue-900 text-white'
          onClick={() => dohvatiSlike()}>
          Arhiva
        </button>
        <div className='d-flex'>
          <div className='  p-2 rounded-md px-5'>
            {!page_image ? null : (
              <img
                style={{ height: '200px', objectFit: 'contain' }}
                src={'https://dev.hoo.hr/' + page_image}
              />
            )}
            {page_image}
            {modal ? (
              <FolderListSingle
                onClose={() => showModal(false)}
                listaSlika={listaSlika}
                folder={'clanci-slike'}
                selected={page_image}
                setImage={setPageImage}
              />
            ) : null}
            {page_image ? (
              <div>
                <input
                  className='px-4 box-shadow bg-white py-1 bg-white border border-gray-100'
                  placeholder='Opis slike...'
                  defaultValue={page_image_caption}
                  onChange={(e) => setPageImageCaption(e.target.value)}
                />
                <button onClick={() => setPageImage(null)}>Ukloni</button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <h6 className='py-4'>Dodatno</h6>
      <div className='mt-4 bg-white shadow-sm p-3 rounded-md'>
        <h5>Dodaj linkove</h5>
        <Switch
          onChange={() =>
            setShowButtons((show_buttons) => (show_buttons === 0 ? 1 : 0))
          }
          checked={show_buttons === 1}
        />

        {show_buttons === 1 ? (
          <div className='py-2 my-3'>
            {buttons.map((val, index) => (
              <li
                className='d-flex p-2 my-1 btn-info text-white rounded-md'
                style={{ listStyleType: 'none', maxWidth: '50%' }}
                key={index}>
                <span className='mt-auto mb-auto'>
                  {val.title} | {val.link}
                </span>
                <span
                  onClick={() => clearButton(val)}
                  className='mt-auto mb-auto ml-auto text-danger p-1 rounded-lg bg-white'>
                  <i class='bi bi-trash'></i>
                </span>
              </li>
            ))}
            <div className='d-flex mt-2'>
              <input
                className='m-1 w-50'
                placeholder='Ime linka'
                value={buttonTitle}
                onChange={(e) => setButtonTitle(e.target.value)}
              />
              <div style={{ width: '300px' }}>
                <CategoriesSelect
                  value={param}
                  setParams={setParams}
                  placeholder='Odaberi stranicu'
                />
              </div>
            </div>
            {buttonTitle}
            {param ? '/page' + '' + param + '/' + '1' : null}
            <div className='text-right'>
              <button
                onClick={() => {
                  {
                    setButtons((data) => {
                      return [
                        ...data,
                        {
                          title: buttonTitle,
                          link: '/page/' + '' + param + '/' + '1',
                        },
                      ];
                    });
                    clearButtonNames();
                  }
                }}
                className='btn btn-success my-2 '>
                Add
              </button>
            </div>
          </div>
        ) : null}
      </div>

      <div className='mt-4 bg-white shadow-sm p-3 rounded-md'>
        <h5>Dodaj padajuće</h5>
        <Switch
          onChange={() =>
            setShowAccordion((show_accordion) => (show_accordion === 0 ? 1 : 0))
          }
          checked={show_accordion === 1}
        />

        {show_accordion === 1 ? (
          <div className='py-2 my-3'>
            <hr />
            {accordions.map((val, index) => (
              <div>
                <div className='d-flex'>
                  <span
                    onClick={() => clearAccordionButton(val)}
                    className='mt-auto mb-auto ml-auto text-danger p-1 rounded-lg bg-white'>
                    <i class='bi bi-trash'></i>
                  </span>
                  <span
                    onClick={() => editAccordion(val)}
                    className='mt-auto mb-auto ml-2 text-danger p-1 rounded-lg bg-white'>
                    <i class='bi bi-pen'></i>
                  </span>
                  {editAcc && editAccValues.title === val.title ? (
                    <button
                      className='mt-auto mb-auto btn ml-2 bg-success  '
                      onClick={() => updateAccordion(val.title)}>
                      Azuriraj
                    </button>
                  ) : null}
                </div>
                <div
                  className='row p-1 mx-1 my-1 bg-gray-200 text-white rounded-md'
                  style={{ listStyleType: 'none' }}
                  key={index}>
                  <div className='col-md'>
                    <span className='mt-auto mb-auto text-black font-weight-bold'>
                      {val.title}
                    </span>
                    <div
                      className='accordion-p text-black'
                      dangerouslySetInnerHTML={{ __html: val.data }}
                    />
                  </div>

                  {editAcc && val.title === editAccValues.title ? (
                    <div className=' col-md bg-gray-600 p-0 rounded-md'>
                      <h5 className='px-3 pt-2'>Uredi</h5>

                      <div className=' rounded-md p-1'>
                        <input
                          placeholder='Naslov'
                          defaultValue={accTitleEdit}
                          onChange={(e) =>
                            setAccordionTitleEdit(e.target.value)
                          }
                        />
                      </div>
                      <div className=' p-2 py-4 bg-light text-black rounded-md'>
                        <ReactQuill
                          key={'id'}
                          theme={'snow'}
                          onChange={(value) => setAccordionDescEdit(value)}
                          value={accDesEdit}
                          modules={PageDetails.modules}
                          formats={PageDetails.formats}
                          bounds={'.app'}
                          placeholder={'Opis'}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ))}

            <div className='mt-4'>
              <h3 className='pt-1'>Dodaj novi padajući</h3>

              <div className='my-2 rounded-md  p-2'>
                <input
                  placeholder='Naslov'
                  className='py-3 bg-gray-200 mx-0'
                  value={accordionTitle}
                  onChange={(e) => setAccordionTitle(e.target.value)}
                />
              </div>
              <div className='p-2 py-4 bg-gray-200 rounded-md'>
                <ReactQuill
                  theme={'snow'}
                  onChange={handleChangeAccordion}
                  value={accordioData}
                  modules={PageDetails.modules}
                  formats={PageDetails.formats}
                  bounds={'.app'}
                  placeholder={'Opis'}
                />
              </div>
            </div>
            <div className='text-right'>
              <button
                onClick={() => {
                  {
                    setAccoridons((data) => {
                      return [
                        ...data,
                        { title: accordionTitle, data: accordioData },
                      ];
                    });
                    clearAccordionButtonNames();
                  }
                }}
                className='btn btn-success my-2 '>
                Add
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <div className='mt-4 bg-white shadow-sm p-3 rounded-md'>
        <h5>Dodaj galeriju</h5>
        <Switch
          onChange={() =>
            setShowGallery((show_gallery) => (show_gallery === 0 ? 1 : 0))
          }
          checked={show_gallery === 1}
        />

        {show_gallery === 1 ? (
          <div className='my-2'>
            <hr />
            <button
              onClick={() => dohvatiSlikeGalerije('test.php')}
              className='btn bg-blue-700 rounded-sm text-white mb-2'
              data-toggle='modal'
              data-target='#exampleModal'>
              Odaberi u arhivi
            </button>

            {modalGallery ? (
              <FolderList
                folder={'galerije'}
                multiple={true}
                withCaption={true}
                onClose={() => showModalGallery(false)}
                setImage={setPageImages}
                selected={page_images}
              />
            ) : null}
            <div className='row'>
              {page_images.map((i, index) => (
                <div className='col-sm-4'>
                  <small onClick={() => unselect(i, i.image)}>
                    <i class='bi bi-trash text-danger text-xl'></i>
                  </small>
                  <img src={'https://dev.hoo.hr/' + i.image} />
                  <input
                    type='text'
                    value={i.caption || ''}
                    placeholder='Caption'
                    onChange={(event) => handleCaptionChange(event, index)}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className='d-flex py-3 my-3'>
        <button
          className='btn ml-auto  text-danger ml-3 mb-2 font-weight-bold'
          onClick={() => deletePage(id)}>
          Izbriši stranicu
          <br />
          <small>Nakon brisanja nije moguće vratiti*</small>
        </button>
      </div>
    </section>
  );
};
PageDetails.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
PageDetails.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'document',

  'code-block',
];

export default PageDetails;
