import Axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import { getAutor } from '../Profile/GetComments';
import SelectSearch, { fuzzySearch } from 'react-select-search';

const DodajClanakOlimpa = () => {
  const [class_select, setClassSelect] = useState('d-none');
  const searchInput = useRef();
  const [_autori, setAutori] = useState([]);
  const [olimp, setOlimp] = useState([]);
  const [rubrike, setRubrike] = useState([]);
  //values
  const [naslov, setNaslov] = useState();
  const [podnaslov, setPodnaslov] = useState();
  const [broj_id, setBroj] = useState();
  const [rubrika_id, setRubrika] = useState();
  const [autor_id, setAutor] = useState([]);
  const [photo_autor_id, setAutorPhoto] = useState();
  const [stranica_start, setStranicaStart] = useState();
  const [stranica_kraj, setStranicaKraj] = useState();
  const [tagovi, setTagovi] = useState();
  const [puni_sardrzaj, setSadrzaj] = useState();
  //alert
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const get_Olimps = useCallback(async () => {
    try {
      const get = await Axios.get(api + '/api/content/olimp-brojevi', {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      setOlimp(get.data);

      return get;
    } catch (e) {
      console.log(e);
    }
  }, []);
  const get_Rubrike = useCallback(async () => {
    try {
      const get = await Axios.get(api + '/api/content/get/olimp-rubrike', {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      setRubrike(get.data);

      return get;
    } catch (e) {
      console.log(e);
    }
  }, []);
  const get_Autor = useCallback(async () => {
    const get = await getAutor();
    setAutori(get);
  }, []);
  const openSelectClass = () => {
    if (class_select == 'd-none') {
      setClassSelect('');
    } else {
      setClassSelect('d-none');
    }
  };
  const selectAutor = (value) => {
    setAutor(value);
    setClassSelect('d-none');
  };
  useEffect(() => {
    get_Autor();
    get_Rubrike();
    get_Olimps();
  }, [getAutor]);

  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const send = async (e) => {
    const au = autor_id.join('|');
    e.preventDefault();
    let val = [
      [
        naslov,
        podnaslov,
        broj_id,
        rubrika_id,
        au,
        photo_autor_id,
        stranica_start,
        stranica_kraj,
        tagovi,
        puni_sardrzaj,
      ],
    ];
    try {
      const res = await Axios.post(
        api + '/api/content/create-olimp-article',
        val,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      showAlert('success', res.data.message);
      document.getElementById('clanak-olimp').reset();
      return res;
    } catch (e) {
      console.log(e.message);
      showAlert('danger', e.message);
    }
  };
  const handleEditor = (value) => {
    setSadrzaj(value);
  };
  const options = Object.values(_autori).map((item) => ({
    name: item.ime + ' ' + item.prezime,
    value: item.id,
  }));
  return (
    <section className='posts-list '>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <button className='bg-blue-800 p-1 text-white px-3 rounded-md my-2'>
        <Link to='/dodaj-olimp'>Nazad</Link>
      </button>
      <h1>Novi Olimp članak</h1>
      <form onSubmit={send} id='clanak-olimp'>
        <button
          type='submit'
          className='bg-green-600  p-1 text-white px-3 rounded-md my-2'>
          Objavi
        </button>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Naslov članka</span>
            <input
              required
              onChange={(e) => setNaslov(e.target.value)}
              placeholder='Naslov'
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Podnaslov</span>
            <textarea rows={3} onChange={(e) => setPodnaslov(e.target.value)} />
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <select onChange={(e) => setBroj(e.target.value)}>
              <option>Odaberi broj Olimpa</option>
              {olimp.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.broj}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <select onChange={(e) => setRubrika(e.target.value)}>
              <option>Odaberi rubriku</option>
              {rubrike.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.naziv}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <select onChange={(e) => setAutorPhoto(e.target.value)}>
              <option>Odaberi photo autor id</option>
              {_autori.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.ime} {item.prezime}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Stranica start</span>
            <input
              type={'number'}
              onChange={(e) => setStranicaStart(e.target.value)}
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Stranica kraj</span>
            <input
              onChange={(e) => setStranicaKraj(e.target.value)}
              type={'number'}
            />
          </div>
        </div>
        <div>
          <span>Tagovi</span>
          <input
            placeholder='tagovi'
            onChange={(e) => setTagovi(e.target.value)}
          />
        </div>
        <div>
          <div className='shadow-sm py-4  rounded-md'>
            <span>Puni sadržaj</span>
            <ReactQuill
              theme={'snow'}
              onChange={handleEditor}
              value={puni_sardrzaj}
              modules={DodajClanakOlimpa.modules}
              formats={DodajClanakOlimpa.formats}
              bounds={'.app'}
              placeholder={'Opis hr'}
            />
          </div>
          <p className='py-4'>Odaberi autore</p>
          <div style={{ width: '60%' }} className='m-1  p-3'>
            <p
              className='bg-blue-600  p-1 text-white px-3 rounded-md my-2'
              onClick={openSelectClass}>
              Odaberi autora
            </p>
            <SelectSearch
              filterOptions={fuzzySearch}
              ref={searchInput}
              search
              options={options}
              id={class_select}
              closeOnSelect
              name='Autor'
              onChange={selectAutor}
              multiple
              value={autor_id}
              placeholder={'Odaberi autora'}
            />
            <br />
            {autor_id.join('|')}
          </div>
        </div>
      </form>
    </section>
  );
};
DodajClanakOlimpa.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
DodajClanakOlimpa.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'document',
];
export default DodajClanakOlimpa;
