import Axios from 'axios';
import { useEffect, useState } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import { natjecanja, natjecanjaEng } from '../../utils/constants';
import Sportasi_select from '../../components/Select/Sportasi_select';
import MedaljeSportasi from '../../components/Select/MedaljeSpoortasi';
import Switch from 'react-switch';
const DodajMedalju = (props) => {
  const [ime, setIme] = useState();
  const [med, setMedalja] = useState();
  const [sport, setSport] = useState();
  const [natjecanje, setNatjecanje] = useState();
  const [stranica, setStranica] = useState();
  const [stranicaEng, setStranicaEng] = useState();
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [param, setParams] = useState({});
  const [categories, setCategories] = useState([]);
  const [tim, setTim] = useState(false);
  const [timVal, setTimVal] = useState(null);
  const [loadin, setLoading] = useState(false);
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };
  const getCategories = async () => {
    try {
      const response = await Axios.get(
        'https://hoo-server.vercel.app/api/content/get/cats'
      );
      setLoading(false);
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  const addmedalja = (e) => {
    e.preventDefault();
    const params = {
      ime_prezime: JSON.stringify(param),
      medalja: med,
      sport: sport,
      natjecanje: natjecanje,
      page_id: stranica,
      page_id_eng: stranicaEng,
      tim: tim,
      timVal: timVal,
    };
    console.log(params);
    if (
      param !== undefined &&
      params.medalja != undefined &&
      params.sport !== undefined &&
      params.natjecanje !== undefined &&
      params.page_id !== undefined &&
      params.page_id_eng !== undefined
    ) {
      Axios.post(api + '/api/content/post/medalje', params, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          showAlert('success', res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 100);
        })
        .catch((e) => {
          showAlert('danger', e.message);
          console.log(e);
        });
    } else {
      showAlert('danger', 'Molimo Vas unesite sve podatke!');
    }
  };
  const selectNatjecanje = (value) => {
    setStranica(value);
  };
  const selectNatjecanjeEng = (value) => {
    setStranicaEng(value);
  };
  const options_cat = Object.values(categories).map((item) => ({
    name: item.title + ' - ' + item.id,
    value: item.id,
  }));
  console.log(param);
  return (
    <section className='posts-list bg-gray-100 min-h-screen'>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <h1 className='py-4'>Dodaj medalju i natjecanje</h1>
      <form className='container mt-4' onSubmit={addmedalja}>
        <button type={'submit'} className='btn my-2  bg-black text-white px-3'>
          Objavi
        </button>
        {param?.name}
        <div className='flex items-center space-x-5 py-3'>
          <span>Tim</span>
          <Switch
            offColor='#333'
            onColor='#8847d7'
            placeholder='Tim'
            onChange={() => setTim((v) => !v)}
            checked={tim}
          />
        </div>
        <div className='d-flex'>
          <div className='w-1/2'>
            <small>{tim ? 'Unesi tim' : 'Odaberi sportaša'}</small>
            {tim ? <input onChange={(e) => setTimVal(e.target.value)} /> : null}
            <MedaljeSportasi
              value={param}
              setParams={setParams}
              placeholder='Odaberi sportaša'
            />
          </div>

          <div className='w-1/2'>
            <small>Medalja</small>
            <input
              className='m-1'
              onChange={(e) => setMedalja(e.target.value)}
              name='medalja'
              placeholder='Vrsta medalje (BR.1  Zlatna, itd...)'
              max={3}
              type={'number'}
            />
          </div>
        </div>
        <div className='d-flex'>
          <input
            className='m-1'
            onChange={(e) => setSport(e.target.value)}
            name='sport'
            placeholder='Sport'
            type={'text'}
          />
          <input
            className='m-1'
            onChange={(e) => setNatjecanje(e.target.value)}
            name='natjecanje'
            placeholder='Natjecanje (OI Tokyo 2020)'
            type={'text'}
          />
        </div>

        {loadin ? (
          <p>Loading...</p>
        ) : (
          <>
            {' '}
            <small>Odaberi stranicu za prikaz hr</small>
            <SelectSearch
              options={options_cat}
              value={stranica}
              search
              name='Stranica medalje '
              onChange={selectNatjecanje}
              filterOptions={fuzzySearch}
              placeholder='Natjecanje'
            />
            <small>Odaberi stranicu za prikaz eng</small>
            <SelectSearch
              options={options_cat}
              value={stranicaEng}
              search
              name='Stranica medalje '
              filterOptions={fuzzySearch}
              onChange={selectNatjecanjeEng}
              placeholder='Natjecanje eng'
            />
          </>
        )}
      </form>
    </section>
  );
};

export default DodajMedalju;
