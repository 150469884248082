import Axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../components/Api/api';

const BrojeviOlimp = () => {
  const [olimp, setOlimp] = useState([]);
  const get_Olimps = useCallback(async () => {
    const get = await Axios.get(api + '/api/content/olimp-brojevi', {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    });
    setOlimp(get.data);

    return get;
  }, []);

  useEffect(() => {
    get_Olimps();
  }, [get_Olimps]);

  return (
    <section className='posts-list '>
      <button className='bg-blue-800 p-1 text-white px-3 rounded-md my-2'>
        <Link to='/dodaj-olimp'>Nazad</Link>
      </button>
      <h1>Brojevi olimp</h1>

      <div className='mt-3 p-1'>
        <table className='table-auto w-full'>
          <thead className='text-xs font-semibold uppercase '>
            <tr>
              <th className='p-2'>
                <div className='font-semibold text-left'>Broj</div>
              </th>

              <th className='p-2'>
                <div className='font-semibold text-left'>Godina</div>
              </th>
              <th className='p-2'>
                <div className='font-semibold text-left'>Mjesec</div>
              </th>
              <th className='p-2'>
                <div className='font-semibold text-left'>Link</div>
              </th>
              <th className='p-2'>
                <div className='font-semibold text-left'>Nakladnik.</div>
              </th>
              <th className='p-2'>
                <div className='font-semibold text-center'>Akcija</div>
              </th>
              <th className='p-2'>
                <div className='font-semibold text-center'>Akcija</div>
              </th>
            </tr>
          </thead>

          <tbody className='text-sm divide-y divide-gray-200  p-1'>
            {Object.values(olimp)
              .reverse()
              .map((item, index) => (
                <>
                  <tr className={index % 2 ? 'bg-gray-100' : 'bg-gray-200'}>
                    <td className='p-2' style={{ maxWidth: '300px' }}>
                      <div className='font-medium text-gray-800'>
                        <p className='text-uppercase mt-1 font-weight-bold onhover:text-red-300'>
                          {item.broj}
                        </p>
                      </div>
                    </td>
                    <td className='p-2'>
                      <div className='text-left'>{item.godina}</div>
                    </td>
                    <td className='p-2'>
                      <div className='text-left'>{item.mjesec}</div>
                    </td>
                    <td className='p-2 ' style={{ maxWidth: '200px' }}>
                      <div className='text-left font-medium text-blue-800 truncate'>
                        <a href={item.issuu}>{item.issuu}</a>
                      </div>
                    </td>
                    <td className='p-2' style={{ maxWidth: '200px' }}>
                      <div className='text-left font-medium text-black'>
                        <small> {item.nakladnik}</small>
                      </div>
                    </td>
                    <td>
                      <Link to={'/uredi-olimp/' + item.id}>
                        <button className='bg-green-600 p-1 text-white px-3 rounded-md my-2'>
                          Uredi
                        </button>
                      </Link>
                    </td>
                    <td className='p-2'>
                      <div className='flex justify-center'>
                        <button>
                          <svg
                            className='w-8 h-8 hover:text-red-600 text-red-500 rounded-full hover:bg-red-100 p-1'
                            fill='none'
                            stroke='currentColor'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'></path>
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <div style={{ height: '5px' }}></div>
                </>
              ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default BrojeviOlimp;
