import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ListaDokumenata = () => {
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dokumenti, setDokumenti] = useState(true);
  const [alert, setAlert] = useState(null);
  function replace(data) {
    let str = data;
    return str.replace('images/dokumenti/', '');
  }
  //https://hoocors.herokuapp.com/
  const dohvatiDokumente = async (type) => {
    const response = await fetch('https://dev.hoo.hr/stari-dokumenti.php').then(
      (response) => response.json()
    );
    setLoading(false);
    setDokumenti(response);
    console.log(response);
  };

  useEffect(() => {
    dohvatiDokumente();
  }, [fileName]);

  const onChangeImg = (event) => {
    if (event.target.files[0] != null) {
      console.log(event.target.files);
      for (let i = 0; i < event.target.files.length; i++) {
        uploadImage(event.target.files[i]);
      }
    } else {
    }
  };

  const uploadImage = (img) => {
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/upload.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);
          setFileName(data.url);

          setAlert(data.message);
          setTimeout(() => {
            setAlert(null);
          }, 5000);
        });
      })
      .catch((e) => {
        console.log(e);
        setTimeout(() => {
          setAlert(null);
        }, 5000);
      });
  };

  return (
    <>
      <h1>Dokumenti</h1>

      {loading ? (
        <div className='loader-div'>
          <div className='m-auto items-center justify-center '>
            <div
              style={{ borderTopColor: 'transparent' }}
              className='w-16 h-16 border-4 border-gray-500 border-solid rounded-full animate-spin'></div>
          </div>
        </div>
      ) : (
        <div className=' mt-5 pt-5'>
          <div className='container-lg'>
            {Object.values(dokumenti).map((item, index) => (
              <div
                key={index}
                className=' py-2 bg-light rounded-md my-1 px-2 border-none'>
                {item.type === '' ? (
                  <div className='d-flex'>
                    <div>
                      <small className='font-weight-normal'>
                        <b className='text-yellow-500'> Folder:</b>{' '}
                        <b>{item.name}</b>
                      </small>
                      <br />
                    </div>
                  </div>
                ) : (
                  <div className='d-flex'>
                    <div>
                      <small className='font-weight-normal'>
                        <b className='text-blue-800'> Naziv:</b>{' '}
                        <b>{item.name}</b>
                      </small>
                      <br />
                      <small className='font-weight-normal text-xs'>
                        <b className='text-blue-800'>Upload date:</b>{' '}
                        {item.date}
                      </small>
                      <br />
                      <small className='font-weight-normal text-xs'>
                        <b className='text-blue-800'>Skraćeni url:</b>{' '}
                        {item.data_name}
                      </small>
                      <br />
                      <small className='font-weight-normal text-xs'>
                        <b className='text-blue-800'>url:</b>{' '}
                        {'https://dev.hoo.hr/' + item.data_name}
                      </small>
                    </div>
                    <a
                      className='ml-auto mt-auto mb-auto text-blue-800'
                      target={'_blank'}
                      href={'https://dev.hoo.hr/' + item.data_name}>
                      Open
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ListaDokumenata;
