import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import jwt from 'jwt-decode';
import { useParams } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import ReactQuill from 'react-quill';
import DatePicker from 'react-datepicker';

import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import CategoriesSelect from '../../components/Select/CategoriesSelect';
import '../../components/Select/select-search.css';
import Modal from '../../components/Modal/Modal';
import Alert from '../../components/Alert/Alert';
import ModalImage from '../../components/Modal/ModalImage';
import api from '../../components/Api/api';
import MultipleCaption from '../../components/Modal/MultupleCaption';
import FolderList from '../NovaMedia/Media';
import FolderListSingle from '../NovaMedia/MediaModalSingle';

const PostDetails = (props) => {
  const { alias } = useParams();
  //galerija
  const [foldername, setFolderName] = useState('');
  const [page_images, setPageImage] = useState([]);
  const [modalGallery, showModalGallery] = useState(false);
  const [startdate, setStartDate] = useState();
  const [param, setParams] = useState();
  const [post, setPost] = useState([]);
  const [name, setName] = useState(null);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState();
  const [intro, setIntroText] = useState();
  const [language, setLangugage] = useState();
  const [imageCaption, setCaption] = useState();
  const [post_image, setImage] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [modal, showModal] = useState(false);
  const [fileName, setFileName] = useState('');
  const [listaSlika, setSlike] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [alert, setAlert] = useState(null);
  const [editorValue, setEditor] = useState();
  const [AlertShow, setShowAlert] = useState(false);
  const [id, setId] = useState();
  const quillRef = useRef(null);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],

        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );
  function imageHandler() {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    const value = prompt('Please enter the image URL');

    if (value && range) {
      editor.insertEmbed(range.index, 'image', value, 'user');
    }
  }
  const options = [
    {
      name: 'Objavljeno',
      value: 1,
    },
    {
      name: 'Neobjavljeno',
      value: 0,
    },
  ];
  const optionsLangugage = [
    {
      name: 'Engleski',
      value: 'en-GB',
    },
    {
      name: 'Hrvatski',
      value: 'hr-HR',
    },
  ];
  const unselect = (item, id) => {
    const isImageSelected = page_images.some((image) => image.image === id);

    if (isImageSelected) {
      const updatedImages = page_images.filter((image) => image.image !== id);
      setPageImage(updatedImages);
      // Reset the `ima` value when the image is unselected
    } else {
    }
  };
  useEffect(() => {
    setImage(page_images);
  }, [setPageImage]);
  const onChangeImg = (event) => {
    if (event.target.files[0] != null) {
      setName(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      uploadImage(event.target.files[0]);
    } else {
    }
  };

  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };
  const UpdatePost = async () => {
    const token = localStorage.getItem('token');
    const userToken = token ? jwt(token) : '';
    const userID = userToken.id;
    const slika = {
      float_fulltext: '',
      float_intro: '',
      image_fulltext: post_image,
      image_fulltext_alt: '',
      image_fulltext_caption: imageCaption,
      image_intro: post_image,
      image_intro_alt: '',
      image_intro_caption: '',
    };

    const params = {
      id: id,
      fulltext: editorValue,
      images: JSON.stringify(slika),
      title: title,
      introtext: intro,
      catid: param,
      modified: moment().format(),
      state: status,
      modified_by: userID,
      language: language,
      created: startdate,
      gallery: JSON.stringify(page_images),
    };
    axios
      .put(api + '/api/content/get/post/update', params, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log(res.data.message);
        showAlert('success', res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        showAlert('danger', 'Nešto nije u redu!');
      });
  };
  const deletePost = (id) => {
    axios
      .delete(api + '/api/content/remove/post/' + '' + id, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log(res.data.message);
        showAlert('success', res.data.message);
        setTimeout(() => {
          props.history.push('/');
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadImage = (img) => {
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/clanci-slike.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);
          setFileName(data.url);
          setImage(data.image);
          showAlert('success', data.message);
        });
      })
      .catch((e) => {
        console.log(e);
        showAlert('success', e.message);
      });
  };
  const setValues = (data) => {
    if (data[0]) {
      setId(data[0].id);
      setEditor(data[0].fulltext);
      setTitle(data[0].title);
      setIntroText(data[0].introtext);
      setCaption(
        data[0].images ? JSON.parse(data[0].images).image_fulltext_caption : ''
      );
      setImage(data[0].images ? JSON.parse(data[0].images).image_fulltext : '');
      setParams(data[0].catid);
      setStatus(data[0].state);
      setStartDate(new Date(data[0].created));
      setLangugage(data[0].language);
      setPageImage(data[0].gallery ? JSON.parse(data[0].gallery) : []);
    }
  };
  useEffect(() => {
    axios
      .get(`${api}/api/content/get/single/${alias}`)
      .then((response) => {
        const data = response.data;

        setPost(data);
        setValues(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [alias]);
  const dohvatiSlike = async (type) => {
    setFolderName(type);
    showModal(true);
    setLoading(false);
  };
  const dohvatiSlikeGalerije = async (type) => {
    setFolderName(type);
    showModalGallery(true);
    setLoading(false);
  };
  const folderi = (type) => {
    dohvatiSlike(type);
  };
  const handleChange = (value) => {
    setEditor(value);
  };
  const handleChangeIntro = (value) => {
    setIntroText(value);
  };
  const selectLangugage = (value) => {
    setLangugage(value);
  };

  const select = (value) => {
    setStatus(value);
  };

  const handleCaptionChange = (event, index) => {
    const updatedImages = [...page_images];
    updatedImages[index].caption = event.target.value;

    setPageImage(updatedImages);
  };
  let preview;
  if (post_image != null) {
    preview = <img src={'https://dev.hoo.hr/' + post_image} />;
  } else {
    preview = <span></span>;
  }

  if (loading || !post) {
    return (
      <div className='loader-div'>
        <div className='m-auto items-center justify-center '>
          <div
            style={{ borderTopColor: 'transparent' }}
            className='w-16 h-16 border-4 border-gray-500 border-solid rounded-full animate-spin'></div>
        </div>
      </div>
    );
  }

  return (
    <div className='container-fluid'>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />

      {modal ? (
        <FolderListSingle
          onClose={() => showModal(false)}
          listaSlika={listaSlika}
          folder={foldername}
          selected={post_image}
          setImage={setImage}
        />
      ) : null}
      <h1>Uredi post</h1>

      <div className='row '>
        <div className='col-md'>
          <h6 className='py-4'>Naslov</h6>
          <input
            placeholder='title'
            value={title}
            defaultValue={title}
            name='name'
            rows={2}
            className=' rounded-lg bg-transparent box-shadow py-6 border border-gray-100'
            onChange={(e) => setTitle(e.target.value)}
          />
          <h6 className='py-4'>Uvodni text</h6>
          <div className=' rounded-lg box-shadow py-6 border border-gray-100'>
            <ReactQuill
              theme={'snow'}
              onChange={handleChangeIntro}
              value={intro}
              modules={PostDetails.modules}
              formats={PostDetails.formats}
              bounds={'.app'}
              placeholder={'Intro text'}
            />
          </div>
          <h6 className='py-4'>Sadržaj</h6>
          <div className=' rounded-lg box-shadow py-6 border border-gray-100'>
            <ReactQuill
              theme={'snow'}
              ref={quillRef}
              onChange={handleChange}
              value={editorValue}
              modules={modules}
              formats={PostDetails.formats}
              bounds={'.app'}
              placeholder={'Novi post'}
            />
          </div>
        </div>

        <div className='col-sm-4 bg-gray-50 mx-1'>
          <button
            className='bg-green-200 p-1 text-black px-3 my-2'
            onClick={() => UpdatePost()}>
            Ažuriraj
          </button>
          <button
            type='button'
            className='bg-red-400 p-1 text-white px-3 ml-2  my-2'
            data-toggle='modal'
            data-target='#exampleModal2'>
            Ukloni post
          </button>
          <hr />
          <small>Vrijeme objave</small>

          <DatePicker
            selected={startdate}
            dateFormat='dd-MM-yyyy'
            onChange={(date) => setStartDate(date)}
          />
          <hr />

          <small>Slika</small>
          <br />
          <small
            className={
              alert === 'Image uploaded' ? 'text-success' : 'text-danger'
            }>
            {alert}
          </small>
          {preview}

          <small>Opis slike</small>
          <input
            defaultValue={imageCaption}
            placeholder='Image caption'
            onChange={(e) => setCaption(e.target.value)}
          />
          <small> Pretraži i dodaj iz arhive</small>
          <br />

          <button
            onClick={() => dohvatiSlike('clanci-slike')}
            type='button'
            className='bg-blue-900 p-1 text-white px-3  my-2'
            data-toggle='modal'
            data-target='#exampleModal'>
            Arhiva nova
          </button>
          <button
            onClick={() => dohvatiSlike('images')}
            type='button'
            className='bg-black ml-1 p-1 text-white px-3  my-2'
            data-toggle='modal'
            data-target='#exampleModal'>
            Arhiva stara
          </button>
          <br />
          <small>Kategorija</small>
          <div style={{ width: '100%' }}>
            <CategoriesSelect
              value={param}
              setParams={setParams}
              placeholder='Odaberi kategoriju'
            />
          </div>
          <div className='mb-3' />
          <small>Status</small>
          <SelectSearch
            options={options}
            value={status}
            name='language'
            onChange={select}
            placeholder='Status'
          />
          <small>Jezik</small>
          <SelectSearch
            options={optionsLangugage}
            value={language}
            name='language'
            onChange={selectLangugage}
            placeholder='jezik'
          />
        </div>
      </div>
      <h6 className='py-4'>Galerija </h6>
      <p>Odaberi slike iz galerija</p>
      <button
        onClick={() => dohvatiSlikeGalerije('images')}
        className='btn bg-black  text-white mb-2'
        data-toggle='modal'
        data-target='#exampleModal'>
        Arhiva stara
      </button>
      <button
        onClick={() => dohvatiSlikeGalerije('galerije')}
        className='btn bg-blue-900 ml-2  text-white mb-2'
        data-toggle='modal'
        data-target='#exampleModal'>
        Arhiva nova
      </button>

      {modalGallery ? (
        <FolderList
          multiple={true}
          folder={foldername}
          withCaption={true}
          onClose={() => showModalGallery(false)}
          listaSlika={listaSlika}
          setImage={setPageImage}
          selected={page_images}
        />
      ) : null}
      <div className='row'>
        {page_images.length > 0
          ? page_images.map((i, index) => (
              <div className='col-sm-4'>
                <small onClick={() => unselect(i, i.image)}>
                  <i class='bi bi-trash text-danger text-xl'></i>
                </small>
                <img src={'https://dev.hoo.hr/' + i.image} />
                <input
                  type='text'
                  value={i.caption || ''}
                  placeholder='Caption'
                  onChange={(event) => handleCaptionChange(event, index)}
                />
              </div>
            ))
          : null}
      </div>
      <div
        class='modal fade'
        id='exampleModal2'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'>
        <Modal
          onPress={() => deletePost(id)}
          title='Ukloni post'
          value='Da li sigurno želite obrisati?'
          content=''
        />
      </div>
    </div>
  );
};
PostDetails.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      {
        color: ['red', 'blue', 'yellow'],
      },
    ],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true,
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
PostDetails.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',

  'color',
];

export default PostDetails;
