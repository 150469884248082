const Settings = () => {
  return (
    <section className='posts-list p-3'>
      <h1>Settings</h1>
      <div className='p-3'>
        <p>Primjeri unosa podataka</p>
        <hr />
        <p>Info</p>
        <small>https://hoo.hr</small>
        <br />
        <small>Svi dokumenti će se nalaziti na domeni https://dev.hoo.hr</small>
        <hr />
        <p>Dodavanje stranice u editor</p>
        <small>"/page/111" ili https://hoo.hr/page/111</small>
        <hr />

        <small>Novi dokumenti </small>
        <br />
        <small>
          https://dev.hoo.hr/dokumenti/javna%20nabava/ODLUKA_V._IZMJENA_Plan_nabave_2022.pdf
        </small>
        <hr />
        <p>Dodavanje linka u editor</p>
        <small>Dodati opciju "underline"</small>
        <hr />
        <p>Dodavanje buttona u editor</p>
        <small>Dodati opciju "line through text"</small>
      </div>
    </section>
  );
};

export default Settings;
