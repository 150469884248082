import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPosts } from '../store/actions/actions';
import AllPosts from './Posts/AllPosts';

const Home = () => {
  const posts = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [language, setLng] = useState('hr-HR');
  const [titleKey, setTitleKey] = useState('');

  useEffect(() => {
    dispatch(
      getPosts(page, language, titleKey.trim().length !== 0 ? titleKey : '-')
    );
  }, [page, getPosts, language]);

  const updateValue = ({ target }) => {
    setPage(target.value);
  };
  const updateLngValue = ({ target }) => {
    setLng(target.value);
  };
  const searchTitle = (e) => {
    e.preventDefault();
    dispatch(getPosts(page, language, titleKey ? titleKey : '-'));
    setPage(1);
  };

  if (posts.loading || posts.posts === null) {
    return (
      <div className='d-flex ' style={{ height: '90vh' }}>
        <p className='m-auto'>Loading posts...</p>
      </div>
    );
  }

  const number = posts.posts.posts[0]
    ? (posts.posts.posts[0].total_records * 1).toFixed(0)
    : '0';

  const c_pages = number;

  return (
    <section className='posts-list p-2'>
      <div className='container-lg'>
        <h3 className='pb-2'>Svi postovi</h3>
        <div className='flex justify-between  p-3  py-2'>
          <div className='col-m'>
            <select style={{ maxWidth: '200px' }} onChange={updateValue}>
              <option selected>Stranica {page}</option>
              {[...Array(posts.posts.numPages)].map((e, i) => (
                <option defaultChecked={page} value={i + 1} key={i}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>
          <div className='col-md'>
            <form className='container-lg' onSubmit={searchTitle}>
              <div className='row '>
                <div className='col '>
                  <input
                    defaultValue={'-'}
                    placeholder='Pretraži prema naslovu'
                    value={titleKey}
                    onChange={(e) => setTitleKey(e.target.value)}
                  />
                </div>
                <div className='col m-auto'>
                  <button
                    type='submit'
                    className='bg-black  py-1 px-3 rounded-sm text-white'>
                    Pretraži
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className='col-sm-2'>
            <select onChange={updateLngValue}>
              <option defaultChecked={language} value={'hr-HR'}>
                Hrvatski
              </option>
              <option defaultChecked={language} value={'en-GB'}>
                Engleski
              </option>
            </select>
          </div>
        </div>
      </div>

      <AllPosts posts={posts.posts} />
      <select style={{ maxWidth: '200px' }} onChange={updateValue}>
        <option selected>Stranica {page}</option>
        {[...Array(c_pages)].map((e, i) => (
          <option value={i + 1} key={i}>
            {i + 1}
          </option>
        ))}
      </select>
    </section>
  );
};

export default Home;
