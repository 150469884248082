import Axios from 'axios';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import Switch from 'react-switch';
const AllPosts = (props) => {
  const [AlertShow, setShowAlert] = useState(false);
  const [pignedLoading, setPignedLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [pinged, setPing] = useState([]);

  const addToIzdvojeno = (id, val) => {
    const param = {
      id: id,
      val: val,
    };
    Axios.put(api + '/api/content/get/update-izdvojeno', param, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        showAlert('danger', err.message);
      });
  };
  const addToPing = (id, val) => {
    const param = {
      id: id,
    };

    if (!val) {
      Axios.delete(
        'https://hoo-server.vercel.app/api/content/get/ping-remove/' + id,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
          },
        }
      )
        .then((res) => {
          showAlert('success', 'Unpinged');
          get();
        })
        .catch((err) => {
          showAlert('danger', err.message);
        });
    } else if ((val && pinged.length === 3) || pinged.length > 3) {
      showAlert('warning', 'Dozvoljeno je odabrati samo 3 posta!');
    } else if (val) {
      Axios.post(
        'https://hoo-server.vercel.app/api/content/get/ping-post',
        param,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
          },
        }
      )
        .then((res) => {
          console.log(res.data.message);
          if (res.data.message === 'Dozvoljeno je odabrati samo 3 posta!') {
            showAlert('warning', 'Dozvoljeno je odabrati samo 3 posta!');
          } else {
            showAlert('success', 'Dodano');
            get();
          }
        })
        .catch((err) => {
          console.log(err);
          showAlert('danger', err.message);
        });
    }
  };
  const get = useCallback(() => {
    Axios.get(api + '/api/content/get/ping-get')
      .then((res) => {
        setPing(res.data);
        setPignedLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    get();
  }, [get]);
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const { posts } = props;
  if (!posts) {
    return null;
  }

  return (
    <div>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      {pignedLoading ? null : pinged ? (
        pinged.length < 3 || !pinged ? (
          <div className='text-white p-1 mb-2 bg-red-500 w-1/3'>
            <small> Upozorenje! Broj "pigned" članaka mora biti 3</small>
          </div>
        ) : null
      ) : null}

      <div class='flex flex-col'>
        <div class='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div class=' align-middle inline-block min-w-full sm:px-3 lg:px-8'>
            <div class=' overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table class='min-w-full divide-y divide-gray-900 '>
                <thead class='bg-white'>
                  <tr>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-black tracking-wider'>
                      #
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-black tracking-wider'>
                      Naslov
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-black tracking-wider'>
                      Kategorija
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-black tracking-wider'>
                      Vrijeme / Jezik
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-black tracking-wider'>
                      Izdvojeno
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-black tracking-wider'>
                      Status
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-black tracking-wider'>
                      Ping
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-black tracking-wider'>
                      Uredi
                    </th>
                  </tr>
                </thead>
                <tbody class='bg-white divide-y  divide-gray-50 '>
                  {posts.posts.map((item, i) => (
                    <tr
                      key={item.id}
                      className={i % 2 ? 'bg-gray-200 ' : 'bg-white '}>
                      <td class='px-3 py-0 wrap ' style={{ maxWidth: '90px' }}>
                        <img
                          style={{
                            width: '80px',
                            height: '80px',
                            objectFit: 'contain',
                          }}
                          src={
                            'https://dev.hoo.hr/' +
                            '' +
                            JSON.parse(item.images).image_fulltext
                          }
                        />
                      </td>
                      <td class='px-3 py-0 wrap ' style={{ maxWidth: '250px' }}>
                        <p className='font-weight-light text-xs text-truncate'>
                          {' '}
                          {item.title}
                        </p>
                      </td>
                      <td class='px-3  wrap' style={{ maxWidth: '200px' }}>
                        <small className='text-2xl'>{item.catName}</small>
                      </td>
                      <td
                        class='px-3  whitespace-nowrap'
                        style={{ maxWidth: '200px' }}>
                        <small className='text-black'>
                          {moment(item.created).format('DD-MM-YYYY u HH:mm:ss')}
                        </small>
                        <br />
                        <small
                          className={
                            item.language == 'hr-HR'
                              ? 'text-green-400'
                              : 'text-blue-400'
                          }>
                          {item.language == 'hr-HR' ? 'Hrvatski' : 'Engleski'}
                        </small>
                      </td>
                      <td class='px-3  whitespace-nowrap text-right text-sm font-medium'>
                        <Switch
                          offColor='#333'
                          onColor='#68e593'
                          onChange={() =>
                            addToIzdvojeno(item.id, item.izdvojeno == 1 ? 0 : 1)
                          }
                          checked={item.izdvojeno == 1}
                        />
                      </td>
                      <td class='px-3  whitespace-nowrap text-sm text-center text-gray-500'>
                        {item.state === 1 ? (
                          <p className='text-success'>Objavljeno</p>
                        ) : (
                          'Skica'
                        )}
                      </td>
                      <td>
                        <Switch
                          offColor='#333'
                          onColor='#8847d7'
                          placeholder='Ping'
                          onChange={() =>
                            addToPing(
                              item.id,
                              pinged.some((i) => i.post_id === item.id)
                                ? false
                                : true
                            )
                          }
                          checked={pinged.some((i) => i.post_id === item.id)}
                        />
                      </td>
                      <td class='px-3  whitespace-nowrap text-right text-sm font-medium'>
                        <div className='d-flex'>
                          <Link
                            to={{
                              pathname: `/post/detalji/${item.alias}`,
                              state: { postValue: item.fulltext },
                            }}>
                            <button class='bg-transparent  px-3 rounded-md text-black mr-1   '>
                              Uredi <i class='bi bi-arrow-right'></i>
                            </button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPosts;
