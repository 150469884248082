import Axios from 'axios';

import { useState } from 'react';
import ReactQuill from 'react-quill';

import SelectSearch from 'react-select-search';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import SportoviSelect from '../../components/Select/SportoviSelect';
import FolderListSingle from '../NovaMedia/MediaModalSingle';

const DodajSportasa = (props) => {
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  // data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Ime, setIme] = useState('');
  const [Prezime, setPrezime] = useState('');
  const [tekst, setTekst] = useState('');
  const [slika, setSlika] = useState('');
  const [sport, setSport] = useState();
  const [listaSlika, setSlike] = useState([]);
  const [aktivan, setAktivan] = useState();
  const [EVB, setEVB] = useState();
  const [oib, setOib] = useState();
  const [azurirano, setAzurirano] = useState();
  const [olimpijac, setOlimpijac] = useState();
  const [tekstEng, setTekstEng] = useState('');
  const [alert, setAlert] = useState(null);

  const [post_image, setImage] = useState(null);
  const [modal, showModal] = useState(false);
  const [foldername, setFolderName] = useState('');
  const optionsAktivan = [
    {
      name: 'Aktivan',
      value: 1,
    },
    {
      name: 'Neaktivan',
      value: 0,
    },
  ];
  const optionsOlimpijac = [
    {
      name: 'Da',
      value: 1,
    },
    {
      name: 'Ne',
      value: 0,
    },
  ];

  const post = (e) => {
    e.preventDefault();

    const params = {
      sportid: sport,
      Ime: Ime,
      Prezime: Prezime,
      tekst: tekst,
      tekst_eng: tekstEng,
      slika: post_image,
      aktivan: aktivan,
      EVB: EVB,
      azurirano: new Date(),
      olimpijac: olimpijac,
    };
    console.log(params);
    Axios.post(api + '/api/sportasi/add', params, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
      .catch((err) => showAlert('danger', err.message));
  };
  const dohvatiSlike = async (type) => {
    setFolderName(type);
    showModal(true);
    setLoading(false);
  };
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const handleChangeIntro = (value) => {
    setTekst(value);
  };
  const handleChangeIntroEN = (value) => {
    setTekstEng(value);
  };
  let preview;
  if (post_image) {
    preview = (
      <img
        className='w-1/5'
        src={'https://dev.hoo.hr/' + post_image}
        alt='File preview'
      />
    );
  }
  if (loading && !data) {
    return <div>Loading...</div>;
  }
  return (
    <section className='posts-list bg-gray-100 p-2'>
      <h1>Dodaj sportaša</h1>

      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      {modal ? (
        <FolderListSingle
          onClose={() => showModal(false)}
          listaSlika={listaSlika}
          folder={foldername}
          selected={post_image}
          setImage={setImage}
        />
      ) : null}
      <form onSubmit={post}>
        <div className='d-flex'>
          <button
            type='submit'
            className='btn bg-blue-900 rounded-none text-white ml-auto'>
            Objavi sportaša
          </button>
        </div>

        <small>Ime i prezime</small>
        <div className='d-flex'>
          <input value={Ime} onChange={(e) => setIme(e.target.value)} />
          <input
            className='ml-1'
            value={Prezime}
            onChange={(e) => setPrezime(e.target.value)}
          />
        </div>
        <small>Slika</small>
        <br />
        <small
          className={
            alert === 'Image has uploaded' ? 'text-success' : 'text-danger'
          }>
          {alert}
        </small>
        {preview}
        <button
          onClick={() => dohvatiSlike('images/sportasi/slike-profila/')}
          type='button'
          className='bg-black 0 p-1 text-white px-3  my-2'
          data-toggle='modal'
          data-target='#exampleModal'>
          Arhiva sportaša
        </button>
        <SportoviSelect
          value={sport}
          setParams={setSport}
          placeholder='Odaberi kategoriju'
        />
        <small>Aktivan</small>
        <SelectSearch
          options={optionsAktivan}
          value={aktivan}
          name=''
          onChange={(value) => setAktivan(value)}
          placeholder='Aktivan'
        />
        <small>Olimpijac</small>
        <SelectSearch
          options={optionsOlimpijac}
          value={olimpijac}
          name=''
          onChange={(value) => setOlimpijac(value)}
          placeholder='Olimpijac'
        />
        <small>Opis HR</small>
        <div className='shadow-sm p-2 py-4 bg-light rounded-md'>
          <ReactQuill
            theme={'snow'}
            onChange={handleChangeIntro}
            value={tekst}
            modules={DodajSportasa.modules}
            formats={DodajSportasa.formats}
            bounds={'.app'}
            placeholder={'Opis hr'}
          />
        </div>
        <small>Opis Eng</small>
        <div className='shadow-sm p-2 py-4 bg-light rounded-md'>
          <ReactQuill
            theme={'snow'}
            onChange={handleChangeIntroEN}
            value={tekstEng}
            modules={DodajSportasa.modules}
            formats={DodajSportasa.formats}
            bounds={'.app'}
            placeholder={'Opis eng'}
          />
        </div>
      </form>
    </section>
  );
};
DodajSportasa.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
DodajSportasa.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'document',
];
export default DodajSportasa;
