import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';

const AutoriOlimp = () => {
  const [autori, setAutori] = useState([]);

  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const get = async () => {
    try {
      const res = await Axios.get(api + '/api/content/get/olimp-autori');

      setAutori(res.data);
    } catch (e) {
      console.log(e);
      showAlert('danger', e.message);
    }
  };
  useEffect(() => {
    get();
  }, []);
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const remove = async (id) => {
    try {
      const res = await Axios.delete(
        api + '/api/content/delete-autor-olimp/' + id,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      showAlert('success', 'Autori izbrisan');
      get();
      return res;
    } catch (e) {
      console.log(e.message);
      showAlert('danger', e.message);
    }
  };
  const data = autori.reverse();
  return (
    <div>
      <h1>Autori Olimp</h1>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <Link to='/dodaj-autora-olimp'>
        <button className='bg-blue-800 p-1 text-white px-3 rounded-md my-2'>
          Dodaj autora
        </button>
      </Link>
      <div class='flex flex-col'>
        <div class='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div class='py-2 align-middle inline-block min-w-full sm:px-3 lg:px-8'>
            <div class='shadow-sm overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table class='min-w-full divide-y divide-gray-200 '>
                <thead class='bg-blue-50'>
                  <tr>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      #
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Ime
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Prezime
                    </th>
                    <th
                      scope='col'
                      class='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Firma
                    </th>

                    <th scope='col' class='relative px-3 py-3'>
                      Uredi
                    </th>
                  </tr>
                </thead>
                <tbody class='bg-white divide-y divide-gray-200 '>
                  {data.map((item, i) => (
                    <tr key={item.id} className={i % 2 ? 'bg-gray-100' : ''}>
                      <td class='px-3 py-2 wrap ' style={{ maxWidth: '30px' }}>
                        #{item.id}
                      </td>
                      <td class='px-3 py-2 wrap ' style={{ maxWidth: '50px' }}>
                        <small className='text-2xl'>{item.ime}</small>
                      </td>
                      <td class='px-3 py-2 wrap' style={{ maxWidth: '50px' }}>
                        <small className='text-2xl'>{item.prezime}</small>
                      </td>
                      <td
                        className='px-3 py-2 whitespace-nowrap text-sm text-gray-500'
                        style={{ maxWidth: '50px' }}>
                        {item.firma == 1 ? 'True' : 'False'}
                      </td>

                      <td class='px-3 py-2 whitespace-nowrap text-right text-sm font-medium'>
                        <div className='d-flex'>
                          <button
                            onClick={() => remove(item.id)}
                            type='submit'
                            className='bg-red-500 p-1 text-white px-3 rounded-md my-2'>
                            Ukloni
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoriOlimp;
