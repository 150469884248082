import Axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../components/Api/api';
import { getKategorizacije } from './Profile/GetComments';
import jwtDecode from 'jwt-decode';

const Dashboard = () => {
  const [lng, setLng] = useState('hr-HR');
  const [param, setParams] = useState();
  const [data, setData] = useState([]);
  const [kategorizacije, setKategorizirani] = useState([]);
  const [dataEng, setDataEng] = useState([]);
  const [publish, setPublish] = useState([]);
  const [unpublish, setUnpublish] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const userToken = token ? jwtDecode(token) : '';
  const role = userToken.role;
  const getLastPosts = () => {
    Axios.get(`${api}/api/content/get/sidebar/${lng}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getSportasi = async () => {
    const get = await getKategorizacije();
    const val = Object.keys(get).length;
    setKategorizirani(val);
    setLoading(false);
  };
  const getLastPostsEng = () => {
    Axios.get(`${api}/api/content/get/sidebar/en-GB`)
      .then((res) => {
        setDataEng(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const countPostsPublish = () => {
    Axios.get(`${api}/api/content/count-posts/1`)
      .then((res) => {
        setPublish(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const countPostsUnpublish = () => {
    Axios.get(`${api}/api/content/count-posts/0`)
      .then((res) => {
        setUnpublish(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    countPostsUnpublish();
    countPostsPublish();
    getLastPosts();
    getSportasi();
    getLastPostsEng();
  }, [lng]);
  if (loading) {
    return (
      <div className='loader-div'>
        <div className='m-auto items-center justify-center '>
          <div
            style={{ borderTopColor: 'transparent' }}
            className='w-16 h-16 border-4 border-gray-500 border-solid rounded-full animate-spin'></div>
        </div>
      </div>
    );
  }
  if (
    userToken.role == 'dokumenti' ||
    role == 'sportasi' ||
    role == 'kategorizacije' ||
    role == 'olipm'
  ) {
    return <div></div>;
  }

  return (
    <div className='container-lg px-2  p-3'>
      <h6 className='py-4'>Administracija</h6>
      <div className='d-flex py-2'>
        {publish.map((item) => (
          <div className='border-l border-black  text-black  mt-auto mb-auto p-6 m-2'>
            <h6> Objavljeni postovi:</h6>

            <span className='text-xl '>{item.total_posts}</span>
          </div>
        ))}
        {unpublish.map((item) => (
          <div className='border-l border-black  mt-auto mb-auto p-6 m-2'>
            <h6> Neobjavljeni postovi:</h6>

            <span className=' text-xl'>{item.total_posts}</span>
          </div>
        ))}
        <div className='border-l border-black  text-black  mt-auto mb-auto p-6 m-2'>
          <h6> Kategorizirani sportaši:</h6>

          <span className=' text-xl'>{kategorizacije}</span>
        </div>
      </div>

      <div className='row mt-1'>
        <div className='col-md-6 '>
          <h6 className='py-2 text-xl'>Hrvatski</h6>

          {data.map((item, index) => (
            <li className='bg-white shadow-on  pr-3  mt-2  d-flex '>
              <img
                className='mr-2 '
                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                src={
                  'https://dev.hoo.hr/' +
                  '' +
                  JSON.parse(item.images).image_fulltext
                }
              />
              <small className='mr-auto mt-auto mb-auto text-xs font-semibold text-black'>
                {item.title.slice(0, 75)} ...
              </small>
              <Link
                className='ml-auto  mt-auto mb-auto'
                to={{
                  pathname: `/post/detalji/${item.alias}`,
                  state: { postValue: item.fulltext },
                }}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='currentColor'
                  class='w-6 h-6 text-green-500'>
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59'
                  />
                </svg>
              </Link>
            </li>
          ))}
        </div>
        <div className='col-md-6 '>
          <h6 className='py-2 text-xl'>Engleski</h6>

          {dataEng.map((item, index) => (
            <li className='bg-white shadow-on pr-2   mt-2  d-flex '>
              <img
                className='mr-2 '
                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                src={
                  'https://dev.hoo.hr/' +
                  '' +
                  JSON.parse(item.images).image_fulltext
                }
              />
              <small className='mr-auto mt-auto mb-auto text-xs font-semibold text-black'>
                {item.title.slice(0, 75)} ...
              </small>
              <Link
                className='ml-auto  mt-auto mb-auto'
                to={{
                  pathname: `/post/detalji/${item.alias}`,
                  state: { postValue: item.fulltext },
                }}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='currentColor'
                  class='w-6 h-6 text-green-500'>
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59'
                  />
                </svg>
              </Link>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
