import { useEffect, useState } from 'react';

import { getPages } from '../Profile/GetComments';
import SelectSearch from 'react-select-search';
import '../../components/Select/select-search.css';
import PageSelect from '../../components/Select/PageSelect';
import { data } from 'jquery';
import { useHistory } from 'react-router-dom';
const Pages = () => {
  const [lowercasedFilter, setFilter] = useState('');
  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [param, setParams] = useState();
  const [lng, setLng] = useState('hr-HR');
  let history = useHistory();
  const goTo = (value) => {
    history.push(`/stranice/${value}`);
  };
  const dohvati = async () => {
    const get = await getPages(lng);
    setPage(get);
    setLoading(false);
  };
  useEffect(() => {
    dohvati();
  }, [lng]);
  const optionsLangugage = [
    {
      name: 'Engleski',
      value: 'en-GB',
    },
    {
      name: 'Hrvatski',
      value: 'hr-HR',
    },
  ];
  const selectLangugage = (value) => {
    setLng(value);
  };

  if (loading) {
    return (
      <div className='container d-flex'>
        <div className='loader-div m-auto'>
          <div className='m-auto items-center justify-center '>
            <div
              style={{ borderTopColor: 'transparent' }}
              className='w-16 h-16 border-4 border-gray-500 border-solid rounded-full animate-spin'></div>
          </div>
        </div>
      </div>
    );
  }
  const filteredData = page.filter((item) => {
    return Object.keys(item).some(
      (key) =>
        typeof item[key] === 'string' &&
        item.title.toLowerCase().includes(lowercasedFilter.toLowerCase())
    );
  });
  return (
    <section className='posts-list container-lg px-10 '>
      <h1>Uredi stranice-kategorije</h1>
      <div className='d-flex '>
        <input
          className='py-2 px-3 bg-white  mr-2 responsive-width '
          style={{ border: '0px' }}
          onChange={(e) => setFilter(e.target.value)}
          placeholder={'Pretraži...'}
        />

        <SelectSearch
          options={optionsLangugage}
          value={lng}
          name='language'
          onChange={selectLangugage}
          placeholder='Odaberi jezik'
        />
      </div>

      <div className='text-blue-900 px-1  font-bold mt-1'>
        Broj pronađenih stranica: {page.length}
      </div>
      <div className='container-lg '>
        <div className='d-flex mt-3 mb-3 bg-white text-black p-1 px-2'>
          <span className=' mt-auto mb-auto font-bold'>Naziv stranice</span>
          <span className='ml-auto p-1 rounded-md mt-auto mb-auto font-bold '>
            Id stranice
          </span>
          <span className='p-2 cursor-pointer font-bold rounded-md ml-2 mt-auto mb-auto  '>
            Akcija
          </span>
        </div>
        {filteredData.reverse().map((item, index) => (
          <div
            onClick={() => goTo(item.id)}
            className={
              index % 2
                ? 'px-3  border-b border-t bg-gray-50 page-item cursor-pointer py-1'
                : 'px-3   border- cursor-pointer  page-item py-1'
            }>
            <div className='d-flex'>
              <p className=' mt-auto mb-auto text-black'>{item.title}</p>
              <span className='ml-auto p-1 px-3  rounded-md mt-auto mb-auto  text-black'>
                {item.id}
              </span>
              <p
                onClick={() => goTo(item.id)}
                className='p-2 cursor-pointer bg-blue-900 text-xl  ml-2 mt-auto mb-auto text-white '>
                Uredi
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Pages;
