import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';

const FolderListPage = () => {
  const { folder } = useParams();
  const [lowercasedFilter, setFilter] = useState('');
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderContents, setFolderContents] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [subfolders, setSubfolders] = useState([]);
  const [sub, setSub] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [imageLoading, setLoadingImage] = useState(false);
  const inputFileRef = useRef(null);
  // alert
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 6000);
  };
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUploadFiles = () => {
    setLoadingData(true);
    showAlert('warning', 'Uploading files...');

    if (selectedFiles.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files[]', selectedFiles[i]);
      }

      formData.append('folder', folder + '/');
      formData.append('subfolder', sub ? sub : '');
      axios
        .post(`https://dev.hoo.hr/upload-script.php`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (sub == null) {
            return fetchFolders();
          } else {
            return fetchFoldersWithSubfolder(sub);
          }
        })
        .then(() => {
          setLoadingImage(true);
          setLoadingData(true);
        })
        .catch((error) => {
          showAlert('error', 'Error');
          console.error('Error:', error);
        })

        .finally(() => {
          showAlert('success', 'Successfully uploaded');
          fetchFolders();
          setLoadingImage(false);
          setLoadingData(false);
          setSelectedFiles([]);
          inputFileRef.current.value = '';
        });
    }
  };
  const fetchFolders = async () => {
    if (folder) {
      setLoadingData(true);

      try {
        const response = await axios.get(
          `${api}/api/content/get-media?folder=${folder + '/'}`
        );

        if (response.data) {
          setLoadingData(false);
          setFolders(response.data);
        } else {
          setFolders([]);
          setLoadingData(false);
        }

        console.log(response.data);
      } catch (error) {
        setLoadingData(false);
        console.error('An error occurred while fetching folders:', error);
        setFolders([]);
      }
    }
  };

  useEffect(() => {
    if (sub === null) {
      fetchFolders();
    } else {
      fetchFoldersWithSubfolder(sub);
    }
  }, [sub, imageLoading]);

  const fetchFoldersWithSubfolder = async (subfolder) => {
    setLoadingData(true);

    try {
      const response = await axios.get(
        `${api}/api/content/get-media?subfolder=${encodeURIComponent(
          subfolder
        )}&folder=${encodeURIComponent(folder + '/')}`
      );

      setLoadingData(false);

      if (response.data) {
        setFolders(response.data);
      } else {
        setFolders([]);
      }
    } catch (error) {
      console.error('An error occurred while fetching folders:', error);
      setLoadingData(false);
      setFolders([]);
    }
  };

  const handleCreateFolder = () => {
    if (newFolderName) {
      showAlert('warning', 'Creating folder...');

      const data = new FormData();
      data.append('folder', folder + '/');
      data.append('subfolder', sub ? sub : '');
      data.append('folderName', newFolderName);

      fetch('https://dev.hoo.hr/create-folder-script.php', {
        method: 'POST',
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.error);
          } else {
            setLoadingImage(true);
            setLoadingData(true);
            showAlert('success', 'Folder created!');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setLoadingImage(false);
          setLoadingData(false);
        });
    }
  };
  const handleNavigateBack = () => {
    if (subfolders.length > 0) {
      const lastSubfolder = subfolders[subfolders.length - 1];
      setSubfolders(subfolders.slice(0, -1));
      setSub(lastSubfolder);
    } else {
      setSub(null);
    }
  };

  const handleSubfolderClick = (subfolderPath) => {
    const newSubfolders = [...subfolders, subfolderPath];
    setSubfolders(newSubfolders);
    setSub(subfolderPath);
  };

  const fo = folder;

  const filteredData = Object.values(folders).filter((item) => {
    return Object.keys(item).some(
      (key) =>
        typeof item[key] === 'string' &&
        item?.name?.toLowerCase().includes(lowercasedFilter.toLowerCase())
    );
  });

  return (
    <div className='bg-gray-50 p-3'>
      <h3>Odaberi folder</h3>
      <div className='flex flex-wrap bg-white space-x-1 py-3 px-3'>
        <a
          href='/media-manager/clanci-slike'
          className=' p-1 px-2 text-black bg-gray-200 rounded-lg  '>
          <section>Slike</section>
        </a>
        <a
          href='/media-manager/dokumenti'
          className=' p-1 px-2 text-black bg-gray-200 rounded-lg  '>
          <section>Dokumenti</section>
        </a>
        <a
          href='/media-manager/images'
          className=' p-1 px-2 text-black bg-gray-200 rounded-lg  '>
          <section>Stari folder (images)</section>
        </a>
        <a
          href='/media-manager/galerije'
          className=' p-1 px-2 text-black bg-gray-200 rounded-lg  '>
          <section>Galerije</section>
        </a>
        <a className='border ml-auto p-1 px-2 text-black bg-gray-200 rounded-lg  '>
          Putanja:{' '}
          <span className='text-green-500 '>
            {folder}/{sub}
          </span>
        </a>
      </div>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <div className='container-lg p-0'>
        <div className='d-flex items-center my-3 space-x-2 justify-between'>
          <div className='d-flex items-center   my-3 space-x-1'>
            <input
              name='folder'
              type='text'
              className='px-3 py-1 rounded-md bg-gray-200'
              placeholder='Npr. "novi-folder"'
              onChange={(e) => setNewFolderName(e.target.value)}
            />
            <button
              className='px-2 btn bg-gray-900 text-white'
              onClick={handleCreateFolder}>
              Dodaj folder
            </button>
          </div>
          <div className='d-flex'>
            <input
              onChange={(e) => setFilter(e.target.value)}
              placeholder='Pretraži'
            />
          </div>
        </div>
        <p className='text-right'>Upload</p>
        <div className='flex '>
          <div className='flex ml-auto'>
            <input
              type='file'
              className='bg-gray-300 sm:w-1/3 text-black text-xs'
              multiple
              onChange={handleFileChange}
              ref={inputFileRef}
            />
            <button
              className='my-2 ml-2 px-2 py-1 bg-green-300 text-white rounded-md'
              onClick={handleUploadFiles}>
              Upload
            </button>
          </div>
        </div>
        <hr />
        {loadingData ? (
          <div className='h-96 d-flex'>
            <h1 className='m-auto text-xl'>Loading...</h1>
          </div>
        ) : (
          <div className='flex flex-col sm:flex-row'>
            <div className='w-25 h-auto px-3 py-2 rounded-md'>
              {sub && (
                <button
                  className='my-2 px-2 py-1 bg-gray-900 text-white rounded-md'
                  onClick={handleNavigateBack}>
                  <i class='bi bi-arrow-left'></i> Nazad
                </button>
              )}
              <h4>Folderi</h4>
              <div className='overflow-y-auto max-h-100'>
                <ul className='list-group'>
                  {filteredData.some((i) => i.type == 'folder') ? (
                    folders?.map((folder, index) => {
                      if (folder.type !== 'folder') return null;

                      return (
                        <li
                          className=' p-0 my-1 bg-gray-100 mx-1 rounded-md'
                          key={index}>
                          <button
                            className='mt-1 p-1 text-left'
                            disabled={folder.type !== 'folder'}
                            onClick={() =>
                              handleSubfolderClick(
                                folder.path.replace(fo + '/', '')
                              )
                            }>
                            <div className='flex items-center space-x-2'>
                              <i class='bi bi-folder-fill text-warning'></i>

                              <small
                                style={{ maxWidth: '200px' }}
                                className='text-truncate'>
                                {folder.name}
                              </small>
                            </div>
                          </button>
                        </li>
                      );
                    })
                  ) : (
                    <p>No folders add one</p>
                  )}
                </ul>
              </div>
            </div>
            <div className='overflow-y-auto w-75  '>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Slika</th>
                      <th>Ime</th>
                      <th>Vrijeme</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.map((folder, index) => {
                      if (folder.type === 'folder') return null;

                      return (
                        <tr key={index} className='bg-white'>
                          <td>
                            {folder.type === 'image' ? (
                              <picture>
                                <img
                                  loading='lazy'
                                  style={{
                                    height: '50px',
                                    width: '50px',
                                    objectFit: 'cover',
                                  }}
                                  src={'https://dev.hoo.hr/' + folder.path}
                                  alt={folder.name}
                                />
                              </picture>
                            ) : (
                              <i class='bi bi-file-earmark text-yellow-400 text-2xl'></i>
                            )}
                          </td>
                          <td
                            className='text-truncate text-xs'
                            style={{ maxWidth: '250px' }}>
                            <a
                              href={'https://dev.hoo.hr/' + folder.path}
                              target='_blank'
                              rel='noopener noreferrer'>
                              {folder.name}{' '}
                            </a>
                          </td>

                          <td>
                            {moment(folder.time).format('DD.MM.YYYY - hh:mm')}
                          </td>

                          <td>
                            <button
                              className='bg-gray-100 text-gray-800 px-2 text-xs py-1 rounded-lg'
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(
                                    'https://dev.hoo.hr/' + folder.path
                                  )
                                  .then((e) => alert('kopirano'));
                              }}>
                              Kopiraj url
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FolderListPage;
