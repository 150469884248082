import axios from 'axios';
import { useEffect, useState } from 'react';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import Modal from '../../components/Modal/Modal';
import CategoriesSelect from '../../components/Select/CategoriesSelect';

const Navigation = () => {
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const [nav, setNav] = useState([]);
  const [loading, setLoading] = useState(true);
  const [is_open, setOpen] = useState(false);
  const [selected_id, setId] = useState();
  const [param, setParams] = useState('');
  const [inputs, setValues] = useState();
  const [title, setTitle] = useState();
  const [link, setLink] = useState();
  const [navItem, setNavItem] = useState();
  const [newItemId, setNewNavItem] = useState();
  const [newTitle, setNewTitle] = useState();
  const [newLink, setNewLink] = useState();
  const getNav = async () => {
    try {
      const response = await fetch(
        api + '/api/content/get/navigation-all'
      ).then((response) => response.json());

      setNav(response);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const addNewLink = async (e) => {
    e.preventDefault();
    try {
      const data = [[newTitle, newLink, newItemId]];
      const post = await axios.post(
        api + '/api/content/get/post-navigation-items',
        data,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );

      showAlert('success', post.data.message);
      document.getElementById('nav-form').reset();
      return [post, getNav()];
    } catch (e) {
      showAlert('danger', e.message);
    }
  };
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const choice = (id, val) => {
    setId(id);
    setOpen((is_open) => !is_open);
    setValues(val);
    setTitle(val?.value);
    setLink(val?.link);
    setNavItem(val?.nav);
  };
  const deleteNavItem = async (id) => {
    try {
      const post = await axios.delete(
        api + '/api/content/get/delete-navigation-items/' + id,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      showAlert('success', post.data.message);
      setOpen(false);
      return [post, getNav()];
    } catch (e) {
      showAlert('danger', e.message);
    }
  };
  const updateNav = async (id) => {
    try {
      const params = {
        value: title,
        link: link,
        nav: navItem,
        id: id,
      };
      const post = await axios.put(
        api + '/api/content/get/update-navigation-items',
        params,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      showAlert('success', post.data.message);
      return [post, getNav()];
    } catch (e) {
      showAlert('danger', e.message);
    }
  };
  useEffect(() => {
    getNav();
  }, []);
  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  const navigation_data = nav.map((item, index) => ({
    id: item.id,
    value: item.value,
    sub: item.sub,
    doc: item.doc,
    link: item.link,
    search: item.search,
    kont: item.kont,
    items: item.items ? JSON.parse(item.items) : [],
  }));
  return (
    <section className='posts-list p-3 '>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <div className='w-50  rounded-sm  p-3 mb-3'>
        <h4>Dodaj novi link</h4>
        <form id='nav-form' onSubmit={addNewLink}>
          <input
            className='bg-gray-200'
            onChange={(e) => setNewTitle(e.target.value)}
            placeholder='Naziv'
          />
          <input
            className='bg-gray-200'
            placeholder='Link-  "/page/640/1'
            onChange={(e) => setNewLink(e.target.value)}
          />
          <select
            className='bg-gray-200'
            onChange={(e) => setNewNavItem(e.target.value)}>
            <option value={''}>Odaberi sekciju</option>
            {nav.map((item, index) => (
              <option key={index} value={item.id}>
                {item.value}
              </option>
            ))}
          </select>
          <button className='bg-blue-900 p-1 text-white px-3  my-2'>
            Objavi
          </button>
        </form>
      </div>
      <div className='row '>
        {navigation_data.map((item, index) =>
          item.value === 'Kontakti' ||
          item.value === 'Dokumenti' ||
          item.value === 'Pretraži' ? null : (
            <div className='col-md-4'>
              <p className=' my-1  px-2'>
                <span className='bg-blue-900 text-white rounded-sm  px-2 py-1'>
                  {item.value}
                </span>
              </p>
              {item.sub ? (
                <ul className='py-1'>
                  {item.items.map((val, index) => (
                    <li className='py-1 text-xs rounded-md  text-black px-3 my-1 bg-gray-100'>
                      <div className='d-flex'>
                        <small className=' mt-auto mb-auto'>{val.value}</small>
                        <small
                          className='ml-auto mt-auto text-green-600 bg-light p-1 rounded-md cursor-pointer mb-auto'
                          onClick={() => choice(val.id, val)}>
                          <i className='bi bi-pencil'></i>
                        </small>
                      </div>

                      {is_open && selected_id === val.id ? (
                        <div>
                          {' '}
                          <div
                            style={{
                              margin: 'auto',
                              position: 'fixed',
                              top: '0',
                              left: '0',
                              bottom: '0',
                              background: 'rgba(0,0,0,0.5)',
                              width: '40%',
                            }}
                          />
                          <section
                            className='bg-white rounded-md  py-1 px-3'
                            style={{
                              margin: 'auto',
                              position: 'fixed',
                              top: '0%',
                              right: '0px',
                              bottom: '0%',

                              width: '60%',
                              zIndex: 200,
                            }}>
                            <div className='d-flex'>
                              <button
                                type='button'
                                onClick={() => deleteNavItem(inputs.id)}
                                className='ml-auto btn text-white ml-auto my-1 px-3 bg-red-500 p-1 text-xs'>
                                <small>Ukloni</small>
                              </button>
                            </div>
                            <p className='font-bold'>{val.value}</p>
                            <b>ID: {inputs.id}</b>
                            <br />
                            <small>Naziv</small>

                            <input
                              defaultValue={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />

                            <small>Link</small>
                            <input
                              defaultValue={link}
                              onChange={(e) => setLink(e.target.value)}
                            />
                            <hr />
                            <small>Naziv eng.</small>
                            <input />
                            <small>Link eng.</small>
                            <input />
                            <select
                              defaultValue={navItem}
                              onChange={(e) => setNavItem(e.target.value)}>
                              <option value={''}>Odaberi sekciju</option>
                              {nav.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.value}
                                </option>
                              ))}
                            </select>
                            <div className='d-flex'>
                              <button
                                onClick={() => updateNav(inputs.id)}
                                className='btn text-white  my-1 ml-2 px-3 bg-green-500 p-1 text-xs'>
                                <small>Ažuriraj </small>
                              </button>

                              <button
                                onClick={() => choice(null, null)}
                                className='btn text-white  my-1 ml-2 px-3 bg-blue-700 p-1 text-xs'>
                                <small>Zatvori</small>
                              </button>
                            </div>
                          </section>
                        </div>
                      ) : null}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default Navigation;
