import { useEffect, useState } from 'react';

import Papa from 'papaparse';
import api from '../../components/Api/api';
import axios from 'axios';
import { data } from 'jquery';
import Alert from '../../components/Alert/Alert';

const UploadKategorizacije = (props) => {
  const [loading, setLoader] = useState(false);
  const [statuss, setStatus] = useState(false);
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const showData = (value) => {
    const data = value.map((item) => [
      item[0],
      item[1],
      item[2],
      item[3],
      item[4],
      item[5],
      item[6],
      item[7],
      item[8],
      item[9],
      item[10],
      item[11],
      item[12],
    ]);
    showAlert('danger', 'Brišem stare podatke');

    axios
      .get(api + '/api/kategorizacija/delete', {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log(res.status);

        showAlert('success', 'Podaci izbrisani!');
      })
      .catch((e) => {
        console.log(e);
      });
    let newArr = Object.keys(data).length;
    showAlert('danger', 'Pričekajte');

    upload(data);
  };
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const upload = (data) => {
    axios
      .post(api + '/api/kategorizacija/upload-csv', data, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        showAlert('success', 'Podaci uspješno dodani');
        console.log(res.data);
        setTimeout(() => {
          props.history.push('/kategorizacije');
        }, 2500);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <section className='posts-list '>
      <h1>Dodaj csv dokument</h1>
      <small className='text-danger text-xl'>
        Prilikom dodavanja novog dokumenta, stari podaci će se automatski
        izbrisati.
      </small>
      <label>
        Enter Your CSV
        <input
          type='file'
          accept='.csv,.xlsx,.xls'
          onChange={(e) => {
            const files = e.target.files;

            if (files) {
              Papa.parse(files[0], {
                encoding: 'windows-1250',

                error: function (err, file, inputElem, reason) {
                  showAlert('danger', 'Greška!');
                },
                complete: function (results) {
                  showData(results.data);
                },
              });
            }
          }}
        />
      </label>

      <Alert show={AlertShow} type={alertType} message={alertMessage} />
    </section>
  );
};

export default UploadKategorizacije;
