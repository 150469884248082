import Axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getMedalje } from '../Profile/GetComments';
import api from '../../components/Api/api';

const Medalje = () => {
  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(true);

  const dohvati = async () => {
    const get = await getMedalje();
    setPage(get);
    setLoading(false);
  };
  useEffect(() => {
    dohvati();
  }, []);

  const remove = (id) => {
    Axios.delete(`${api}/api/content/remove/medalje/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        console.log('deleted');
        dohvati();
      })
      .catch((e) => console.log(e));
  };
  const handleDeleteConfirmation = (itemId) => {
    const isConfirmed = window.confirm('Jeste li sigurni da želite izbrisati?');

    if (isConfirmed) {
      remove(itemId);
      // Dodajte dodatnu logiku ili pozive funkcija ako je potrebno
    }
  };

  if (loading) {
    return (
      <div className='d-flex ' style={{ height: '90vh' }}>
        <p className='m-auto'>Loading posts...</p>
      </div>
    );
  }
  return (
    <section className='posts-list '>
      <div className='container'>
        <div className='row'>
          <h1 className='m-1'>Sve medalje</h1>
          <Link className='ml-auto' to='/dodaj/medalje'>
            <button className='btn bg-black text-white m-1 '>
              Dodaj novu medalju
            </button>
          </Link>{' '}
        </div>
      </div>
      <div className='container'>
        <ul className=' ml-0 mt-5'>
          {page == null
            ? null
            : page.map((item, index) => (
                <li
                  className='d-flex px-2 py-0 rounded-md  bg-gray-100  my-2'
                  key={index}>
                  <div className='p-2'>
                    {!item.tim ? (
                      <h6 className='mr-1'>
                        {' '}
                        {JSON.parse(item.ime_prezime).map((sportas, va) => (
                          <span className='mr-1  '>
                            {sportas.name}
                            {va !== JSON.parse(item.ime_prezime).length - 1 &&
                              ','}
                          </span>
                        ))}
                      </h6>
                    ) : (
                      <div>
                        <h6>{item.timVal}</h6>
                        <div className='mr-1 w-1/2'>
                          {' '}
                          {JSON.parse(item.ime_prezime).map((sportas, va) => (
                            <span style={{ fontSize: '9px' }} className='mr-1'>
                              {sportas.name}
                              {va !== JSON.parse(item.ime_prezime).length - 1 &&
                                ','}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    <small>{item.sport}</small>
                    <small className='pl-1'>, {item.natjecanje}</small>
                    <small className='pl-1'>
                      , Osvojeno {item.medalja}. mjesto
                    </small>
                  </div>

                  <div className=' ml-auto flex space-x-2 mt-auto mb-auto'>
                    <a href={'/uredi-medalju/' + item.id}>
                      <button className='px-2 py-1 text-sm bg-blue-900 text-white'>
                        Uredi
                      </button>
                    </a>
                    <button
                      onClick={() => handleDeleteConfirmation(item.id)}
                      className='px-2 py-1 bg-red-500 text-sm text-white rounded-sm'>
                      Izbriši
                    </button>
                  </div>
                </li>
              ))}
        </ul>
      </div>
    </section>
  );
};

export default Medalje;
