import React, { Component } from "react";
import axios from 'axios';
import jwt from 'jwt-decode'
import { Link } from "react-router-dom";
class Verification extends Component {
    constructor (props) {
        super(props);
        this.state = {
            lists: [],
            loading: true, fetch_user_id: null
        };
    }
    componentDidMount() {
        // get listing

        const objectMatch = this.props.match.params.id
        axios.get(`https://meet-cro-test.herokuapp.com/verify/${objectMatch}`).then((response) => {
            console.log(response.data[0].userId)
            const data = response.data
            this.setState({
                fetch_user_id: response.data[0].userId,
                accountVerify: response.data[0].verified,
                lists: data, loading: false
            })
        }).catch((err) => {
            console.log(err)
        })

        this.isVerifyed()


    }
    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message },
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000);
    }
    update() {

        if (this.state.fetch_user_id) {

            const data = {
                verified: true,
                userId: this.state.fetch_user_id * 1

            };
            axios.put(`https://meet-cro-test.herokuapp.com/confirmation`, data).then((res) => {
                console.log(res)
                this.showAlert('success', `${res.data.message}`)
                setTimeout(() => {
                    window.location.href = "/"
                })
            }).catch((err) => {
                this.showAlert('danger', `${err.message}`)
            })

        }
        else {
            this.showAlert('danger', 'Account does not exist!')
            window.location.href = "/"
        }


    }
    isVerifyed() {
        const { accountVerify } = this.state
        if (accountVerify) {
            window.location.href = "/"
        }
        else {

        }

    }
    render() {

        return (
            <div style={ { height: '96vh', width: '100%' } } className="w-100 p-0 m-0 d-flex  bg-grey-500">
                { this.state.alert && <div className={ `alert alert-${this.state.alertData.type} my-alert ` } role='alert'>
                    <div className='container'>
                        { this.state.alertData.message }
                    </div>
                </div> }
                { this.isVerifyed() }
                <div className="m-auto">
                    <h3>Please verify Your email here</h3>
                    <p>After verifycation you will be able to login.</p>
                    <button className="my-button-sucess" onClick={ () => this.update() } >Verify account</button>
                </div>

            </div>
        );
    }
}

export default Verification;