export const natjecanja = [
  { value: '', name: 'Select' },
  { value: '559', name: 'OI Tokio' },
  { value: '317', name: 'OI London 2012' },
  { value: '315', name: 'OI Peking 2008' },
  { value: '311', name: 'OI Sydney 2000' },
  { value: '310', name: 'OI Atlanta 1996' },
  { value: '309', name: 'OI Barcelona 1992' },
  { value: '625', name: 'ZOI Peking 2022' },
  { value: '487', name: 'ZOI Pjonngčang 2018' },
  { value: '318', name: 'ZOI Soči 2014' },
  { value: '316', name: 'ZOI Vancouver 2010' },
  { value: '402', name: 'Salt Lake City 2002' },
  { value: '401', name: 'Lillehammer 1994' },
  { value: '400', name: 'Albertville 1992' },
  { value: '593', name: 'EI Minsk 2019' },
  { value: '343', name: 'EI Baku 2015' },
  { value: '602', name: 'SINP Doha 2019' },
  { value: '629', name: 'MI Oran 2022' },
  { value: '304', name: 'MI Tarragona 2018' },
  { value: '332', name: 'MI Mersin 2013' },
  { value: '331', name: 'MI Pescara 209' },
  { value: '403', name: 'MI Almeria 2005' },
  { value: '404', name: 'MI Tunis 2001' },
  { value: '405', name: 'MI Bari 1997' },
  { value: '406', name: 'MI Lannguedoc Roossilion 1993' },
  { value: '599', name: 'MI Na pijesku Patras 2019' },
  { value: '513', name: 'MI Na pijesku Pescara 2015' },
];

export const natjecanjaEng = [
  { name: 'Olympic Winter Games Beijing 2022', value: '626' },
  { name: 'PyeongChang 2018 2018', value: '491' },
  { name: 'Sochi 2014', value: '329' },
  { name: 'Vancouver 2010', value: '327' },
  { name: 'Salt lake City 2002', value: '323' },
  { name: 'Nagano 1998', value: '492' },
  { name: 'Lillehammer 1994', value: '493' },
  { name: 'Albertville 1992', value: '494' },
  { name: 'Tokyo 2020', value: '567' },
  { name: 'Rio 2016', value: '564' },
  { name: 'London 2012', value: '328' },
  { name: 'Beijing 2008 ', value: '326' },
  { name: 'Sydney 2000', value: '322' },
  { name: 'Atlanta 1996', value: '321' },
  { name: 'Barcelona 1992', value: '320' },
  { name: 'European Games Minsk 2019', value: '591' },
  { name: 'European Games Baku 2015', value: '346' },
  { name: 'World Beach Games Doha 2019', value: '606' },
  { name: 'MG Oran 2022', value: '630' },
  { name: 'MG Tarragona 2018', value: '308' },
  { name: 'MG Mersin 2013', value: '332' },
  { name: 'MG Pescara 2009', value: '336' },
  { name: 'MG Almeria 2005', value: '495' },
  { name: 'MG Tunis 2001', value: '496' },
  { name: 'MG Bari 1997', value: '497' },
  { name: 'MG Languedoc Roussilion 1993', value: '498' },
  { name: 'MBG Pescara 2015', value: '515' },
  { name: 'Buenos Aires 2018', value: '572' },
  { name: 'Nanjing 2014 YOG', value: '340' },
  { name: 'Singapore 2010 YOG', value: '338' },
  { name: 'Lausanne 2020', value: '607' },
  { name: 'ZOIM Innsbruck 2012', value: '339' },
  { name: 'EYOF Banska Bystrica 2022', value: '632' },
  { name: 'EYOF Tbilisi 2015', value: '499' },
  { name: 'EYOF Utrecht 2013', value: '345' },
  { name: 'EYOF Trabzon 2011', value: '344' },
  { name: 'EYOF Tampere 2009', value: '500' },
  { name: 'EYOF Beograd 2007', value: '501' },
  { name: 'EYOF Lignano 2005', value: '502' },
  { name: 'EYOF Paris 2003', value: '503' },
  { name: 'EYOWF Vuokatti 2022', value: '631' },
  { name: 'EYOWF Vorarlberg - Lihtenštajn 2015', value: '504' },
  { name: 'EYOWF Brasov 2013', value: '505' },
  { name: 'EYOWF Liberec 2011', value: '506' },
  { name: 'EYOWF Slask - Beskidy 2009 ', value: '507' },
  { name: 'EYOWF Jaca 2017', value: '508' },
  { name: 'EYOWF Monthey 2005', value: '509' },
  { name: 'EYOWF Bled 2003', value: '510' },
];
export const months = [
  { value: '01', label: '1' },
  { value: '02', label: '2' },
  { value: '03', label: '3' },
  { value: '04', label: '4' },
  { value: '05', label: '5' },
  { value: '06', label: '6' },
  { value: '07', label: '7' },
  { value: '08', label: '8' },
  { value: '09', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];
export const godina = [
  { value: '1999', label: '1999' },
  { value: '2000', label: '2000' },
  { value: '2001', label: '2001' },
  { value: '2002', label: '2002' },
  { value: '2003', label: '2003' },
  { value: '2004', label: '2004' },
  { value: '2005', label: '2005' },
  { value: '2006', label: '2006' },
  { value: '2007', label: '2007' },
  { value: '2008', label: '2008' },
  { value: '2009', label: '2009' },
  { value: '2010', label: '2010' },
  { value: '2011', label: '2011' },
  { value: '2012', label: '2012' },
  { value: '2013', label: '2013' },
  { value: '2014', label: '2014' },
  { value: '2015', label: '2015' },
  { value: '2016', label: '2016' },
  { value: '2017', label: '2017' },
  { value: '2018', label: '2018' },
  { value: '2019', label: '2019' },
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
];
