import React, { Component } from 'react';
import jwt from 'jwt-decode';
import { Redirect } from 'react-router-dom';
import Login from './Auth/Login';
import { useEffect } from 'react';
class Not_found2 extends Component {
  render() {
    const token = localStorage.getItem('token');
    const userToken = token ? jwt(token) : '';

    return (
      <div className='bg-gray-50 h-screen p-2'>
        <div
          className='d-flex '
          style={{ height: '80vh', maxWidth: '800px', margin: '0 auto' }}>
          <div className='m-auto'>
            <h3 className='text-black'>
              Žao nam je, ali nismo mogli pronaći tu stranicu :(
            </h3>
            <br />
            <p className=''>
              Premjestili smo neke stvari i tvoja se stranica vjerojatno pritom
              izgubila. Pokušaj ponovno unijeti adresu ili se vrati na početnu
              stranicu.
            </p>
            <h6>
              <a className='text-black' href='https://www.hoo.hr'>
                Vrati se na HOO
              </a>
            </h6>
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            right: '20px',
            zIndex: 20,
          }}>
          <small className='text-gray-200'>
            {' '}
            <a href='/admin'>Admin</a>
          </small>
        </div>
      </div>
    );
  }
}

export default Not_found2;
