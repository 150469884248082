import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Alert from '../../components/Alert/Alert';
import moment from 'moment';
import api from '../../components/Api/api';

const FolderList = ({
  onClose,
  listaSlika,
  setImage,
  nove,
  stare,
  multiple,
  folder,
  selected,
  allowSelect,
  text,
  clanci,
  clanciDoc,
}) => {
  const [lowercasedFilter, setFilter] = useState('');
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderContents, setFolderContents] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [subfolders, setSubfolders] = useState([]);
  const [sub, setSub] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [imageLoading, setLoadingImage] = useState(false);
  const inputFileRef = useRef(null);
  const [deleted, setDeleted] = useState(null);
  const [images, setImages] = useState(selected);
  // alert
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 6000);
  };

  const select = (item, id) => {
    console.log(item);
    if (images.some((i) => i.image === id)) {
      const updatedImages = images.filter((image) => image.image !== id);
      ref(updatedImages);
    } else {
      setImages((images) => [...images, item]);
      setIma(item);
    }
  };

  const ref = (val) => {
    setImages(val);
  };

  const setIma = (item) => {
    setImage([...images, item]);
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUploadFiles = () => {
    setLoadingData(true);
    showAlert('warning', 'Uploading files...');

    if (selectedFiles.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files[]', selectedFiles[i]);
      }

      formData.append('folder', folder + '/');
      formData.append('subfolder', sub ? sub : '');
      axios
        .post(`https://dev.hoo.hr/upload-script.php`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (sub == null) {
            return fetchFolders();
          } else {
            return fetchFoldersWithSubfolder(sub);
          }
        })
        .then(() => {
          setLoadingImage(true);
          setLoadingData(true);
        })
        .catch((error) => {
          showAlert('error', 'Error');
          console.error('Error:', error);
        })

        .finally(() => {
          showAlert('success', 'Successfully uploaded');
          fetchFolders();
          setLoadingImage(false);
          setLoadingData(false);
          setSelectedFiles([]);
          inputFileRef.current.value = '';
        });
    }
  };

  const fetchFolders = async () => {
    setLoadingData(true);
    try {
      const response = await axios.get(
        `${api}/api/content/get-media?folder=${folder + '/'}`
      );
      if (response.data) {
        setFolders(response.data);
        setLoadingData(false);
      } else {
        setFolders([]);
        setLoadingData(false);
      }
    } catch (error) {
      setLoadingData(false);
      console.error('Došlo je do greške prilikom dohvaćanja foldera:', error);
    }
  };

  useEffect(() => {
    if (sub === null) {
      fetchFolders();
    } else {
      fetchFoldersWithSubfolder(sub);
    }
  }, [sub, imageLoading]);

  const fetchFoldersWithSubfolder = async (subfolder) => {
    setLoadingData(true);
    try {
      const encodedFolder = folder + '/';
      const encodedSubfolder = subfolder;

      const url = `${api}/api/content/get-media?folder=${encodedFolder}&subfolder=${encodedSubfolder}`;
      const response = await axios.get(url);
      setLoadingData(false);
      if (response.data) {
        setFolders(response.data);
      } else {
        setFolders([]);
      }
    } catch (error) {
      console.error('Došlo je do greške prilikom dohvaćanja foldera:', error);
      setLoadingData(false);
    }
  };

  const handleCreateFolder = () => {
    if (newFolderName) {
      showAlert('warning', 'Creating folder...');

      const data = new FormData();
      data.append('folder', folder + '/');
      data.append('subfolder', sub ? sub : '');
      data.append('folderName', newFolderName);

      fetch('https://dev.hoo.hr/create-folder-script.php', {
        method: 'POST',
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.error);
          } else {
            setLoadingImage(true);
            setLoadingData(true);
            showAlert('success', 'Folder created!');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setLoadingImage(false);
          setLoadingData(false);
        });
    }
  };

  const handleNavigateBack = () => {
    if (subfolders.length > 0) {
      const lastSubfolder = subfolders[subfolders.length - 1];
      setSubfolders(subfolders.slice(0, -1));
      setSub(lastSubfolder);
    } else {
      setSub(null);
    }
  };

  const handleSubfolderClick = (subfolderPath) => {
    const newSubfolders = [...subfolders, subfolderPath];
    setSubfolders(newSubfolders);
    setSub(subfolderPath);
  };

  const filteredData = Object.values(folders).filter((item) => {
    return Object.keys(item).some(
      (key) =>
        typeof item[key] === 'string' &&
        item?.name?.toLowerCase().includes(lowercasedFilter.toLowerCase())
    );
  });

  const fo = folder;
  const renderTable = () => {
    if (filteredData.length < 1) return <td>No data to show</td>;
    return filteredData?.map((folder, index) =>
      folder.type === 'folder' ? null : (
        <tr key={index}>
          {folder.type != 'image' ? (
            <i class='bi bi-file-earmark text-2xl'></i>
          ) : (
            <img
              className='m-2'
              src={`https://dev.hoo.hr/${folder.path}`}
              alt={folder.name}
              style={{
                width: '40px',
                height: '40px',
                objectFit: 'cover',
              }}
            />
          )}
          <td className='text-truncate text-xs' style={{ maxWidth: '250px' }}>
            {folder.name}
          </td>
          <td className='text-xs'>
            {moment(folder.time).format('DD.MM.YYYY : mm:hh')}
          </td>
          <td>
            {folder.type === 'image' ? (
              <button
                onClick={() => {
                  select(
                    {
                      image: folder.path,
                      id: index,
                      caption: '',
                    },
                    folder.path
                  );
                }}
                className={`px-2 text-xs py-1 rounded-lg ${
                  images.some((i) => i.image === folder.path)
                    ? 'bg-green-400'
                    : 'bg-indigo-400 text-white'
                }`}>
                {images.some((i) => i.image === folder.path)
                  ? 'Odabran'
                  : 'Odaberi'}
              </button>
            ) : (
              <button
                onClick={() => {
                  allowSelect
                    ? select(folder.path)
                    : navigator.clipboard
                        .writeText('https://dev.hoo.hr/' + folder.path)
                        .then((e) => alert('kopirano'));
                }}
                className='bg-gray-100 text-gray-800 px-2 text-xs py-1 rounded-lg'>
                {allowSelect ? 'Odaberi' : 'Kopiraj URL'}
              </button>
            )}
          </td>
        </tr>
      )
    );
  };

  return (
    <div className='modal-bg'>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <div className=' image-modal '>
        <div className='pt-1 px-3 relative '>
          <button
            onClick={() => onClose()}
            type='button'
            className='bg-black px-4 shadow-sm p-2  text-white image-modal-button'
            data-toggle='modal'
            data-target='#exampleModal'>
            Zatvori
          </button>

          <div>
            <div className='container-lg p-0'>
              <h1>Media</h1>
              Trenutni folder: {sub}
              <div className='d-flex items-center mb-3 space-x-2 justify-between'>
                <div className='d-flex items-center my-3 space-x-1'>
                  <input
                    name='folder'
                    type='text'
                    className='px-3 py-1 rounded-md w-full  bg-gray-200'
                    placeholder='Unesi ime foldera  (npr. novi-folder )'
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />
                  <button
                    type='button'
                    className='my-2 ml-2 px-2 py-1 bg-green-400 text-white rounded-md'
                    onClick={handleCreateFolder}>
                    Dodaj
                  </button>
                </div>
                <div className='d-flex'>
                  <input
                    type='file'
                    multiple
                    className='bg-gray-300 text-black text-xs'
                    onChange={handleFileChange}
                    ref={inputFileRef}
                  />
                  <button
                    type='button'
                    className='my-2 ml-2 px-2 py-1 bg-green-400 text-white rounded-md'
                    onClick={handleUploadFiles}>
                    Upload
                  </button>
                </div>
              </div>
              <hr />
              {loadingData ? (
                <div className='h-96 d-flex'>
                  <h1 className='m-auto text-xl'>Loading...</h1>
                </div>
              ) : (
                <div className='flex flex-row '>
                  <div
                    className='w-25 px-3 py-2 rounded-md  '
                    style={{
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                      height: '400px',
                    }}>
                    {sub && (
                      <button
                        type='button'
                        className='my-2 px-2 py-1 bg-indigo-300 rounded-md'
                        onClick={handleNavigateBack}>
                        <i class='bi bi-arrow-left'></i> Nazad
                      </button>
                    )}
                    <h4 className=''>Folderi</h4>
                    <ul>
                      {filteredData.some((i) => i.type == 'folder') ? (
                        folders?.map((folder, index) =>
                          folder.type !== 'folder' ? null : (
                            <div className=''>
                              <li className=' p-0 my-1 bg-gray-100 mx-1 rounded-md'>
                                <button
                                  type='button'
                                  className='mt-1 p-1 text-left '
                                  disabled={folder.type !== 'folder'}
                                  onClick={() =>
                                    handleSubfolderClick(
                                      folder.path.replace(fo + '/', '')
                                    )
                                  }>
                                  <div className='flex items-center'>
                                    <i className='bi bi-folder mr-1 text-black'></i>
                                    <small
                                      style={{ maxWidth: '200px' }}
                                      className='text-truncate'>
                                      {folder.name}
                                    </small>
                                  </div>
                                </button>
                              </li>
                            </div>
                          )
                        )
                      ) : (
                        <p>No folders add one</p>
                      )}
                    </ul>
                  </div>
                  <div
                    className='w-75 mx-auto '
                    style={{
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                      height: '430px',
                    }}>
                    <input
                      className='bg-gray-100'
                      onChange={(e) => setFilter(e.target.value)}
                      placeholder='Pretraži'
                    />
                    <table className='table '>
                      <thead>
                        <tr>
                          <th scope='col'>Tip</th>
                          <th scope='col'>Naziv</th>

                          <th scope='col'>Vrijeme</th>
                          <th scope='col'>Akcije</th>
                        </tr>
                      </thead>
                      <tbody>{renderTable()}</tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FolderList.propTypes = {
  onClose: PropTypes.func,
  listaSlika: PropTypes.object,
  setImage: PropTypes.func,
  nove: PropTypes.func,
  stare: PropTypes.func,
  multiple: PropTypes.bool,
  selected: PropTypes.array,
  allowSelect: PropTypes.bool,
  text: PropTypes.string,
  clanci: PropTypes.func,
  clanciDoc: PropTypes.func,
};

export default FolderList;
