import React, { useMemo, useRef, useState } from 'react';

import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

export const Editor = () => {
  const [value, setValue] = useState();
  const quillRef = useRef(null);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [[{ header: [1, 2, 3, false] }], ['image']],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );
  const handleChange = (value) => {
    setValue(value);
  };

  function imageHandler() {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    const value = prompt('Please enter the image URL');
    alert(value);
    if (value && range) {
      editor.insertEmbed(range.index, 'image', value, 'user');
    }
  }

  return (
    <ReactQuill
      ref={quillRef}
      theme='snow'
      defaultValue={value}
      onChange={handleChange}
      modules={modules}
    />
  );
};
