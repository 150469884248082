import Axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import { godina, months } from '../../utils/constants';
import { getAutor } from '../Profile/GetComments';
import FolderListSingle from '../NovaMedia/MediaModalSingle';

const DodajOlimp = () => {
  //values
  const [broj, setBroj] = useState();
  const [godina_, setGodina] = useState();
  const [mjesec, setMjesec] = useState();
  const [graficka, setGraficka] = useState();
  const [tisak, setTisak] = useState();
  const [issue, setIssue] = useState();
  const [urednik, setUrednik] = useState();
  const [prijevod, setPrijevod] = useState();
  const [produkcija, setProdukcija] = useState();
  const [nakladnik, setNakladnik] = useState();
  const [naklada, setNaklada] = useState();
  const [dodatno, setDodatno] = useState();
  const [dodatnoEng, setDodatnoEng] = useState();
  const [opis, setOpis] = useState();
  const [modal, showModal] = useState(false);
  const [modalPdf, showModalPdf] = useState(false);

  //gt
  const [autori, setAutori] = useState([]);
  //upload
  const [pdfLoading, setLoadingPdf] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [imgLoading, setLoadingImage] = useState(false);
  const [img, setImg] = useState(null);
  //alert
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  //doc upload #########################
  const onChangePdf = (event) => {
    if (event.target.files[0] != null) {
      console.log(event.target.files[0]);
      uploadPdf(event.target.files[0]);
    } else {
    }
  };

  const uploadPdf = (img) => {
    setLoadingPdf(true);
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/upload-olimp-pdf.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);

          setPdf(data.image);
          setLoadingPdf(false);
          showAlert('success', data.message);
        });
      })
      .catch((e) => {
        showAlert('success', e.message);
      });
  };
  //image upload
  const onChangeImage = (event) => {
    if (event.target.files[0] != null) {
      console.log(event.target.files[0]);
      uploadImage(event.target.files[0]);
    } else {
    }
  };

  const uploadImage = (img) => {
    setLoadingImage(true);
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/olimp-image-upload.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);

          setImg(data.image);
          setLoadingImage(false);
          showAlert('success', data.message);
        });
      })
      .catch((e) => {
        showAlert('success', e.message);
      });
  };
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const send = async (e) => {
    e.preventDefault();
    const broj_olimpa = 0 + '' + broj * 1;
    console.log(broj_olimpa);
    let val = [
      [
        broj_olimpa,
        godina_,
        mjesec,
        graficka,
        tisak,
        issue,
        pdf,
        img,
        urednik,
        prijevod,
        produkcija,
        nakladnik,
        naklada,
        dodatno,
        dodatnoEng,
        opis,
      ],
    ];
    try {
      if (img === null) {
        showAlert('danger', 'Slika nije dodana');
      } else if (pdf === null) {
        showAlert('danger', 'Dokument nije dodan!');
      } else if (pdf && img) {
        const res = await Axios.post(
          api + '/api/content/post-olimp-number',
          val,
          {
            headers: {
              'x-access-token': localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          }
        );
        showAlert('success', res.data.message);
        document.getElementById('olimp').reset();
        setImg(null);
        setPdf(null);
        return res;
      }
    } catch (e) {
      showAlert('success', e.message);
    }
  };

  const get_Autor = useCallback(async () => {
    const get = await getAutor();
    setAutori(get);
  }, []);
  const handleEditor = (value) => {
    setOpis(value);
  };

  useEffect(() => {
    get_Autor();
  }, [getAutor]);
  return (
    <section className='posts-list px-2'>
      <h1>Dodaj novi broj olimpa</h1>

      <form className='pb-5 mb-2' onSubmit={send} id='olimp'>
        <Alert show={AlertShow} type={alertType} message={alertMessage} />
        <div className='d-flex justify-content-between '>
          <button className='bg-blue-800 p-1 text-white px-3 rounded-md my-2'>
            <Link to='/dodaj-olimp-clanak'>Novi članak</Link>
          </button>
          <button
            type='submit'
            className='bg-green-600 p-1 text-white px-3 rounded-md my-2'>
            Objavi novi broj
          </button>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Broj Olimpa</span>
            <input
              required
              onChange={(e) => setBroj(e.target.value)}
              type={'number'}
              placeholder='Unesi broj'
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Godina</span>
            <select onChange={(e) => setGodina(e.target.value)}>
              <option>Odaberi godinu</option>
              {godina.reverse().map((item, index) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </div>
        </div>{' '}
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Mjesec</span>
            <select onChange={(e) => setMjesec(e.target.value)}>
              <option>Odaberi mjesec</option>
              {months.map((item, index) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Grafička priprema</span>
            <input
              onChange={(e) => setGraficka(e.target.value)}
              type={'text'}
              placeholder='Grafička'
            />
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Tisak</span>
            <input
              onChange={(e) => setTisak(e.target.value)}
              type={'text'}
              placeholder='Tisak'
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Issue link</span>
            <input
              required
              onChange={(e) => setIssue(e.target.value)}
              type={'text'}
              placeholder='Issue'
            />
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1 relative'>
            <span>Slika</span>
            <br />
            <button
              className='btn bg-indigo-300 mb-1'
              type='button'
              onClick={() => showModal(true)}>
              Odaberi sliku (olimp-slike)
            </button>
            {modal ? (
              <FolderListSingle
                onClose={() => showModal(false)}
                folder={'uploads'}
                selected={img}
                setImage={setImg}
              />
            ) : null}

            {img ? (
              <img
                width={'70%'}
                style={{ objectFit: 'contain' }}
                src={'https://dev.hoo.hr/' + img}
              />
            ) : null}
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Pdf</span>
            <button
              className='btn bg-indigo-300 mb-1'
              type='button'
              onClick={() => showModalPdf(true)}>
              Odaberi pdf (olimp-pdf)
            </button>
            {modalPdf ? (
              <FolderListSingle
                allowSelect={true}
                onClose={() => showModalPdf(false)}
                folder={'uploads'}
                selected={pdf}
                setImage={setPdf}
              />
            ) : null}
            {pdfLoading ? <p>Pričekajte dok se dokument učita</p> : null}
            {pdf ? (
              <div>
                <i className='bi bi-file-earmark-arrow-down text-black text-4xl'></i>
                <br />
                <span className='text-green-600 font-light'>Link: {pdf}</span>
                <br />
                <span className='text-green-600 font-light'>
                  Puni link: https://dev.hoo.hr/{pdf}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Urednik</span>
            <select onChange={(e) => setUrednik(e.target.value)}>
              <option value={0}>Odaberi urednika</option>
              {autori.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.ime} {item.prezime}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Prijevod</span>
            <select onChange={(e) => setPrijevod(e.target.value)}>
              <option value={0}>Odaberi </option>
              {autori.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.ime} {item.prezime}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Produkcija</span>
            <select onChange={(e) => setProdukcija(e.target.value)}>
              <option value={0}>Odaberi </option>
              {autori.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.ime} {item.prezime}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Nakladnik</span>
            <input
              onChange={(e) => setNakladnik(e.target.value)}
              type={'text'}
              placeholder='Nakladnik'
            />
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Naklada</span>
            <input
              onChange={(e) => setNaklada(e.target.value)}
              type={'number'}
              placeholder='Naklada'
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Dodatno</span>
            <textarea
              onChange={(e) => setDodatno(e.target.value)}
              rows={2}
              type={'text'}
              placeholder='Issue'
            />
          </div>
        </div>
        <div className='m-1'>
          <span>Dodatno eng.</span>
          <textarea
            onChange={(e) => setDodatnoEng(e.target.value)}
            rows={2}
            type={'text'}
            placeholder='Dodatno eng'
          />
        </div>
        <div className='m-1'>
          <span>Opis</span>
          <div className='shadow-sm p-2 py-4 bg-light rounded-md'>
            <ReactQuill
              theme={'snow'}
              onChange={handleEditor}
              value={opis}
              modules={DodajOlimp.modules}
              formats={DodajOlimp.formats}
              bounds={'.app'}
              placeholder={'Opis hr'}
            />
          </div>
        </div>
      </form>
    </section>
  );
};
DodajOlimp.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
DodajOlimp.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'document',
];
export default DodajOlimp;
