export const styles = {
  control: (base, state) => ({
    ...base,
    background: 'white',
    // match with the menu
    zIndex: 100,
    position: 'relative',
    width: '100%',
    border: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
    padding: '0px',
    fontSize: '14px',
    // Overwrittes the different states of border
    borderColor: state.isFocused ? 'white' : 'white',

    // Removes weird border around container
  }),
  multiValue: (styles, state) => ({
    ...styles,
    background: '#001c21',

    color: '#ff4c00',
    margin: '5px',
    padding: '4px 0px',
    borderRadius: '5px',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
    height: 18,

    minHeight: 15,
    fontSize: 12,
    paddingTop: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: 'black',
    borderLeft: '0rem solid #1EB7F3',
    left: '0%',
    border: 'none',
    lineHeight: '1.3rem',
    paddingLeft: '0.5rem',
    marginLeft: '0rem',
  }),
  singleValue: (base, state) => ({
    ...base,
    color: 'white',
    padding: '5px 10px',
    borderRadius: '5px',
    maxWidth: '150px',
    background: '#0f1638',
  }),
  menu: (base, state) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    zIndex: 100,
    // kill the gap
    color: state.isSelected ? 'blue' : 'white',
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    color: state.isSelected ? 'blue' : 'black',
    // kill the white space on first and last option
    padding: 4,
    fontSize: '12px',
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  option: (styles, state) => ({
    ...styles,
    padding: '10px 4px',
    fontSize: '0.75rem',
    textAlign: 'left',

    color: state.isFocused ? '#fff' : '#000',
    borderBottom: '0rem solid #103D56',
    background: state.isFocused ? '#001c21' : 'white',
    ':last-of-type': {
      borderBottom: 'none',
    },
  }),
};
export const style = {
  control: (base, state) => ({
    ...base,
    background: 'transparent',
    // match with the menu

    border: 1,
    borderColor: 'black',
    boxShadow: 'none',

    fontSize: '14px',
    // Overwrittes the different states of border
    borderColor: state.isFocused ? 'transparent' : 'transparent',

    // Removes weird border around container
  }),
  singleValue: (base) => ({
    ...base,
    color: 'yellow',
  }),
  menu: (base, state) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    color: state.isSelected ? 'blue' : 'black',
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    color: state.isSelected ? 'blue' : 'black',
    // kill the white space on first and last option
    padding: 0,
  }),
};
