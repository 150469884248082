import PropTypes from 'prop-types';

const Alert = ({ type, show, message }) => {
  if (!show) {
    return null;
  }
  return (
    <div>
      <div
        className={`alert rounded-nonde alert-${type} my-alert `}
        role='alert'>
        <div className='container'>{message}</div>
      </div>
    </div>
  );
};
Alert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  show: PropTypes.bool,
};

export default Alert;
