import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './PAGES/Auth/Login';
import Not_found from './PAGES/Not_found';
import Register from './PAGES/Auth/Register';
import Verification from './PAGES/Auth/Verification';
import ResetPassword from './PAGES/Auth/resetPassword';
import ResetUserPassword from './PAGES/Auth/resetUserPassword';
import Not_found2 from './PAGES/Not_found2';

function Rutes2() {
  return (
    <div>
      <Router>
        <div className=''>
          <Switch>
            <Route exact path='/' component={Not_found2} />
            <Route exact path='/admin' component={Login} />
            <Route component={Not_found2} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default Rutes2;
