import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOlimp } from '../../store/actions/actions';
import AllOlimpPosts from './AllOlimpArticle';

const OlimpClanci = () => {
  const posts = useSelector((state) => state.olimp);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [titleKey, setTitleKey] = useState('');

  useEffect(() => {
    dispatch(getOlimp(page));
  }, [page, getOlimp]);

  const updateValue = ({ target }) => {
    setPage(target.value);
  };

  if (posts.loading || posts.olimp === null) {
    return (
      <div className='d-flex ' style={{ height: '90vh' }}>
        <p className='m-auto'>Loading posts...</p>
      </div>
    );
  }

  const number = posts.olimp.posts[0]
    ? (posts.olimp.posts[0].total_records * 1).toFixed(0)
    : '0';

  const c_pages = Number(number);
  return (
    <section className='posts-list p-3 '>
      <div className='container-lg'>
        <h2 className=''>Svi postovi</h2>
        <div className='row'>
          <div className='col-md'>
            <select style={{ maxWidth: '200px' }} onChange={updateValue}>
              <option selected>Stranica {page}</option>
              {[...Array(c_pages)].map((e, i) => (
                <option defaultChecked={page} value={i + 1} key={i}>
                  {i + 1}
                </option>
              ))}
            </select>
            <small> Total {c_pages}</small>
          </div>
        </div>
      </div>

      <AllOlimpPosts posts={posts.olimp.posts} />

      <select style={{ maxWidth: '200px' }} onChange={updateValue}>
        <option selected>Stranica {page}</option>
        {[...Array(c_pages)].map((e, i) => (
          <option value={i + 1} key={i}>
            {i + 1}
          </option>
        ))}
      </select>
    </section>
  );
};

export default OlimpClanci;
