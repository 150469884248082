import React, { Component } from "react";
import axios from 'axios';
import jwt from 'jwt-decode'
import { Link } from "react-router-dom";
class ResetPassword extends Component {
    constructor (props) {
        super(props);
        this.state = {
            lists: [],
            loading: true, fetch_user_id: null
        };
    }
    componentDidMount() {
        // get listing



    }
    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message },
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 10000);
    }
    send(e) {
        e.preventDefault();


        const data = {
            email: this.inputEmail.value,
        };
        axios.post(`https://meet-cro-test.herokuapp.com/reset-password-email`, data).then((res) => {
            console.log(res)
            this.showAlert('success', `${res.data.message}`)
            this.resetForm()

        }).catch((err) => {
            this.showAlert('danger', `${err.message}`)
        })





    }
    resetForm() {
        this.refs.CheckoutFormForm.reset();

    }
    isVerifyed() {
        const { accountVerify } = this.state
        if (accountVerify) {
            window.location.href = "/"
        }
        else {

        }

    }
    render() {

        return (
            <div style={ { height: '96vh', width: '100%' } } className="w-100 p-0 m-0 d-flex  bg-grey-500">
                { this.state.alert && <div className={ `alert alert-${this.state.alertData.type} my-alert ` } role='alert'>
                    <div className='container'>
                        { this.state.alertData.message }
                    </div>
                </div> }

                <div className="m-auto">
                    <h3>Please verify Your email here</h3>
                    <form
                        onSubmit={ this.send.bind(this) }
                        ref='CheckoutFormForm'
                    >
                        <input

                            name="email"

                            type="email"
                            placeholder='Enter account email'
                            ref={ (email) => (this.inputEmail = email) }
                        />
                        <button className="my-button-sucess" type="submit">Send email</button>
                    </form>



                </div>

            </div>
        );
    }
}

export default ResetPassword;