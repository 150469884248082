import React, { Component } from 'react'

import axios from 'axios'

import { useHistory, Link, NavLink, } from "react-router-dom";
class Register extends Component {
    constructor () {
        super()
        this.state = {
            f_name: '',
            l_name: '',
            username: '',
            password: '',
            password1: '',
            email: '',
            message: '',
            userAccount: true,
            flag: null,
        }
    }
    changeHandler = (ev) => {
        let nam = ev.target.name
        let val = ev.target.value
        this.setState({
            [nam]: val
        })
    }
    selectAccount() {
        this.setState(prevState => ({
            userAccount: !prevState.userAccount
        }));
    }
    submit(e) {
        e.preventDefault()
        axios.defaults.withCredentials = true;
        // checking password correction and fill the all div
        // after regestratioin redirect to login

        this.setState({ loading: true })

        const log = 6;
        const payment = false;
        const Insert_type = 'USER';
        const company = '';
        const accountTypeRequest = '';
        const phone = '';
        const city = "";
        const ProfileImage = "";

        const data = {
            f_name: this.inputName.value,
            l_name: this.inputLastName.value,
            username: this.inputUsername.value,
            password: this.inputPassword.value,
            email: this.inputEmail.value,
            log: log,
            payment: payment,
            Insert_type: Insert_type,
            company: company,
            accountTypeRequest: this.inputaccountTypeRequest.value,
            phone: phone,
            city: city,
            ProfileImage: ProfileImage

        }
        if (this.inputPassword.value === this.inputPassword2.value) {
            if (this.inputEmail.value || this.inputUsername.value) {
                axios.post('https://meet-cro-test.herokuapp.com/register', data, { credentials: 'include', headers: { 'Content-Type': 'application/json' } })
                    .then((response) => {
                        this.setState({ loading: false })
                        if (response.data.message) {
                            this.setState({
                                message: response.data.message,
                                flag: 1
                            })
                            console.log(response.data)
                            this.showAlert('success', `${response.data.message}`)

                        } else {
                            this.setState({
                                message: response.data.warning,
                                flag: 2
                            })
                            this.showAlert('danger', `${response.data.warning}`)
                        }

                    })

            } else {


                this.showAlert('danger', "Please enter all data!")
            }
        } else {

            this.showAlert('danger', "Entered Passwords are not match\nPlease try again")
        }

    }
    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message },
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000);
    }
    render() {

        return (

            <div className="container-fluid  login-back">
                { this.state.alert && <div className={ `alert alert-${this.state.alertData.type} my-alert ` } role='alert'>
                    <div className='container'>
                        { this.state.alertData.message }
                    </div>
                </div> }
                <form onSubmit={ this.submit.bind(this) } className="container d-flex " style={ { minHeight: '95vh' } }>

                    <div className="jumbotron bg-transparent m-auto pt-4 mt-4">
                        <h3 >If You don't have account please register here!</h3>
                        <p>Send request for listing objects</p>
                        <div className=" justify-center pt-4 pb-4 mt-4 items-center ">

                            <div className="flex">

                                <input className="mr-1" name='f_name' type="text" placeholder="First Name" ref={ (name) => (this.inputName = name) } required />
                                <input className="" name='l_name' type="text" placeholder="Last Name" ref={ (last) => (this.inputLastName = last) } required />
                            </div>
                            <div className="">
                                <input className="" name='username' type="text" placeholder="User Name" ref={ (username) => (this.inputUsername = username) } required />
                            </div>
                            <div className="">
                                <input className="" name='email' type="email" placeholder="Enter Email" ref={ (email) => (this.inputEmail = email) } required />
                            </div>
                            <div className="flex" >
                                <input className="mr-1" name='password' type="password" placeholder="Password" ref={ (password) => (this.inputPassword = password) } required />

                                <input name='password1' type="password" ref={ (password2) => (this.inputPassword2 = password2) } placeholder="Confirm Password" />
                            </div>
                            <p>Register user or business account, please select</p>

                            <span className='my-button-info' onClick={ this.selectAccount.bind(this) }> { this.state.userAccount ? 'Select business' : 'Select user' } account</span><br />
                            { !this.state.userAccount ? <div className='p-1 mt-3'>
                                <h5 className='mt-2'>What type account do you want</h5>
                                <small>After we confirm your payment we will set this account.</small>
                                <select className="form-select" ref={ (accountTypeRequest) => (this.inputaccountTypeRequest = accountTypeRequest) } >
                                    <option value="USER" selected hidden>Sellect account</option>
                                    <option value="ALL">All types</option>
                                    <option value="REALESTATE">Real estate</option>
                                    <option value="RESTAURANT">Restaurant</option>
                                    <option value="RENTABOAT">Rent a boat</option>
                                    <option value="APARTMENT">Apartment booking</option>
                                    <option value="SHOP">Shop</option>
                                </select>
                            </div> : null }



                            <button className="my-button-sucess mt-4" type="submit">
                                Submit
                            </button>
                            { this.state.loading ? <p>Wait...</p> : null }
                            <p id="login-register" className="text-white py-3" ><Link to="/"> Login here</Link></p>
                        </div>
                    </div>
                    <div></div>
                </form>
            </div>


        )
    }
}

export default Register