import Axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import SelectSearch from 'react-select-search';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import SportoviSelect from '../../components/Select/SportoviSelect';
import { getOlimpijciSingle } from '../Profile/GetComments';

const Sportasi_detalji = (props) => {
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  // data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Ime, setIme] = useState('');
  const [Prezime, setPrezime] = useState('');
  const [tekst, setTekst] = useState('');
  const [slika, setSlika] = useState('');
  const [sport, setSport] = useState();

  const [aktivan, setAktivan] = useState();
  const [EVB, setEVB] = useState();
  const [oib, setOib] = useState();
  const [azurirano, setAzurirano] = useState();
  const [olimpijac, setOlimpijac] = useState();
  const [tekstEng, setTekstEng] = useState('');
  const [alert, setAlert] = useState(null);
  const id = props.match.params.id;

  const optionsAktivan = [
    {
      name: 'Aktivan',
      value: 1,
    },
    {
      name: 'Neaktivan',
      value: 0,
    },
  ];
  const optionsOlimpijac = [
    {
      name: 'Da',
      value: 1,
    },
    {
      name: 'Ne',
      value: 0,
    },
  ];
  const onChangeImg = (event) => {
    if (event.target.files[0] != null) {
      uploadImage(event.target.files[0]);
    } else {
    }
  };
  const uploadImage = (img) => {
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/upload.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);

          setSlika(data.image);
          setAlert(data.message);
          setTimeout(() => {
            setAlert(null);
          }, 5000);
        });
      })
      .catch((e) => {
        console.log(e);
        setTimeout(() => {
          setAlert(null);
        }, 5000);
      });
  };
  const getSportasi = async () => {
    const id = props.match.params.id;
    const get = await getOlimpijciSingle(id);
    setData(get);
    setIme(get[0].Ime);
    setPrezime(get[0].Prezime);
    setTekst(get[0].tekst);
    setSlika(get[0].slika);
    setSport(get[0].sportid);
    setTekstEng(get[0].tekst_eng);
    setAktivan(get[0].aktivan);
    setEVB(get[0].EVB);
    setAzurirano(get[0].azurirano);
    setOib(get[0].oib);
    setOlimpijac(get[0].olimpijac);
  };
  useEffect(() => {
    getSportasi();

    setLoading(false);
  }, [id]);

  const update = (e) => {
    e.preventDefault();

    const params = {
      sportid: sport,
      Ime: Ime,
      Prezime: Prezime,
      tekst: tekst,
      tekst_eng: tekstEng,
      slika: slika,
      aktivan: aktivan,
      EVB: EVB,
      oib: oib,
      azurirano: new Date(),
      olimpijac: olimpijac,
      id: id,
    };
    console.log(params);
    Axios.put(api + '/api/sportasi/update', params, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
      })
      .catch((err) => showAlert('success', err.message));
  };
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const handleChangeIntro = (value) => {
    setTekst(value);
  };
  const handleChangeIntroEN = (value) => {
    setTekstEng(value);
  };
  let preview;
  if (slika) {
    preview = (
      <img
        style={{ height: '200px', width: '200px', objectFit: 'cover' }}
        className='mt-3 m-auto rounded-full'
        src={'https://dev.hoo.hr/' + slika}
        alt='File preview'
      />
    );
  }
  if (loading && !data) {
    return <div>Loading...</div>;
  }
  return (
    <section className='posts-list '>
      <h1>Sportaš</h1>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <form onSubmit={update}>
        <div className='d-flex'>
          <button className='bg-green-600 p-1 text-white px-3 rounded-md my-2 ml-auto'>
            Ažuriraj
          </button>
        </div>
        <p> Ažurirano: {moment(azurirano).format('DD.MM.YYYY')}</p>
        <small>Ime i prezime</small>
        <div className='d-flex'>
          <input defaultValue={Ime} onChange={(e) => setIme(e.target.value)} />
          <input
            className='ml-1'
            defaultValue={Prezime}
            onChange={(e) => setPrezime(e.target.value)}
          />
        </div>
        <small>Slika</small>
        <br />
        <small
          className={
            alert === 'Image has uploaded' ? 'text-success' : 'text-danger'
          }>
          {alert}
        </small>
        {preview}
        <label>
          Enter Your File
          <input
            accept='image/*'
            type='file'
            placeholder='odabe'
            name='image'
            onChange={onChangeImg}
          />{' '}
        </label>
        <SportoviSelect
          value={sport}
          setParams={setSport}
          placeholder='Odaberi kategoriju'
        />
        <small>Aktivan</small>
        <SelectSearch
          options={optionsAktivan}
          value={aktivan}
          name=''
          onChange={(value) => setAktivan(value)}
          placeholder='Aktivan'
        />
        <small>Olimpijac</small>
        <SelectSearch
          options={optionsOlimpijac}
          value={olimpijac}
          name=''
          onChange={(value) => setOlimpijac(value)}
          placeholder='Olimpijac'
        />
        <small>Opis HR</small>
        <div className='shadow-sm p-2 py-4 bg-light rounded-md'>
          <ReactQuill
            theme={'snow'}
            onChange={handleChangeIntro}
            value={tekst}
            modules={Sportasi_detalji.modules}
            formats={Sportasi_detalji.formats}
            bounds={'.app'}
            placeholder={'Opis hr'}
          />
        </div>
        <small>Opis Eng</small>
        <div className='shadow-sm p-2 py-4 bg-light rounded-md'>
          <ReactQuill
            theme={'snow'}
            onChange={handleChangeIntroEN}
            value={tekstEng}
            modules={Sportasi_detalji.modules}
            formats={Sportasi_detalji.formats}
            bounds={'.app'}
            placeholder={'Opis eng'}
          />
        </div>
      </form>
    </section>
  );
};
Sportasi_detalji.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Sportasi_detalji.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'document',
];
export default Sportasi_detalji;
