import { Link } from 'react-router-dom';

const Media = () => {
  return (
    <section className='posts-list '>
      <h1>Media</h1>
      <p>Prenesi sliku ili dokument</p>
      <ul className='mt-2 pt-2 d-flex'>
        <li className='my-1 m-2 text-center bg-light p-2 rounded-md'>
          <Link to='/doc-upload'>
            <i class='bi bi-folder-fill text-3xl text-yellow-400'></i>
            <br />
            Dokumenti
          </Link>
        </li>
        <li className='my-1 m-2 text-center bg-light p-2 rounded-md'>
          <Link to='/image-upload'>
            <i class='bi bi-folder-fill text-3xl text-yellow-400'></i>
            <br />
            Slike
          </Link>
        </li>
        <li className='my-1 m-2 text-center bg-light p-2 rounded-md'>
          <Link to='/clanci-slike'>
            <i class='bi bi-folder-fill text-3xl text-yellow-400'></i>
            <br />
            Slike članci
          </Link>
        </li>
        <li className='my-1 m-2 text-center bg-light p-2 rounded-md'>
          <Link to='/doc-upload-clanci'>
            <i class='bi bi-folder-fill text-3xl text-yellow-400'></i>
            <br />
            Dokumenti članci
          </Link>
        </li>
      </ul>
      <p>Ostalo</p>
      <ul className='mt-2 pt-2 d-flex'>
        <li className='my-1 m-2 text-center bg-light p-2 rounded-md'>
          <Link to='/image-old'>
            <i class='bi bi-folder-fill text-3xl text-yellow-400'></i>
            <br />
            Slike sportasa
          </Link>
        </li>
        <li className='my-1 m-2 text-center bg-light p-2 rounded-md'>
          <Link to='/stari-dokumenti'>
            <i class='bi bi-folder-fill text-3xl text-yellow-400'></i>
            <br />
            Stari dokumenti
          </Link>
        </li>
        <li className='my-1 m-2 text-center bg-light p-2 rounded-md'>
          <Link to='/web-slike'>
            <i class='bi bi-folder-fill text-3xl text-yellow-400'></i>
            <br />
            Slike web
          </Link>
        </li>
      </ul>
    </section>
  );
};

export default Media;
