import axios from 'axios';
import jwt from 'jwt-decode';
import api from '../../components/Api/api';
export const getPages = async (lng) => {
  try {
    const res = await axios.get(`${api}/api/content/get/pages/${lng}`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getPageDetails = async (id) => {
  try {
    const res = await axios.get(`${api}/api/content/get/categories/${id}`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getImages = async () => {
  try {
    const res = await axios.get(`${api}/api/content/get/images`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getMedalje = async () => {
  try {
    const res = await axios.get(`${api}/api/content/get/medalje`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const _user_token = () => {
  const token = localStorage.getItem('token');
  const userToken = token ? jwt(token) : '';

  return userToken;
};
export const getOlimpijci = async () => {
  try {
    const res = await axios.get(`${api}/api/sportasi/dohvati`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getOlimpijciSingle = async (id) => {
  try {
    const res = await axios.get(`${api}/api/sportasi/dohvati/${id}`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getKategorizacije = async () => {
  try {
    const res = await axios.get(`${api}/api/kategorizacija/dohvati`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getKategorizacijeSingle = async (id) => {
  try {
    const res = await axios.get(`${api}/api/kategorizacija/dohvati/${id}`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
//accodrion
export const getAccordions = async (id) => {
  try {
    const res = await axios.get(`${api}/api/content/get/page-accordion/${id}`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
//page gallery
export const getPageGallery = async (id) => {
  try {
    const res = await axios.get(`${api}/api/content/get/page-gallery/${id}`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
//get doc categories
export const getDocCategories = async (lng) => {
  try {
    const res = await axios.get(
      `${api}/api/content/get/doc-categories-admin-all/${lng}`
    );

    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
//get doc
export const getDocSingle = async (id) => {
  try {
    const res = await axios.get(`${api}/api/content/get/documents-admin/${id}`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const addDoc = async (datas) => {
  try {
    const res = await axios.get(`${api}/api/content/get/post-dokument`, datas);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getAutor = async () => {
  try {
    const res = await axios.get(`${api}/api/content/get/olimp-autori`);
    const data = await res.data;
    return data;
  } catch (e) {
    console.log(e);
  }
};
