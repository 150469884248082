import React, { useState } from 'react';
import axios from 'axios';
import api from '../../components/Api/api';

export default function Login(props) {
  const [username, setUsername] = useState('');
  const [msg, setMsg] = useState('');
  const [cls, setClass] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  axios.defaults.withCredentials = true;
  const login = () => {
    if (!username || !password) {
      alert('Insert data');
      return false;
    }
    setLoading(true);
    axios
      .post(
        `${api}/api/auth/login`,
        {
          username,
          password,
        },
        {
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .then((res) => {
        if (!res.data.auth) {
          setLoginStatus(false);
          setMsg(res.data.message);
          setLoading(false);
          setClass('login-danger');
          setTimeout(() => {
            setMsg('');
            setClass('');
          }, 1700);
        } else {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('userData', res.data.role);
          setLoginStatus(true);
          setClass('login-success');
          setMsg('You are logged in');
          setTimeout(() => {
            setMsg('');
            setClass('');
          }, 1200);
          window.location.href = '/';
          setLoading(false);
        }
      });
  };
  const userAuthenticated = () => {
    axios
      .get(api + '/api/token', {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      })
      .then((res) => {
        console.log(res);
      });
  };
  const auth = () => {
    setTimeout(() => {
      window.location.href = '/';
    }, 1200);
  };

  return (
    <div className=' login-back'>
      <div className='flex login-container row'>
        <div className='d-flex login-back-image'>
          <div style={{ maxWidth: '470px', margin: 'auto', padding: '12px' }}>
            <h1 className='pt-4 font-weight-bold text-white'>HOO ADMIN</h1>
            <p className='t text-white'>
              To continue please login to Your admin account...
            </p>
          </div>
        </div>
        <div className='login'>
          <h3>Prijavi se</h3>
          <input
            name='username'
            type='text'
            placeholder='Username...'
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <input
            name='password'
            type='password'
            placeholder='Password...'
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <div className='d-flex p-0'>
            {' '}
            <button className='my-button-black py-2 px-4' onClick={login}>
              Prijavi se
            </button>
            <br />
            {loading ? (
              <img
                alt='img'
                style={{
                  margin: 'auto',
                  width: '40px',
                  height: 'auto',
                  objectFit: 'contain',
                }}
                src='https://i.gifer.com/ZZ5H.gif'
              />
            ) : (
              <div className='p-2'>
                <small className={cls} onClick={userAuthenticated}>
                  {msg}
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
