import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import jwt from 'jwt-decode';
import axios from 'axios';
import DatePicker from 'react-datepicker';

import CategoriesSelect from '../../components/Select/CategoriesSelect';
import SelectSearch from 'react-select-search';
import Alert from '../../components/Alert/Alert';
import ModalImage from '../../components/Modal/ModalImage';
import api from '../../components/Api/api';
import '../../components/Select/select-search.css';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import MultipleCaption from '../../components/Modal/MultupleCaption';
import FolderList from '../NovaMedia/Media';
import FolderListSingle from '../NovaMedia/MediaModalSingle';
import Axios from 'axios';

const AddPost = (props) => {
  const [caption, setGalleryCaption] = useState('');
  const [foldername, setFolderName] = useState('');
  const { alias } = useParams();
  const [startdate, setStartDate] = useState(new Date());
  const [param, setParams] = useState();
  const [page_images, setPageImage] = useState([]);
  const [listaSlika, setSlike] = useState([]);
  const [name, setName] = useState(null);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState();
  const [intro, setIntroText] = useState();
  const [language, setLangugage] = useState('hr-HR');
  const [imageCaption, setCaption] = useState();
  const [post_image, setImage] = useState(null);
  const [modal, showModal] = useState(false);
  const [modalGallery, showModalGallery] = useState(false);
  const [fileName, setFileName] = useState('uploads/244800-front1.png');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [alert, setAlert] = useState(null);
  const [editorValue, setEditor] = useState();
  const [id, setId] = useState();
  const [edit_link, setEditLink] = useState();
  const [post_added, setPostAdded] = useState(false);
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [gallery_with_captions, setGalleryWithCaptions] = useState();
  const quillRef = useRef(null);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],

        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );
  function imageHandler() {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    const value = prompt('Please enter the image URL');

    if (value && range) {
      editor.insertEmbed(range.index, 'image', value, 'user');
    }
  }
  const options = [
    {
      name: 'Objavi',
      value: 1,
    },
    {
      name: 'Neobjavljeno/ skica',
      value: 0,
    },
  ];
  const optionsLangugage = [
    {
      name: 'Engleski',
      value: 'en-GB',
    },
    {
      name: 'Hrvatski',
      value: 'hr-HR',
    },
  ];
  const unselect = (item, id) => {
    const isImageSelected = page_images.some((image) => image.image === id);

    if (isImageSelected) {
      const updatedImages = page_images.filter((image) => image.image !== id);
      setPageImage(updatedImages);
      // Reset the `ima` value when the image is unselected
    } else {
    }
  };
  useEffect(() => {
    setImage(page_images);
  }, [setPageImage]);
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const onChangeImg = (event) => {
    if (event.target.files[0] != null) {
      setName(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      uploadImage(event.target.files[0]);
    } else {
    }
  };
  function convertToSlug(Text) {
    if (Text) {
      return Text.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
    } else {
      return '';
    }
  }
  const UpdatePost = async () => {
    const token = localStorage.getItem('token');
    const userToken = token ? jwt(token) : '';
    const userID = userToken.id;
    const slika = {
      float_fulltext: '',
      float_intro: '',
      image_fulltext: post_image,
      image_fulltext_alt: '',
      image_fulltext_caption: imageCaption,
      image_intro: post_image,
      image_intro_alt: '',
      image_intro_caption: '',
    };

    const params = {
      fulltext: editorValue,
      images: JSON.stringify(slika),
      alias: convertToSlug(title),
      title: title,
      alias: convertToSlug(title),
      introtext: intro,
      catid: param,
      modified: startdate,
      state: status,
      modified_by: userID,
      language: language,
      created: startdate,
      created_by: userID,
      publish_up: startdate,
      access: 1,
      featured: 1,
      asset_id: 0,
      gallery: JSON.stringify(page_images),
    };
    if (
      (params.title !== undefined &&
        params.catid !== undefined &&
        params.language !== '') ||
      (undefined && params.state !== undefined)
    ) {
      setLoading(true);
      axios
        .post(api + '/api/content/add/post', params, {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          setPostAdded(true);
          setEditLink(params.alias);
          setTimeout(() => {
            setLoading(false);
          }, [1000]);
          showAlert('success', res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          showAlert('danger', 'Unesi podatke');
          console.log(err);
        });
    } else {
      setLoading(false);
      showAlert('danger', 'Unesite podatke');
    }
  };
  const dohvatiSlike = async (type) => {
    setFolderName(type);
    showModal(true);
    setLoading(false);
  };

  const dohvatiSlikeGalerije = async (type) => {
    setFolderName(type);
    showModalGallery(true);
    setLoading(false);
  };
  const folderi = (type) => {
    dohvatiSlike(type);
  };

  const uploadImage = (img) => {
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/clanci-slike.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);
          setFileName(data.url);
          setImage(data.image);
          showAlert('success', data.message);
        });
      })
      .catch((e) => {
        console.log(e);
        showAlert('success', e.message);
      });
  };

  const handleChange = (value) => {
    setEditor(value);
  };
  const handleChangeIntro = (value) => {
    setIntroText(value);
  };
  const selectLangugage = (value) => {
    setLangugage(value);
  };

  const select = (value) => {
    setStatus(value);
  };

  let preview;
  if (Array.isArray(post_image) && post_image.length === 0) {
    preview = <span>Nema slike</span>;
  } else {
    preview = <img src={'https://dev.hoo.hr/' + post_image} />;
  }
  const handleCaptionChange = (event, index) => {
    const updatedImages = [...page_images];
    updatedImages[index].caption = event.target.value;

    setPageImage(updatedImages);
  };
  if (loading) {
    return (
      <div className='d-flex ' style={{ height: '90vh' }}>
        <p className='m-auto'>Post se objavljuje...</p>
      </div>
    );
  }
  return (
    <>
      <div className='container  '>
        <Alert show={AlertShow} type={alertType} message={alertMessage} />
        {modal ? (
          <FolderListSingle
            onClose={() => showModal(false)}
            listaSlika={listaSlika}
            folder={foldername}
            selected={post_image}
            setImage={setImage}
          />
        ) : null}
        <div className='row'>
          <h1 className='m-0'>Dodaj članak</h1>
        </div>
        <div className='row '>
          <div className='col-md p-0'>
            <h6 className='py-4'>Naslov</h6>
            <input
              placeholder='Naslov'
              value={title}
              defaultValue={title}
              name='name'
              required
              className=' rounded-lg box-shadow py-6 border border-gray-100'
              onChange={(e) => setTitle(e.target.value)}
            />
            <h6 className='py-4'>Uvodni text</h6>
            <div className=' rounded-lg box-shadow py-6 bg-white border border-gray-100'>
              <ReactQuill
                theme={'snow'}
                onChange={handleChangeIntro}
                value={intro}
                modules={AddPost.modules}
                formats={AddPost.formats}
                bounds={'.app'}
                placeholder={'Intro text'}
              />
            </div>
            <h6 className='py-4'>Sadržaj</h6>
            <div className=' rounded-lg box-shadow py-6 bg-white border border-gray-100'>
              <ReactQuill
                theme={'snow'}
                ref={quillRef}
                onChange={handleChange}
                value={editorValue}
                modules={modules}
                formats={AddPost.formats}
                bounds={'.app'}
                placeholder={'Post text'}
              />
            </div>
          </div>

          <div className='col-md-4  rounded-lg mx-1'>
            {!post_added ? (
              <button
                disabled={title < 1}
                className='bg-green-400  p-1 text-white px-3 my-2'
                onClick={() => UpdatePost()}>
                Objavi novi post
              </button>
            ) : (
              <a href={'/post/detalji/' + edit_link}>
                <button
                  disabled={title < 1}
                  className='bg-black p-1 text-white px-3  my-2'>
                  Uredi ovaj članak
                </button>
              </a>
            )}
            <hr />
            <small>Vrijeme objave</small>
            <DatePicker
              selected={startdate}
              dateFormat='dd-MM-yyyy'
              onChange={(date) => setStartDate(date)}
            />

            <hr />
            <small>Slika</small>
            <br />
            <small
              className={
                alert === 'Image uploaded' ? 'text-success' : 'text-danger'
              }>
              {alert}
            </small>
            {preview}
            <br />

            <small> Pretraži i dodaj iz arhive</small>
            <br />
            <button
              onClick={() => dohvatiSlike('clanci-slike')}
              type='button'
              className='bg-blue-500 p-1 text-white px-3  my-2'
              data-toggle='modal'
              data-target='#exampleModal'>
              Arhiva nova
            </button>
            <button
              onClick={() => dohvatiSlike('images')}
              type='button'
              className='bg-gray-900 ml-1 p-1 text-white px-3  my-2'
              data-toggle='modal'
              data-target='#exampleModal'>
              Arhiva stara
            </button>
            <input
              defaultValue={imageCaption}
              placeholder='Image caption'
              className='bg-white'
              onChange={(e) => setCaption(e.target.value)}
            />
            <small>Category</small>
            <div style={{ width: '100%' }}>
              <CategoriesSelect
                value={param}
                setParams={setParams}
                placeholder='Odaberi kategoriju'
              />
            </div>

            <div className='mb-3' />
            <small>Status</small>
            <SelectSearch
              options={options}
              value={status}
              name='language'
              onChange={select}
              placeholder='Status'
            />
            <small>Language</small>
            <SelectSearch
              options={optionsLangugage}
              value={language}
              name='language'
              onChange={selectLangugage}
              placeholder='Jezik'
            />
          </div>
        </div>
      </div>

      <div className='p-3 '>
        <div className='py-16 p-2  w-full border border-gray-100 bg-white rounded-md'>
          <h6 className='py-4'>Galerija </h6>
          <p>Odaberi slike iz galerija</p>
          <button
            onClick={() => dohvatiSlikeGalerije('images')}
            className='btn bg-blue-500   text-white mb-2'
            data-toggle='modal'
            data-target='#exampleModal'>
            Arhiva stara
          </button>
          <button
            onClick={() => dohvatiSlikeGalerije('galerije')}
            className='btn bg-gray-700 ml-2  text-white mb-2'
            data-toggle='modal'
            data-target='#exampleModal'>
            Arhiva nova
          </button>
        </div>
        {modalGallery ? (
          <FolderList
            multiple={true}
            folder={foldername}
            withCaption={true}
            onClose={() => showModalGallery(false)}
            listaSlika={listaSlika}
            setImage={setPageImage}
            selected={page_images}
          />
        ) : null}
        <div className='grid grid-cols-3 gap-4'>
          {page_images.map((i, index) => (
            <div className='bg-gray-100 px-3'>
              <small onClick={() => unselect(i, i.image)}>
                <i class='bi bi-trash text-black-500 text-xl'></i>
              </small>
              <img src={'https://dev.hoo.hr/' + i.image} />
              <input
                type='text'
                value={i.caption || ''}
                placeholder='Caption'
                onChange={(event) => handleCaptionChange(event, index)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
AddPost.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
AddPost.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'document',
];

export default AddPost;
