import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ClanciSlike = () => {
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dokumenti, setDokumenti] = useState(true);
  const [alert, setAlert] = useState(null);

  const dohvatiDokumente = async (type) => {
    const response = await fetch(
      'https://dev.hoo.hr/dohvati-slike-clanci.php'
    ).then((response) => response.json());
    setLoading(false);
    setDokumenti(response);
    console.log(response);
  };

  useEffect(() => {
    dohvatiDokumente();
  }, [fileName]);

  const onChangeImg = (event) => {
    if (event.target.files[0] != null) {
      console.log(event.target.files);
      for (let i = 0; i < event.target.files.length; i++) {
        uploadImage(event.target.files[i]);
      }
    } else {
    }
  };

  const uploadImage = (img) => {
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/clanci-slike.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);
          setFileName(data.url);

          setAlert(data.message);
          setTimeout(() => {
            setAlert(null);
          }, 5000);
        });
      })
      .catch((e) => {
        console.log(e);
        setTimeout(() => {
          setAlert(null);
        }, 5000);
      });
  };

  return (
    <>
      <h1>Dodaj novu sliku</h1>
      {alert != null ? (
        <p
          className={
            alert === 'Dokument je uploadan!' ? 'text-success' : 'text-danger'
          }>
          {alert}
        </p>
      ) : null}

      <label>
        Enter Your File
        <input
          multiple
          accept='image/*'
          type='file'
          placeholder='odabe'
          name='image'
          onChange={onChangeImg}
        />{' '}
      </label>
      {fileName != null ? (
        <div>
          <div className='card'>
            <i class='bi bi-check2-circle  text-success text-5xl p-3'></i>
            <img
              src={fileName}
              className='p-3 rounded-lg'
              style={{ width: '200px', height: 'auto' }}
            />

            <p className='p-3'>
              Vrati se na{' '}
              <Link className='text-success' to='/'>
                početnu
              </Link>
            </p>
          </div>
        </div>
      ) : null}
      {loading ? (
        <div className='loader-div'>
          <div className='m-auto items-center justify-center '>
            <div
              style={{ borderTopColor: 'transparent' }}
              className='w-16 h-16 border-4 border-gray-500 border-solid rounded-full animate-spin'></div>
          </div>
        </div>
      ) : (
        <div className='container mt-5 pt-5'>
          <h3>Slike</h3>
          <div className='row'>
            {Object.values(dokumenti).map((item, index) => (
              <div
                style={{ border: 'none' }}
                key={index}
                className='col-md-4  c py-4 border-none'>
                <img
                  style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                  src={'https://dev.hoo.hr/' + item.data_name}
                  className='img-thumbnail'
                />

                <small className='font-weight-normal text-xs'>
                  <b className='text-blue-800'>Naziv:</b> {item.name}
                </small>
                <br />

                <small className='font-weight-normal text-xs'>
                  <b className='text-blue-800'>Upload date:</b>
                  {item.date}
                </small>
                <br />
                <small className='font-weight-normal text-xs'>
                  <b className='text-blue-800'>Skraćeni url:</b>{' '}
                  {item.data_name}
                </small>
                <br />
                <small className='font-weight-normal text-xs'>
                  <b className='text-blue-800'>Url:</b> https://dev.hoo.hr/
                  {item.data_name}
                </small>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ClanciSlike;
