import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';

import { getDocCategories, getDocSingle } from '../Profile/GetComments';
import Axios from 'axios';
import Switch from 'react-switch';

const DokumentiSingle = () => {
  const { id } = useParams();
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [dokumenti, setDokumenti] = useState([]);
  const [folderSelected, setFolderSelected] = useState(id);
  const [subfolderSelected, setSubFolderSelected] = useState();
  const [subFolders, setSubFolders] = useState([]);
  const [showInSub, setShowInSub] = useState(false);
  const [lowercasedFilter, setFilter] = useState('');
  const [doc, setDoc] = useState([]);
  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [folderTitle, setFolderTitle] = useState('');
  const [docTitle, setDocTitle] = useState('');
  const [docName, setDocName] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [showId, setShowId] = useState('');
  const [link, setDocLink] = useState('');
  const [change, setChange] = useState(false);
  const [smallLoader, setSmallLoader] = useState(false); // State variable to hold the order of items

  // ... Other existing code ...

  // Function to handle reordering of items
  const handleReorder = (startIndex, endIndex) => {
    const reorderedData = Array.from(doc);
    const [reorderedItem] = reorderedData.splice(startIndex, 1);
    reorderedData.splice(endIndex, 0, reorderedItem);
    setDoc(doc);
    updateOrderInDatabase(reorderedData);
  };

  // Function to update the order in the database
  const updateOrderInDatabase = async (values) => {
    try {
      // Prepare the data to be sent to the backend
      const dataToSend = {
        order: values.map((item, index) => ({
          doc_id: item.doc_id,
          orderIndex: index + 1,
        })),
      };
      setSmallLoader(true);

      // Make the API call to update the order in the database using Axios
      const response = await Axios.post(
        api + '/api/content/dokumenti-redosljed',
        dataToSend
      );

      // Handle the response if needed
      if (response.status === 200) {
        dohvati();
        showAlert('success', response.data.message);
        setTimeout(() => {
          setSmallLoader(false);
        }, [1700]);
      } else {
        setSmallLoader(false);
        alert(response.data.message);
      }
    } catch (error) {
      setSmallLoader(false);
      alert(error.message);
    }
  };

  // Call the function to update the order in the database whenever the 'order' state changes

  function httpHtml(content) {
    var myStr = content;
    return myStr.replace(/[*+?^%/_${}()|[\]\\]/g, ' ');
  }

  const dohvati = async () => {
    try {
      const get = await getDocSingle(id);
      setDoc(get);
      setLoading(false);
      setFolderTitle(get[0].title);
    } catch (error) {
      console.log(error);
    }
  };

  const dohvatiKategorije = async () => {
    try {
      const get = await getDocCategories();
      setPage(get);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const dohvatiPodKategorije = async () => {
    try {
      const res = await Axios.get(
        api + '/api/content/get/subfolder-admin/' + folderSelected,
        {
          headers: {
            'x-access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      setSubFolders(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dohvati();
    dohvatiKategorije();
  }, []);

  useEffect(() => {
    dohvatiPodKategorije();
  }, [folderSelected]);

  const removeFolder = (id) => {
    Axios.delete(api + '/api/content/get/remove-dokument/' + id, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
        dohvati();
      })
      .catch((e) => {
        console.log(e);
        showAlert('danger', 'danger');
      });
  };

  const updateDocFolder = () => {
    const params = {
      title: folderTitle,
      title_eng: doc[0].title_eng,
      show_doc_page: 1,
      page_id: folderSelected,
      sub_folder: subfolderSelected,
      show_in_sub: showInSub,
      id: id,
    };
    Axios.put(api + '/api/content/get/update-doc-categories/', params, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
      })
      .catch((e) => {
        console.log(e);
        showAlert('danger', 'danger');
      });
  };

  const showModal = (doc_id, name, link, show) => {
    setDocLink(link);
    setDocName(name);
    setShowId(doc_id);
    setShowInSub(show);
    setModalShow(true);
  };

  const updateDocName = (doc_id, sub_id) => {
    const params = {
      doc_name: docName,
      link: link,
      doc_id: doc_id,
      page_id: folderSelected,
      sub_folder: subfolderSelected ? subfolderSelected : sub_id,
      show_in_sub: showInSub,
    };
    Axios.put(api + '/api/content/get/update-document/', params, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
        setModalShow(false);
        dohvati();
      })
      .catch((e) => {
        console.log(e);
        showAlert('danger', e.message);
      });
  };

  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  if (loading) {
    return (
      <div className='d-flex ' style={{ height: '90vh' }}>
        <p className='m-auto'>Pričekaj...</p>
      </div>
    );
  }

  const filteredData = Object.values(doc).filter((item) => {
    return Object.keys(item).some(
      (key) =>
        typeof item[key] === 'string' &&
        item.doc_name.toLowerCase().includes(lowercasedFilter.toLowerCase())
    );
  });

  console.log(doc);
  return (
    <section className='posts-list'>
      {smallLoader ? (
        <div
          style={{ background: 'rgba(0,0,0,0.6)' }}
          className='absolute top-0 bottom-0 left-0 right-0 z-40 flex'>
          <p className='m-auto text-white'>Ordering...</p>
        </div>
      ) : null}
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <p className='text-success'>
        <Link to='/dokumenti-folderi'>Nazad</Link>
      </p>
      <small className='ml-4'>Promjeni naziv foldera</small>
      <br />
      <input
        className='ml-4 w-50'
        defaultValue={folderTitle}
        onChange={(e) => setFolderTitle(e.target.value)}
      />
      <button
        onClick={() => updateDocFolder()}
        className='bg-green-600 p-1 text-white px-3 rounded-md m-2'>
        Promjeni
      </button>
      <section className='antialiased text-gray-600 px-4'>
        <input
          onChange={(e) => setFilter(e.target.value)}
          placeholder='Pretraži'
        />
        <div className='flex flex-col justify-center h-full'>
          <div className='w-full mx-auto bg-white rounded-sm'>
            <header className='px-2 py-4 border-none border-gray-100'>
              <div className='font-semibold text-gray-800'>
                <h1> Dokumenti {doc[0]?.title}</h1>
              </div>
              {/* <button onClick={() => updateOrderInDatabase()}>
                Update order
              </button> */}
            </header>
            <div className='overflow-x-auto p-3'>
              <table className='table-auto w-full'>
                <thead className='text-xs font-semibold uppercase'>
                  <tr>
                    <th className='p-2'>
                      <div className='font'>#</div>
                    </th>
                    <th className='p-2'>
                      <div className='font'>Naziv</div>
                    </th>

                    <th className='p-2'>
                      <div className='font-semibold text-left'>Godina</div>
                    </th>
                    <th className='p-2'>
                      <div className='font-semibold text-left'>Link</div>
                    </th>
                    <th className='p-2'>
                      <div className='font-semibold text-left'>Redni broj</div>
                    </th>
                    <th className='p-2'>
                      <div className='font-semibold text-center'>Akcija</div>
                    </th>
                    <th className='p-2'>
                      <div className='font-semibold text-center'>
                        U podfolderu
                      </div>
                    </th>
                    <th className='p-2'>
                      <div className='font-semibold text-center'>Akcija</div>
                    </th>
                  </tr>
                </thead>

                <tbody className='text-sm relative divide-y p-1'>
                  {filteredData.map((item, index) => (
                    <tr
                      className={index % 2 ? 'bg-gray-200' : ''}
                      style={{ position: 'relative' }}
                      draggable
                      onDragStart={(e) =>
                        e.dataTransfer.setData('index', index)
                      }
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        e.preventDefault();
                        const startIndex = e.dataTransfer.getData('index');
                        const endIndex = index;
                        handleReorder(startIndex, endIndex);
                      }}>
                      {modalShow && showId === item.doc_id ? (
                        <div
                          className='bg-white p-2'
                          style={{
                            position: 'fixed',
                            top: '0%',
                            bottom: '0%',
                            width: '60%',
                            right: 0,
                            zIndex: 29990,
                            background: 'white',
                          }}>
                          <h1>Uredi</h1>
                          <p>{item.doc_name}</p>
                          <div className='mt-4'>
                            <small>Naziv</small>
                            <input
                              className='m-1'
                              defaultValue={item.doc_name}
                              onChange={(e) => setDocName(e.target.value)}
                            />
                            <small>Link</small>
                            <input
                              className='m-1'
                              defaultValue={item.link}
                              onChange={(e) => setDocLink(e.target.value)}
                            />
                          </div>
                          <div>
                            <small>Promijeni folder dokumenta</small>
                            <select
                              onChange={(e) =>
                                setFolderSelected(e.target.value)
                              }>
                              {Object.values(page).map((item, index) => (
                                <option
                                  selected={item.id == id}
                                  value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                            <small>Promijeni podfolder dokumenta</small>
                            <select
                              onChange={(e) =>
                                setSubFolderSelected(e.target.value)
                              }>
                              <option value={null}>Select sub folder</option>
                              {Object.values(subFolders).map((valm, index) => (
                                <option
                                  selected={item.sub_folder == valm.sub_id}
                                  key={index}
                                  value={valm.sub_id}>
                                  {valm.title}
                                </option>
                              ))}
                            </select>
                            <p>Prikaži dokument u podfolderu</p>
                            <Switch
                              offColor='#333'
                              onColor='#8847d7'
                              placeholder='Ping'
                              onChange={() =>
                                setShowInSub(item.show_in_sub == 1 ? 0 : 1)
                              }
                              checked={showInSub == 1}
                            />
                          </div>
                          <br />
                          <button
                            className='bg-green-600 p-1 text-white px-3 rounded-md my-2'
                            onClick={() =>
                              updateDocName(item.doc_id, item.sub_folder)
                            }>
                            Update
                          </button>
                          <button
                            className='bg-blue-800 p-1 ml-2 text-white px-3 rounded-md my-2'
                            onClick={() => {
                              setShowId(null);
                            }}>
                            Zatvori
                          </button>
                        </div>
                      ) : null}
                      <td className='p-2'>{item.doc_id}</td>
                      <td className='p-2' style={{ maxWidth: '300px' }}>
                        <div className=' text-gray-800'>
                          <a
                            href={'https://dev.hoo.hr/' + item.link}
                            target={'_blank'}>
                            <p className='text-xs onhover:text-red-300'>
                              {httpHtml(item.doc_name)}
                            </p>
                          </a>
                        </div>
                      </td>
                      <td className='p-2'>
                        <div className='text-left'>{item.year}</div>
                      </td>
                      <td className='p-2 ' style={{ maxWidth: '200px' }}>
                        <div className='text-left font-medium text-blue-800 truncate'>
                          {item.link}
                        </div>
                      </td>
                      <td className='p-2' style={{ maxWidth: '300px' }}>
                        <div className='text-left font-medium text-blue-800'>
                          {item.orderIndex}
                        </div>
                      </td>
                      <td>
                        <button
                          className='bg-green-600 p-1 text-white px-3 rounded-md my-2'
                          onClick={() =>
                            showModal(
                              item.doc_id,
                              item.doc_name,
                              item.link,
                              item.show_in_sub
                            )
                          }>
                          Uredi
                        </button>
                      </td>
                      <td className='text-center flex'>
                        {item.show_in_sub ? (
                          <p className='text-success bold'>Da</p>
                        ) : (
                          <p>Ne</p>
                        )}
                      </td>
                      <td className='p-2'>
                        <div className='flex justify-center'>
                          <button onClick={() => removeFolder(item.doc_id)}>
                            <svg
                              className='w-8 h-8 hover:text-red-600 text-red-500 rounded-full hover:bg-red-100 p-1'
                              fill='none'
                              stroke='currentColor'
                              viewBox='0 0 24 24'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                stroke-width='2'
                                d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'></path>
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default DokumentiSingle;
