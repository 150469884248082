import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';

const FolderListSingle = ({
  onClose,
  setImage,
  multiple,
  folder,
  selected,
  allowSelect,
}) => {
  const [lowercasedFilter, setFilter] = useState('');
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderContents, setFolderContents] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [subfolders, setSubfolders] = useState([]);
  const [sub, setSub] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [imageLoading, setLoadingImage] = useState(false);
  const inputFileRef = useRef(null);
  const [images, setImages] = useState(selected ? selected : []);
  // alert
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 6000);
  };
  const select = (item) => {
    if (!multiple) {
      setImage(item);
      onClose();
    } else {
      if (images.some((i) => i === item)) {
        const updatedImages = images.filter((i) => i !== item);
        setImages(updatedImages);
      } else {
        setImages([...images, item]);
      }
    }
  };

  const setIma = (item) => {
    setImage([...images, item]);
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUploadFiles = () => {
    setLoadingData(true);
    showAlert('warning', 'Uploading files...');

    if (selectedFiles.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files[]', selectedFiles[i]);
      }

      formData.append('folder', folder + '/');
      formData.append('subfolder', sub ? sub : '');
      axios
        .post(`https://dev.hoo.hr/upload-script.php`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (sub == null) {
            return fetchFolders();
          } else {
            return fetchFoldersWithSubfolder(sub);
          }
        })
        .then(() => {
          setLoadingImage(true);
          setLoadingData(true);
        })
        .catch((error) => {
          showAlert('error', 'Error');
          console.error('Error:', error);
        })

        .finally(() => {
          showAlert('success', 'Successfully uploaded');
          fetchFolders();
          setLoadingImage(false);
          setLoadingData(false);
          setSelectedFiles([]);
          inputFileRef.current.value = '';
        });
    }
  };

  const fetchFolders = async () => {
    setLoadingData(true);

    try {
      const response = await axios.get(
        `${api}/api/content/get-media?folder=${folder + '/'}`
      );

      if (response.data) {
        setFolders(response.data);
        setLoadingData(false);
      } else {
        setFolders([]);
        setLoadingData(false);
      }
    } catch (error) {
      setLoadingData(false);
      console.error('An error occurred while fetching folders:', error);
    }
  };

  useEffect(() => {
    if (sub === null) {
      fetchFolders();
    } else {
      fetchFoldersWithSubfolder(sub);
    }
  }, [sub, imageLoading]);

  const fetchFoldersWithSubfolder = async (subfolder) => {
    setLoadingData(true);

    try {
      const response = await axios.get(
        `${api}/api/content/get-media?subfolder=${encodeURIComponent(
          subfolder
        )}&folder=${encodeURIComponent(folder + '/')}`
      );

      setLoadingData(false);

      if (response.data) {
        setFolders(response.data);
      } else {
        setFolders([]);
      }
    } catch (error) {
      setFolders([]);
      console.error('An error occurred while fetching folders:', error);
      setLoadingData(false);
    }
  };

  const handleCreateFolder = () => {
    if (newFolderName) {
      showAlert('warning', 'Creating folder...');

      const data = new FormData();
      data.append('folder', folder + '/');
      data.append('subfolder', sub ? sub : '');
      data.append('folderName', newFolderName);

      fetch('https://dev.hoo.hr/create-folder-script.php', {
        method: 'POST',
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            throw new Error(data.error);
          } else {
            setLoadingImage(true);
            setLoadingData(true);
            showAlert('success', 'Folder created!');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setLoadingImage(false);
          setLoadingData(false);
        });
    }
  };

  const handleNavigateBack = () => {
    if (subfolders.length > 0) {
      const lastSubfolder = subfolders[subfolders.length - 1];
      setSubfolders(subfolders.slice(0, -1));
      setSub(lastSubfolder);
    } else {
      setSub(null);
    }
  };

  const handleSubfolderClick = (subfolderPath) => {
    const newSubfolders = [...subfolders, subfolderPath];
    setSubfolders(newSubfolders);
    setSub(subfolderPath);
  };

  const filteredData = Object.values(folders).filter((item) =>
    Object.keys(item).some(
      (key) =>
        typeof item[key] === 'string' &&
        item?.name?.toLowerCase().includes(lowercasedFilter.toLowerCase())
    )
  );
  const renderFolderContents = () => {
    if (filteredData.length === 0) {
      return (
        <tr>
          <td colSpan='4'>No items found.</td>
        </tr>
      );
    }

    return filteredData.map((folder, index) =>
      folder.type != 'folder' ? (
        <tr
          key={index}
          className={images == folder.path ? 'bg-green-300' : 'bg-white'}>
          <td>
            {folder.type != 'image' ? (
              <i class='bi bi-file-earmark text-2xl'></i>
            ) : (
              <img
                src={`https://dev.hoo.hr/${folder.path}`}
                alt={folder.name}
                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
              />
            )}
          </td>
          <td className='text-truncate text-sm' style={{ maxWidth: '250px' }}>
            {folder.name}
          </td>
          <td>{moment(folder.time).format('DD.MM.YYYY')}</td>
          <td>
            <button
              className='bg-gray-100 text-gray-800 px-2 text-xs py-1 rounded-lg'
              onClick={() => {
                navigator.clipboard
                  .writeText('https://dev.hoo.hr/' + folder.path)
                  .then((e) => alert('kopirano'));
              }}>
              Kopiraj url
            </button>
          </td>
          <td>
            <button
              onClick={() => select(folder.path)}
              className={` px-2 text-xs py-1 rounded-lg ' ${
                images == folder.path
                  ? 'bg-gray-700 text-white'
                  : 'bg-blue-500 text-white'
              }`}>
              {images == folder.path ? 'Odabran' : 'Odaberi'}
            </button>
          </td>
        </tr>
      ) : null
    );
  };

  const handleCopyToClipboard = (path) => {
    navigator.clipboard.writeText('https://dev.hoo.hr/' + path).then(() => {
      alert('Copied to clipboard');
    });
  };
  const fo = folder;
  return (
    <div className='modal-bg'>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <div className='image-modal'>
        <div className='pt-1 px-3 relative'>
          <button
            onClick={() => onClose()}
            type='button'
            className='bg-indigo-800 rounded-lg px-4 shadow-sm p-2 text-white image-modal-button'
            data-toggle='modal'
            data-target='#exampleModal'>
            Zatvori
          </button>

          <div>
            <div className='container-lg p-0'>
              <h1>Media</h1>
              Trenutni folder: {sub}
              <div className='d-flex items-center my-1 space-x-2 justify-between'>
                <div className='d-flex items-center my-1 space-x-2'>
                  <input
                    name='folder'
                    type='text'
                    className='px-3 py-1 rounded-md bg-gray-200'
                    placeholder='Unesi ime foldera (npr. novi-folder)'
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />
                  <button
                    type='button'
                    className='my-2 ml-2 px-2 py-1 bg-green-400 text-white rounded-md'
                    onClick={handleCreateFolder}>
                    Dodaj
                  </button>
                </div>
                <div className='d-flex'>
                  <input
                    type='file'
                    multiple
                    className='bg-gray-300 text-black text-xs'
                    onChange={handleFileChange}
                    ref={inputFileRef}
                  />
                  <button
                    type='button'
                    className='my-2 ml-2 px-2 py-1 bg-green-400 text-white rounded-md'
                    onClick={handleUploadFiles}>
                    Upload
                  </button>
                </div>
              </div>
              <hr />
              {loadingData ? (
                <div className='h-96 d-flex'>
                  <h1 className='m-auto text-xl'>Loading...</h1>
                </div>
              ) : (
                <div className='flex flex-row'>
                  <div
                    className='w-25 px-3 py-2 rounded-md'
                    style={{
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                      height: '500px',
                    }}>
                    {sub && (
                      <button
                        type='button'
                        className='my-1 mb-2 px-2 py-1 bg-black text-white rounded-md'
                        onClick={handleNavigateBack}>
                        <i class='bi bi-arrow-left'></i> Nazad
                      </button>
                    )}
                    <input
                      className='bg-gray-100'
                      onChange={(e) => setFilter(e.target.value)}
                      placeholder='Pretraži'
                    />
                    <h4 className=''>Folderi</h4>
                    <ul className=''>
                      {filteredData.some((i) => i.type == 'folder') ? (
                        folders?.map((folder, index) =>
                          folder.type !== 'folder' ? null : (
                            <li
                              className=' p-0 my-1 bg-gray-100 mx-1 rounded-md'
                              key={index}>
                              <button
                                type='button'
                                className='mt-1 p-1 text-left'
                                disabled={folder.type !== 'folder'}
                                onClick={() =>
                                  handleSubfolderClick(
                                    folder.path.replace(fo + '/', '')
                                  )
                                }>
                                <div className='flex items-center'>
                                  <i class='bi bi-folder-fill text-warning'></i>
                                  <small
                                    style={{ maxWidth: '200px' }}
                                    className='text-truncate'>
                                    {folder.name}
                                  </small>
                                </div>
                              </button>
                            </li>
                          )
                        )
                      ) : (
                        <p>No folders add one</p>
                      )}
                    </ul>
                  </div>
                  <div
                    className='w-75 mx-auto'
                    style={{
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                      height: '500px',
                    }}>
                    <table className='table'>
                      <thead className='border-none'>
                        <tr>
                          <th></th>
                          <th>Ime</th>

                          <th>Vrijeme</th>
                          <th></th>
                          <th>Akcija</th>
                        </tr>
                      </thead>
                      <tbody>{renderFolderContents()}</tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FolderListSingle.propTypes = {
  listaSlika: PropTypes.object,
  onClose: PropTypes.func,
  setImage: PropTypes.func,
  multiple: PropTypes.bool,
};

export default FolderListSingle;
