import {
  GET_POSTS,
  GET_POSTS_ERROR,
  GET_OLIMP_ARTICLE,
  GET_OLIMP_ARTICLE_ERROR,
} from '../types';
import axios from 'axios';
import api from '../../components/Api/api';

export const getPosts = (page, language, titleKey) => async (dispatch) => {
  console.log(language, 'ld');
  try {
    const res = await axios.get(
      `${api}/api/content/get/all/${page}/${titleKey}/${language}`
    );

    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: GET_POSTS_ERROR,
      payload: console.error(e),
    });
  }
};
export const getOlimp = (page) => async (dispatch) => {
  try {
    const res = await axios.get(`${api}/api/content/get-olimp-article/${page}`);

    dispatch({
      type: GET_OLIMP_ARTICLE,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: GET_OLIMP_ARTICLE_ERROR,
      payload: console.log(e),
    });
  }
};
