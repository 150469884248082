import Axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { getOlimpijci } from '../../PAGES/Profile/GetComments';
import Select from 'react-select';
import { style, styles } from '../const/style';
import { param } from 'jquery';

const MedaljeSportasi = ({ setParams, placeholder, value, selected }) => {
  const searchInput = useRef();
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selec, setSelected] = useState();
  useEffect(() => {
    getSportasi();
  }, []);
  const select = (value, name) => {
    setParams(value, name);
    setSelected(value, name);
  };

  const getSportasi = async () => {
    const get = await getOlimpijci();
    setData(get);
    setLoading(false);
  };
  const handleChange = (selectedOption) => {
    if (!selectedOption) {
      setParams(null);
    } else {
      console.log(selectedOption);
      const valuesArray = selectedOption.map((item) => ({
        id: item.value,
        name: item.label,
      }));

      const valuesString = valuesArray;

      setParams(valuesString);
      setSelected(selectedOption);
    }
  };
  useEffect(() => {
    getSportasi();
    handleChange(selected);
  }, []);
  if (loading || data.length < 1) {
    return <div>Loading</div>;
  }

  const options = Object.values(data).map((item) => ({
    label: item.Ime + ' ' + item.Prezime,
    value: item.id,
  }));

  return (
    <div>
      <Select
        classNames='rounded-lg bg-white'
        onChange={handleChange}
        isMulti
        value={selec}
        placeholder='Odaberi sportaša'
        styles={styles}
        isClearable={true}
        options={options}
      />
    </div>
  );
};
MedaljeSportasi.propTypes = {
  setParams: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default MedaljeSportasi;
