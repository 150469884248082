import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import ModalImage from '../../components/Modal/ModalImage';

import { getDocCategories, getMedalje } from '../Profile/GetComments';
import FolderListSingle from '../NovaMedia/MediaModalSingle';
import ReactSwitch from 'react-switch';

const Dokumenti = () => {
  const [AlertShow, setShowAlert] = useState(false);
  const [lng, setLng] = useState('hrv');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showsub, setshowsub] = useState(false);
  const [folderLng, set_folderLng] = useState('hrv');
  // docs
  const [foldername, setFolderName] = useState();
  const [subId, setSubId] = useState();
  const [sub_folders, setSubFolders] = useState([]);
  const [docPage, setPage] = useState([]);
  const [docData, setDocumentsData] = useState([]);
  const [docModal, setOpenDocModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState();
  const [ShowInSubFolder, setShowInSubFolder] = useState(false);
  const [showAddSubFolder, setShowAddSubFolder] = useState(false);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [folderTitle, setFolderTitle] = useState('');
  const [folderTitleEng, setFolderTitleEng] = useState('');
  const [subfolderTitle, setSubFolderTitle] = useState('');
  const [subfolderTitleEng, setSubFolderTitleEng] = useState('');
  const [docVisible, setDocVisible] = useState();
  const [id_page, setId_page] = useState();
  const [link, setLink] = useState(null);
  const [full_link, setFullLink] = useState(null);
  const [doc_name, setDoc_name] = useState();
  const [year, setYear] = useState();
  const [doc_name_eng, setDoc_name_eng] = useState();
  const dohvati = async () => {
    const get = await getDocCategories(lng);
    setPage(get);
    setLoading(false);
  };
  const dohvatiFoldere = async () => {
    const get = await getDocCategories(lng);
    setPage(get);
    setLoading(false);
  };
  const dohvatiPodKategorije = async () => {
    Axios.get(api + '/api/content/get/subfolder-admin/' + id_page)
      .then((res) => {
        setSubFolders(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    dohvati();
  }, [lng]);
  useEffect(() => {
    dohvatiPodKategorije();
  }, [id_page]);
  //dodajfolder
  const addFolder = (e) => {
    e.preventDefault();
    const params = {
      title: folderTitle,
      title_eng: folderTitleEng,
      show_doc_page: 1,
      lng: folderLng,
    };
    Axios.post(api + '/api/content/get/post-doc-categories', params, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 400) {
          showAlert('danger', e.message);
        } else {
          showAlert('success', res.data.message);
          dohvati();
          document.getElementById('create-course-form').reset();
          setFolderTitle();
          setFolderTitleEng();
        }
      })
      .catch((err) => {
        if (err.message == 'Request failed with status code 400') {
          showAlert('danger', 'Nazivi su obavezni* !');
        } else {
          showAlert('danger', err.message);
        }
        console.log(err.message);
      });
  };

  //objavi podfolder
  const addSubFolder = (e) => {
    e.preventDefault();
    if (!selectedFolder) {
      showAlert('danger', 'Folder id je obavezan !');
    } else {
      const params = {
        title: subfolderTitle,
        title_eng: subfolderTitleEng,
        show_sub: 1,
        doc_cat: selectedFolder,
      };
      Axios.post(api + '/api/content/get/post-subfolder', params, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 400) {
            showAlert('danger', e.message);
          } else {
            showAlert('success', res.data.message);
            dohvati();
            document.getElementById('create').reset();
            setSubFolderTitle();
            setSubFolderTitleEng();
          }
        })
        .catch((err) => {
          if (err.message == 'Request failed with status code 400') {
            showAlert('danger', 'Nazivi su obavezni* !');
          } else {
            showAlert('danger', err.message);
          }
          console.log(err.message);
        });
    }
  };
  const removeSubFolder = (id) => {
    Axios.delete(api + '/api/content/get/remove-subfolder/' + id, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
        dohvatiPodKategorije();
      })
      .catch((e) => {
        console.log(e);
        showAlert('danger', e.data.message);
      });
  };
  const removeFolder = (id) => {
    Axios.delete(api + '/api/content/get/remove-doc-categories/' + id, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        showAlert('success', res.data.message);
        dohvati();
      })
      .catch((e) => {
        console.log(e);
        showAlert('danger', e.data.message);
      });
  };
  const folderi = (type) => {
    dohvatiSlike(type);
  };
  const onChangeImg = (event) => {
    if (event.target.files[0] != null) {
      console.log(event.target.files[0]);
      uploadImage(event.target.files[0]);
    } else {
    }
  };

  const uploadImage = (img) => {
    const formData = new FormData();
    formData.append('image', img);
    let url = 'https://dev.hoo.hr/upload-doc.php';
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);
          setLink(data.image);
          setFullLink(data.url);

          showAlert('success', data.message);
        });
      })
      .catch((e) => {
        console.log(e);
        showAlert('success', e.message);
      });
  };

  //open doc modal
  const dohvatiSlike = async (type) => {
    setFolderName(type);
    setOpenDocModal(true);
  };
  const addDocument = (e) => {
    e.preventDefault();
    if (ShowInSubFolder == true && !subId) {
      showAlert('danger', 'Dokument se prikazuje u subfolderu a nema id');
    } else {
      const params = [
        [link, doc_name, id_page, year, doc_name_eng, subId, ShowInSubFolder],
      ];
      Axios.post(api + '/api/content/get/post-dokument', params, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          showAlert('success', res.data.message);
          dohvati();
          document.getElementById('document').reset();
        })
        .catch((e) => {
          console.log(e);
          showAlert('danger', 'danger');
        });
    }
  };
  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const updateDocVisible = (val, id) => {
    const params = {
      show_doc_page: val,
      id: id,
    };
    Axios.put(
      api + '/api/content/get/update-doc-categories-visibility/',
      params,
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => {
        showAlert('success', res.data.message);
        dohvati();
      })
      .catch((e) => {
        showAlert('danger', e.message);
      });
  };

  if (loading || !docPage) {
    return (
      <div className='d-flex ' style={{ height: '90vh' }}>
        <p className='m-auto bold'>Učitavam dokumente</p>
      </div>
    );
  }
  return (
    <section className='posts-list p-4 '>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <div className=''>
        <h3>Dokumenti</h3>
        <button
          className='bg-green-200 p-1 text-black px-3  my-2'
          onClick={() => setShowAddFolder((value) => !value)}>
          Dodaj novi folder <i className='bi bi-folder'></i>
        </button>
        <button
          className='bg-blue-900 ml-1 p-1 text-white px-3 d my-2'
          onClick={() => setShowAddSubFolder((value) => !value)}>
          Dodaj novi pod folder <i className='bi bi-folder'></i>
        </button>
        {showAddFolder ? (
          <form
            id='create-course-form'
            onSubmit={addFolder}
            className='container py-3 olimp-search'>
            <div>
              <p>Dodaj folder</p>
              <input
                className='w-50'
                placeholder='Unesi naziv foldera'
                onChange={(e) => setFolderTitle(e.target.value)}
              />
              <br />
              <input
                className='w-50'
                placeholder='Unesi naziv eng'
                onChange={(e) => setFolderTitleEng(e.target.value)}
              />
              <div>
                <p>Jezik foldera</p>
                <div className='flex items-center space-x-3'>
                  <ReactSwitch
                    offColor='#333'
                    onColor='#8847d7'
                    placeholder='Jezik'
                    onChange={() =>
                      set_folderLng(folderLng == 'hrv' ? 'eng' : 'hrv')
                    }
                    checked={folderLng == 'eng'}
                  />

                  {folderLng == 'hrv' ? (
                    <small>Hrvatski</small>
                  ) : (
                    <small>Engleski</small>
                  )}
                </div>
                <br />
              </div>
              <button
                type='submit'
                className='bg-blue-800 p-1 text-white px-3 rounded-md mt-2'>
                Objavi novi folder
              </button>
              <hr />
            </div>
          </form>
        ) : null}
        {showAddSubFolder ? (
          <form
            id='create'
            onSubmit={addSubFolder}
            className='container flex-col py-3 olimp-search'>
            <div>
              <p>Podfolder</p>
              <input
                className='w-50'
                placeholder='Unesi naziv pod foldera'
                onChange={(e) => setSubFolderTitle(e.target.value)}
              />
              <br />
              <input
                className='w-50'
                placeholder='Unesi naziv eng'
                onChange={(e) => setSubFolderTitleEng(e.target.value)}
              />
              <br />
              {selectedFolder}
              <select
                className='w-50'
                onChange={(e) => setSelectedFolder(e.target.value)}>
                {docPage.map((val, index) => (
                  <option key={index} value={val.id}>
                    {val.title}
                  </option>
                ))}
              </select>
              <br />
              <button
                type='submit'
                className='bg-blue-800 p-1 text-white px-3 rounded-md mt-2'>
                Objavi novi pod folder
              </button>
              <hr />
            </div>
          </form>
        ) : null}
        <h6>Svi folderi</h6>
        <div>
          <p>Jezik foldera</p>
          <div className='flex items-center space-x-3'>
            <ReactSwitch
              offColor='#333'
              onColor='#8847d7'
              placeholder='Jezik'
              onChange={() => {
                const newLng = lng === 'hrv' ? 'eng' : 'hrv';
                setLng(newLng);
                // Poziv funkcije dohvatiFoldere() nakon postavljanja novog jezika
                // Ovdje smo koristili callback funkciju koja se poziva nakon postavljanja state-a
              }}
              checked={lng === 'eng'}
            />
            {lng === 'hrv' ? <small>Hrvatski</small> : <small>Engleski</small>}
          </div>
          <br />
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th>Naziv</th>
              <th>Podfolderi</th>
              <th>Prikaži dokumente</th>
              <th>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {docPage.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <i className='bi bi-folder-fill text-yellow-400 mr-2'></i>
                    {item.title}
                  </td>
                  <td>
                    <button
                      className='ml-auto text-black mr-3 btn btn-link'
                      onClick={() => {
                        setshowsub((val) => !val);
                        setId_page((val) => (val === item.id ? null : item.id));
                      }}>
                      <i className='bi bi-arrow-down'></i> Podfolderi
                    </button>
                  </td>
                  <td>
                    <ReactSwitch
                      offColor='#333'
                      onColor='#8847d7'
                      placeholder='Ping'
                      onChange={() =>
                        updateDocVisible(
                          item.show_doc_page === 1 ? 0 : 1,
                          item.id
                        )
                      }
                      checked={item.show_doc_page === 1}
                    />
                  </td>
                  <td>
                    <a href={'/dokumenti/folder/' + item.id}>Pogledaj</a>
                    <button
                      className='text-danger ml-2 btn btn-link'
                      onClick={() => removeFolder(item.id)}>
                      Ukloni
                    </button>
                  </td>
                </tr>
                {id_page === item.id && sub_folders.length < 1 && (
                  <tr>
                    <td colSpan={4} className='text-center py-4'>
                      <b>Nema dodanih podfoldera</b>
                    </td>
                  </tr>
                )}
                {id_page === item.id && sub_folders.length > 0 && (
                  <tr>
                    <td colSpan={4}>
                      <div
                        className='p-3'
                        style={{ mineight: '200px', background: 'white' }}>
                        {sub_folders.map((subItem, subIndex) => (
                          <div
                            className='d-flex justify-content-between pb-2'
                            style={{ borderBottom: '0.5px solid #ddd' }}
                            key={subIndex}>
                            <b className='mt-auto mb-auto'> {subItem.title}</b>
                            <button
                              onClick={() => removeSubFolder(subItem.sub_id)}
                              className='bg-red-700 p-1 text-white px-3 rounded-md mb-2'>
                              Ukloni
                            </button>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <hr />
      <div className='my-3 rounded-lg p-3'>
        <h3>Dodaj novi dokument</h3>
        <form
          id='document'
          onSubmit={addDocument}
          className=' py-3 olimp-search'>
          <button
            onClick={() => dohvatiSlike('dokumenti')}
            type='button'
            className='bg-blue-500 p-1 text-white px-3 rounded-md mb-2'
            data-toggle='modal'
            data-target='#exampleModal'>
            Arhiva nova
          </button>
          <button
            onClick={() => dohvatiSlike('images')}
            type='button'
            className='bg-blue-300 ml-2 p-1 text-white px-3 rounded-md mb-2'
            data-toggle='modal'
            data-target='#exampleModal'>
            Arhiva stara
          </button>
          {alert != null ? (
            <p
              className={
                alert === 'Dokument je uploadan!'
                  ? 'text-success'
                  : 'text-danger'
              }>
              {alert}
            </p>
          ) : null}

          {link != null ? (
            <div>
              <div className='card'>
                <i class='bi bi-check2-circle  text-success text-5xl p-3'></i>

                <p className='p-3'>
                  Skraceni link: <span className='text-green-500'>{link}</span>
                </p>
                <p className='p-3'>
                  Puni link:{' '}
                  <a className='text-green-500' href={full_link}>
                    {full_link}
                  </a>
                </p>
              </div>
            </div>
          ) : null}
          <div className='d-flex '>
            <section className='w-100 m-1'>
              <small>Naziv dokumenta *</small>
              <input
                onChange={(e) => setDoc_name(e.target.value)}
                placeholder='Naziv'
              />
            </section>
            <section className='w-100 m-1'>
              <small>Link *</small>
              <input
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder=' images/dokumenti/Revizija_financijskih_izvještaja_za_2021_godinu.pdf
'
              />
            </section>
          </div>
          <div className=' d-flex'>
            <section className='w-100 m-1'>
              <small>Odaberi stranicu za prikaz dokumenta *</small>
              <select required onChange={(e) => setId_page(e.target.value)}>
                <option value='-'>Odaberi *</option>
                {docPage.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </section>
            <section className='w-100 m-1'>
              <small>Dodaj eng. naslov</small>
              <input
                required
                onChange={(e) => setDoc_name_eng(e.target.value)}
                className='m-1'
                placeholder='Naziv eng*'
              />
            </section>
          </div>
          <section className='w-100 m-1'>
            <small>Odaberi podfolder za prikaz dokumenta </small>
            Odabrano: {subId}
            <select onChange={(e) => setSubId(e.target.value)}>
              <option value={''}>Odaberi *</option>
              {sub_folders.map((item, index) => (
                <option key={index} value={item.sub_id}>
                  {item.title}
                </option>
              ))}
            </select>
          </section>
          <section className='w-100 m-1'>
            <small>Prikazi ovaj dokument u podfolderu</small>
            <select
              required
              onChange={(e) => setShowInSubFolder(e.target.value)}>
              <option value={0}>Ne prikazuj</option>
              <option value={1}>Prikaži</option>
            </select>
          </section>
          <small>Unesi godinu</small>
          <input
            onChange={(e) => setYear(e.target.value)}
            className='m-1'
            required
            placeholder='Godina dokumenta*'
          />
          <button
            type='submit'
            className='bg-green-400 py-1 text-white px-4 rounded-lg mt-4'>
            Objavi
          </button>
        </form>
      </div>

      {docModal ? (
        <FolderListSingle
          allowSelect={true}
          onClose={() => setOpenDocModal(false)}
          folder={foldername}
          selected={link}
          setImage={setLink}
        />
      ) : null}
    </section>
  );
};

export default Dokumenti;
