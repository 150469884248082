import React, { Component } from 'react';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import jwt from 'jwt-decode';
const Sidebar = () => {
  const token = localStorage.getItem('token');
  const userToken = token ? jwt(token) : '';
  const user = userToken.userId;
  const role = userToken.role;
  useEffect(() => {
    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
      });
    });
  });

  const accountType = () => {
    switch (role) {
      case 'superadmin':
        return (
          <ul className='list-unstyled components'>
            <li className='active'>
              <NavLink exact='true' activeclassname='active' to='/'>
                <i className='bi bi-house p-0'></i>Dashboard
              </NavLink>
            </li>
            <li className='active'>
              <NavLink exact='true' activeclassname='active' to='/postovi'>
                <i className='bi bi-newspaper p-0'></i>Članci
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/dodaj-post'>
                <i className='bi bi-box-arrow-in-down-right'></i> Dodaj članak
              </NavLink>
            </li>

            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/media-manager/images'>
                <i className='bi bi-image'></i> Media
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/stranice'>
                <i className='bi bi-list-check'></i> Stranice
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/new-page'>
                <i className='bi bi-box-arrow-in-down-right'></i>Dodaj stranicu
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/dodaj-olimp'>
                <i className='bi bi-box'></i> Dodaj Olimp
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/olimp-brojevi'>
                <i className='bi bi-list-check'></i> Brojevi Olimpa
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/olimp-clanci'>
                <i className='bi bi-list-check'></i> Članci Olimp
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/autori-olimp'>
                <i className='bi bi-list-check'></i> Autori Olimp
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/dodaj-rubriku-olimp'>
                <i className='bi bi-list-check'></i> Dodaj rubriku
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/dodaj-autora-olimp'>
                <i className='bi bi-list-check'></i> Dodaj autora
              </NavLink>
            </li>

            <li>
              <NavLink exact='true' activeclassname='active' to='/navigacija'>
                <i className='bi bi-map'></i> Navigacija
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/dokumenti-folderi'>
                <i className='bi bi-file-earmark-binary'></i>Dokumenti
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/sportasi'>
                <i className='bi bi-award'></i> Sportaši
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/kategorizacije'>
                <i className='bi bi-box-arrow-in-down-right'></i>Kategorizacije
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/medalje'>
                <i className='bi bi-award'></i> Medalje
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/postavke'>
                <i className='bi bi-gear'></i> Postavke stranice
              </NavLink>
            </li>
          </ul>
        );
      case 'olimp':
        return (
          <ul className='list-unstyled components'>
            <li className='active'>
              <NavLink exact='true' activeclassname='active' to='/'>
                <i className='bi bi-house p-0'></i>Dashboard
              </NavLink>
            </li>

            <li>
              <NavLink exact='true' activeclassname='active' to='/dodaj-olimp'>
                <i className='bi bi-box'></i> Dodaj Olimp
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/olimp-brojevi'>
                <i className='bi bi-list-check'></i> Brojevi Olimpa
              </NavLink>
            </li>

            <li>
              <NavLink exact='true' activeclassname='active' to='/olimp-clanci'>
                <i className='bi bi-list-check'></i> Članci Olimp
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/dodaj-rubriku-olimp'>
                <i className='bi bi-list-check'></i> Dodaj rubriku
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/autori-olimp'>
                <i className='bi bi-list-check'></i> Autori Olimp
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/dodaj-autora-olimp'>
                <i className='bi bi-list-check'></i> Dodaj autora
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/sportasi'>
                <i className='bi bi-award'></i> Sportaši
              </NavLink>
            </li>
          </ul>
        );
      case 'dokumenti':
        return (
          <ul className='list-unstyled components'>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/media-manager/images'>
                <i className='bi bi-image'></i> Media
              </NavLink>
            </li>

            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/dokumenti-folderi'>
                <i className='bi bi-file-earmark-binary'></i>Dokumenti
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/sportasi'>
                <i className='bi bi-award'></i> Sportaši
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/medalje'>
                <i className='bi bi-award'></i> Medalje
              </NavLink>
            </li>
          </ul>
        );
      case 'tipfeler':
        return (
          <ul className='list-unstyled components'>
            <li className='active'>
              <NavLink exact='true' activeclassname='active' to='/'>
                <i className='bi bi-house p-0'></i>Dashboard
              </NavLink>
            </li>
            <li className='active'>
              <NavLink exact='true' activeclassname='active' to='/postovi'>
                <i className='bi bi-newspaper p-0'></i>Članci
              </NavLink>
            </li>

            <li>
              <NavLink exact='true' activeclassname='active' to='/dodaj-post'>
                <i className='bi bi-box-arrow-in-down-right'></i> Dodaj članak
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/media-manager/images'>
                <i className='bi bi-image'></i> Media
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/stranice'>
                <i className='bi bi-list-check'></i> Stranice
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/new-page'>
                <i className='bi bi-list-check'></i>Dodaj stranicu
              </NavLink>
            </li>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/dokumenti-folderi'>
                <i className='bi bi-file-earmark-binary'></i>Dokumenti
              </NavLink>
            </li>
            <li>
              <NavLink exact='true' activeclassname='active' to='/sportasi'>
                <i className='bi bi-award'></i> Sportaši
              </NavLink>
            </li>
          </ul>
        );
      case 'sportasi':
        return (
          <ul className='list-unstyled components'>
            <li>
              <NavLink exact='true' activeclassname='active' to='/sportasi'>
                <i className='bi bi-award'></i> Sportaši
              </NavLink>
            </li>
          </ul>
        );
      case 'kategorizacije':
        return (
          <ul className='list-unstyled components'>
            <li>
              <NavLink
                exact='true'
                activeclassname='active'
                to='/kategorizacije'>
                <i className='bi bi-box-arrow-in-down-right'></i>Kategorizacije
              </NavLink>
            </li>
          </ul>
        );
    }
  };

  return (
    <nav id='sidebar'>
      <div className='sidebar-header mt-3 '>
        <h4 className='text-left pl-3 text-white'>hoo.hr</h4>

        {accountType()}
      </div>
    </nav>
  );
};

export default Sidebar;
