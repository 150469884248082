import Axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import api from '../../components/Api/api';
import { getAutor } from '../Profile/GetComments';

const DodajAutora = () => {
  //values
  const [ime, setIme] = useState();
  const [prezime, setPrezime] = useState();
  const [pref, setPref] = useState();
  const [suf, setSuf] = useState();
  const [email, setEmail] = useState();
  const [web, setWeb] = useState();
  const [firma, setFirma] = useState();

  //alert
  const [AlertShow, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const showAlert = (type, message) => {
    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const send = async (e) => {
    e.preventDefault();
    let val = [[pref, ime, prezime, suf, email, web, firma]];
    try {
      const res = await Axios.post(api + '/api/content/add-autor-olimp', val, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      showAlert('success', res.data.message);
      document.getElementById('clanak-olimp').reset();
      return res;
    } catch (e) {
      console.log(e.message);
      showAlert('danger', e.message);
    }
  };

  return (
    <section className='posts-list '>
      <Alert show={AlertShow} type={alertType} message={alertMessage} />
      <button className='bg-blue-800 p-1 text-white px-3 rounded-md my-2'>
        <Link to='/autori-olimp'>Nazad</Link>
      </button>
      <h1>Dodaj autora Olimp</h1>
      <form onSubmit={send} id='clanak-olimp'>
        <button
          type='submit'
          className='bg-green-600  p-1 text-white px-3 rounded-md my-2'>
          Objavi
        </button>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Ime</span>
            <input
              required
              onChange={(e) => setIme(e.target.value)}
              placeholder='Ime'
            />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Prezime</span>
            <input onChange={(e) => setPrezime(e.target.value)} />
          </div>
        </div>
        <span>Pref</span>
        <input onChange={(e) => setPref(e.target.value)} placeholder='Pref' />
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Suf</span>
            <input onChange={(e) => setSuf(e.target.value)} placeholder='Suf' />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Email</span>
            <input
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Web'
            />
          </div>
        </div>
        <div className='d-flex '>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Web</span>
            <input onChange={(e) => setWeb(e.target.value)} placeholder='Web' />
          </div>
          <div style={{ width: '49%' }} className='m-1'>
            <span>Firma</span>
            <select onChange={(e) => setFirma(e.target.value)}>
              <option>Odaberi</option>
              <option value={false}>Ne</option>
              <option value={true}>Da</option>
            </select>
          </div>
        </div>
      </form>
    </section>
  );
};

export default DodajAutora;
